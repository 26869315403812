import { Injectable } from "@angular/core";
import { AngularFirestore } from "@angular/fire/firestore";
import { AngularFireAuth } from "@angular/fire/auth";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import * as firebase from "firebase/app";
import { environment } from "../environments/environment";
import { Subscription } from "rxjs/Subscription";
import { HttpService } from "./http.service";
import { StringMap } from "@angular/compiler/src/compiler_facade_interface";

const whitelabelAgency = environment.whitelabelAgency;

class User {
  private userEmail: string;
  private userNumber: string;
  private agency: string;
  private userName: string;
  private exists: boolean = false;

  constructor(
    userEmail: string,
    userNumber: string,
    userName: string,
    agency: string
  ) {
    if (userEmail || userEmail != "") this.exists = true;
    this.userEmail = userEmail;
    this.userNumber = userNumber;
    this.userName = userName;
    this.agency = agency;
  }

  data() {
    return this;
  }
}

class AgencyUser {
  private userEmail: string;
  private storeName: string;
  private agency: string;
  private userName: string;
  private isAdmin;
  private isAgency;
  private exists: boolean = false;
  private newTicketNotification: string;
  private reportNotification: string;
  private userLogo: string;
  private storeDirectRequest: boolean;
  private disableFunnyNotifications: boolean;
  private permission;
  private profilePicture: string;

  constructor(
    userEmail: string,
    storeName: string,
    userName: string,
    agency: string,
    isAdmin,
    isAgency,
    newTicketNotification: string,
    reportNotification: string,
    userLogo: string,
    permission,
    disableFunnyNotifications: boolean,
    storeDirectRequest: boolean,
    profilePicture: string
  ) {
    if (userEmail || userEmail != "") this.exists = true;
    this.userEmail = userEmail;
    this.storeName = storeName;
    this.userName = userName;
    this.agency = agency;
    this.isAdmin = isAdmin;
    this.newTicketNotification = newTicketNotification;
    this.reportNotification = reportNotification;
    this.userLogo = userLogo;
    this.isAgency = isAgency;
    this.permission = permission;
    this.disableFunnyNotifications = disableFunnyNotifications;
    this.storeDirectRequest = storeDirectRequest;
    this.profilePicture = profilePicture;
  }

  data() {
    return this;
  }
}

class AgencyStore {
  private storeName: string;
  private storeCategory: string;
  private version: string;
  private url: string;
  private woocommerceSecret: string;
  private woocommerceApi: string;
  private exists: boolean = false;
  private isPremium: boolean = false;
  private storeOwner: string;
  private randomPass: string;
  private encryptedKey: string;
  private encryptedSecret: string;

  constructor(
    storeName: string,
    storeCategory: string,
    url: string,
    version: string,
    woocommerceSecret: string,
    woocommerceApi: string,
    isPremium: boolean,
    storeOwner: string,
    randomPass: string,
    encryptedKey: string,
    encryptedSecret: string,
  ) {
    if (storeName || storeName != "") this.exists = true;
    this.storeName = storeName;
    this.storeCategory = storeCategory;
    this.url = url;
    this.version = version;
    this.woocommerceSecret = woocommerceSecret;
    this.woocommerceApi = woocommerceApi;
    this.isPremium = isPremium;
    this.storeOwner = storeOwner;
    this.randomPass = randomPass;
    this.encryptedKey = encryptedKey;
    this.encryptedSecret = encryptedSecret;
  }

  data() {
    return this;
  }
}

@Injectable({
  providedIn: "root",
})
export class FirebaseService {
  private snapshotChangesSubscription: Subscription;

  public static isAdmin: boolean = false;
  public static isAgency: boolean = false;
  public static permission = "NONE";
  public static storesAmount;
  public whitelabelAgency = whitelabelAgency;
  public agency = whitelabelAgency;

  postHttpOptions = {
    headers: new HttpHeaders({
      "Content-Type": "text/plain",
      Authorization: "UNAUTHORIZED",
    }),
  };

  getHttpOptions = {
    headers: new HttpHeaders({
      "Content-Type": "application/x-www-form-urlencoded",
      Authorization: "UNAUTHORIZED",
    }),
  };

  jsonPostHttpOptions = {
    headers: new HttpHeaders({
      "Content-Type": "application/json",
      Authorization: "UNAUTHORIZED",
    }),
  };

  constructor(
    public firestore: AngularFirestore,
    public afAuth: AngularFireAuth,
    private httpClient: HttpClient,
    private httpService: HttpService
  ) {
    this.setAdmin(localStorage.getItem("isAdmin"));
  }

  isLoggedIn(): boolean {
    const user = JSON.parse(localStorage.getItem("user"));
    return user !== null;
  }

  // createStore(
  //     storeName: string,
  //     woocommerceApi: string,
  //     woocommerceSecret: string,
  //     storeCategory: string,
  //     url: string,
  //     version: string
  // ): Promise<void> {
  //     const agency = localStorage.getItem('agency');

  //     return this.firestore.doc(`${agency}StoreList/${storeName}`).set({
  //         storeName,
  //         storeCategory,
  //         woocommerceApi,
  //         woocommerceSecret,
  //         url,
  //         version
  //     });
  // }

  // createStore(storeName: string,
  //     woocommerceApi: string,
  //     woocommerceSecret: string,
  //     storeCategory: string,
  //     url: string,
  //     version: string){
  //     return new Promise((res,rej) => {
  //         const agency = localStorage.getItem('agency');
  //         const httpOptions = this.getHttpOptions
  //         const endpoint = environment.endpoint

  //         if(!agency && !url && !storeName && !woocommerceSecret){
  //             rej('invalid params');
  //         }

  //         let requestEndpoint = `createStore?agency=${agency}&storeName=${storeName}&woocommerceApi=${woocommerceApi}&woocommerceSecret=${woocommerceSecret}&storeCategory=${storeCategory}&url=${url}&version=${version}`;
  //         this.httpService.get(requestEndpoint,httpOptions).subscribe((result) => {
  //             console.log('Created Store',result)
  //             res(result)
  //         });
  //     })
  // }

  saveUserInformation(): Promise<void> {
    //uid
    const userEmail = this.afAuth.auth.currentUser.email;
    const userName = "undefined";
    let agency = "";

    return this.firestore.doc(`userList/${userEmail}`).set({
      userName,
      userEmail,
      agency,
    });
  }

  saveUserAgency(agc, name, number): Promise<void> {
    const userName = name;
    const userNumber = number;
    const userEmail = this.afAuth.auth.currentUser.email;
    const agency = agc;

    localStorage.setItem("agency", agency);
    localStorage.setItem("userName", name);

    return this.firestore.doc(`userList/${userEmail}`).set({
      agency,
      userName,
      userEmail,
      userNumber,
    });
  }

  updateAgencyUserList(agc, name, country, device): Promise<any> {
    return new Promise((res, rej) => {
      const userEmail = this.afAuth.auth.currentUser.email;
      const userName = name;

      const agency = agc;
      const storeName = "";

      if (!userEmail) {
        rej("Email invalido");
      }

      let requestEndpoint = `updateAgencyUserList?agency=${agency}&userEmail=${userEmail}&userName=${userName}&storeName=${storeName}&country=${country}&device=${device}`;
      this.httpService.get(requestEndpoint).subscribe((result) => {
        res(result);
      });
    });
  }

  createNewWhitelabelUser(user, whitelabelAgency) {
    return new Promise((res, rej) => {
      const userName = user.data().userName;
      const userEmail = user.data().userEmail;
      const agency = whitelabelAgency;


      const storeName = "";

      if (!userEmail) {
        rej("Email invalido");
      }

      let requestEndpoint = `updateAgencyUserList?agency=${agency}&userEmail=${userEmail}&userName=${userName}&storeName=${storeName}`;
      this.httpService.get(requestEndpoint).subscribe((result) => {
        res(result);
      });
    });
  }

  getUser(email: string) {
    return this.firestore.doc(`userList/${email}`).ref.get();
  }

  getStoreName(agency, email: string) {
    return new Promise((res, rej) => {
      const userEmail = email;

      if (!userEmail || !agency) {
        rej("Email ou agency invalidos");
      }

      let backendEndpoint = `user/agency-user?userEmail=${userEmail}&agency=${agency}`;
      let cloudfnEndpoint = `getAgencyUser?userEmail=${userEmail}&agency=${agency}`;

      return this.httpService.getFromBackendOrCloud({ endpoint: backendEndpoint }, { endpoint: cloudfnEndpoint }).subscribe((result: any) => {
        // console.log("O QUE ESTÁ CHEGANDO AQUI É ISSO: " + result);
        // console.log("O QUE ESTÁ CHEGANDO AQUI É ISSO (JSON): " + JSON.stringify(result));

        let userEmail = "";
        let storeName = "";
        let userName = "";
        let isAdmin = "";
        let isAgency = "";
        let newTicketNotification = "";
        let reportNotification = "";
        let userLogo = "";
        let permission = [];
        let profilePicture = "";
        let storeDirectRequest = false;
        let disableFunnyNotifications = false;

        if (result["_fieldsProto"]) {
          if (result["_fieldsProto"].userEmail)
            userEmail = result["_fieldsProto"].userEmail.stringValue;
          if (result["_fieldsProto"].userName)
            userName = result["_fieldsProto"].userName.stringValue;
          if (result["_fieldsProto"].storeName)
            storeName = result["_fieldsProto"].storeName.stringValue;
          if (result["_fieldsProto"].isAdmin)
            isAdmin = result["_fieldsProto"].isAdmin.stringValue;
          if (result["_fieldsProto"].isAgency)
            isAgency = result["_fieldsProto"].isAgency.stringValue;
          if (result["_fieldsProto"].newTicketNotification)
            newTicketNotification =
              result["_fieldsProto"].newTicketNotification.stringValue;
          if (result["_fieldsProto"].reportNotification)
            reportNotification =
              result["_fieldsProto"].reportNotification.stringValue;
          if (result["_fieldsProto"].userLogo)
            userLogo = result["_fieldsProto"].userLogo.stringValue;
          if (result["_fieldsProto"].permission) {
            if (result["_fieldsProto"].permission.valueType == "stringValue") {
              permission.push(result["_fieldsProto"].permission.stringValue);
            }
            else if (result["_fieldsProto"].permission.valueType == "arrayValue") {
              result["_fieldsProto"].permission.arrayValue.values.forEach(element => {
                permission.push(element.stringValue);
              });
            }
          } else {
            permission.push("DEPRECATED_PERMISSION");
          }

          if (result["_fieldsProto"].disableFunnyNotifications)
            disableFunnyNotifications =
              result["_fieldsProto"].disableFunnyNotifications.booleanValue;

          if (result["_fieldsProto"].storeDirectRequest)
            storeDirectRequest =
              result["_fieldsProto"].storeDirectRequest.booleanValue;

          if (result["_fieldsProto"].profilePicture)
            profilePicture = result["_fieldsProto"].profilePicture.stringValue;
        }

        const user = new AgencyUser(
          userEmail,
          storeName,
          userName,
          agency,
          isAdmin,
          isAgency,
          newTicketNotification,
          reportNotification,
          userLogo,
          permission,
          disableFunnyNotifications,
          storeDirectRequest,
          profilePicture
        );

        // console.log("Test user ===========", user);
        res(user);
        // console.log(user.data());
      });
    });
  }

  checkIfStoreExists(storeName) {
    return new Promise((res, rej) => {
      const agency = this.agency;
      if (!storeName || !agency) {
        rej("Email ou agency invalidos");
      }

      return this.httpService.getFromBackendOrCloud(
        { endpoint: `store/store-exists?storeName=${storeName}&agency=${agency}` },
        { endpoint: `checkIfStoreExists?storeName=${storeName}&agency=${agency}` }
      )
        .subscribe((result: any) => {
          console.log(result);
          res(result);
        });
    });
  }

  getAgencyList() {
    return new Promise((res, rej) => {

      let requestEndpoint = `getAgencyList`;
      this.httpService.get(requestEndpoint).subscribe((result) => {
        res(result);
      });
    });
  }

  setAdmin(isAdmin) {
    if (isAdmin == true || isAdmin == "true") FirebaseService.isAdmin = true;
    else FirebaseService.isAdmin = false;
  }
  isAdmin() {
    return new Promise((resolve, reject) => {
      // console.log("RODOU ISADMIN  ");
      const agency: string = localStorage.getItem("agency");
      this.getUserInfo(agency.toLocaleLowerCase()).then(
        (res: any) => {
          if (res.data()) {
            if (
              res.data().newMessages != undefined &&
              res.data().newMessages != "" &&
              res.data().newMessages != null
            ) {
              localStorage.setItem(
                "newMessages",
                res.data().newMessages.toString()
              );
            }
            // console.log("is Admin =======================");
            // console.log(JSON.stringify(res.data()));
            if (
              res.data().isAdmin != undefined &&
              res.data().isAdmin != "false" &&
              res.data().isAdmin != false
            ) {
              FirebaseService.isAdmin = true;
              localStorage.setItem("isAdmin", "true");
            } else FirebaseService.isAdmin = false;
            localStorage.setItem("isAdmin", "false");
          } else {
            console.log("Error: No response", res.data());
          }
        },
        (err) => {
          console.log("is Admin err =======================");
          console.log(err);
          return false;
        }
      );
    });
  }

  isAgency() {
    return new Promise<void>((resolve, reject) => {
      // console.log("RODOU ISAGENCY ------>  ");
      const agency: string = localStorage.getItem("agency");
      this.getUserInfo(agency.toLocaleLowerCase()).then(
        (res: any) => {
          if (res.data()) {
            if (
              res.data().newMessages != undefined &&
              res.data().newMessages != "" &&
              res.data().newMessages != null
            ) {
              localStorage.setItem(
                "newMessages",
                res.data().newMessages.toString()
              );
            }
            // console.log("is agency =======================");
            // console.log(JSON.stringify(res.data()));
            if (
              res.data().isAgency != undefined &&
              res.data().isAgency != "false" &&
              res.data().isAgency != false
            ) {
              FirebaseService.isAgency = true;
              resolve();
            } else {
              FirebaseService.isAgency = false;
              resolve();
            }
          } else {
            console.log("Error: No response", res.data());
            reject();
          }
        },
        (err) => {
          console.log("is Admin err =======================");
          console.log(err);
          reject();
          return false;
        }
      );
    });
  }

  getStoreInfo(storeName: string, agency: string) {
    return new Promise((res, rej) => {
      
      this.getCurrentUser().then((email) => {
        const phrase = environment.phrase;
        const userEmail = email;
        if (!storeName || !agency || !userEmail || !phrase) {
          rej("storeName,agency, frase ou  email invalidos");
        }

        return this.httpService.getFromBackendOrCloud(
          { endpoint: `woocommerce/get-agency-store-with-keys?userEmail=${userEmail}&storeName=${storeName}&agency=${agency}&phrase=${phrase}` },
          { endpoint: `getAgencyStoreWithKeys?userEmail=${userEmail}&storeName=${storeName}&agency=${agency}&phrase=${phrase}` }
        )
          .subscribe((result: any) => {
            let storeName = "";
            let storeCategory = "";
            let url = "";
            let version = "";
            let woocommerceSecret = "";
            let woocommerceApi = "";
            let storeOwner = "";
            let isPremium = false;

            let randomPass = result.encryptedKeys.randomPass;
            let encryptedKey = result.encryptedKeys.encryptedKey;
            let encryptedSecret = result.encryptedKeys.encryptedSecret;

            if (result.agencyStore["_fieldsProto"]) {
              if (result.agencyStore["_fieldsProto"].storeName)
                storeName = result.agencyStore["_fieldsProto"].storeName.stringValue;
              if (result.agencyStore["_fieldsProto"].storeCategory)
                storeCategory =
                  result.agencyStore["_fieldsProto"].storeCategory.stringValue;
              if (result.agencyStore["_fieldsProto"].url)
                url = result.agencyStore["_fieldsProto"].url.stringValue;
              if (result.agencyStore["_fieldsProto"].version)
                version = result.agencyStore["_fieldsProto"].version.stringValue;
              if (result.agencyStore["_fieldsProto"].woocommerceApi)
                woocommerceApi =
                  result.agencyStore["_fieldsProto"].woocommerceApi.stringValue;
              if (result.agencyStore["_fieldsProto"].woocommerceSecret)
                woocommerceSecret =
                  result.agencyStore["_fieldsProto"].woocommerceSecret.stringValue;
              if (result.agencyStore["_fieldsProto"].isPremium)
                isPremium =
                  result.agencyStore["_fieldsProto"].isPremium.booleanValue;
              if (result.agencyStore["_fieldsProto"].storeOwner)
                storeOwner = result.agencyStore["_fieldsProto"].storeOwner.stringValue;
            }
            const store = new AgencyStore(
              storeName,
              storeCategory,
              url,
              version,
              woocommerceSecret,
              woocommerceApi,
              isPremium,
              storeOwner,
              randomPass,
              encryptedKey,
              encryptedSecret
            );
            res(store);
          });
      });
    });
  }

  getUserInfo(agency) {
    return new Promise((res, rej) => {
      this.getCurrentUser()
        .then((email) => {
          this.getStoreName(agency, email).then((user: any) => {
            FirebaseService.permission = user.data().permission;
            localStorage.setItem("prm", window.btoa(FirebaseService.permission.toString()));
            //console.log("FirebaseService.permission: " + FirebaseService.permission );
            if (user.data()) {
              if (
                user.data().newMessages != undefined &&
                user.data().newMessages != "" &&
                user.data().newMessages != null
              ) {
                localStorage.setItem(
                  "newMessages",
                  user.data().newMessages.toString()
                );
              }
              // console.log("is Admin =======================");
              if (
                user.data().isAdmin != undefined &&
                user.data().isAdmin != "false" &&
                user.data().isAdmin != false
              ) {
                FirebaseService.isAdmin = true;
                localStorage.setItem("isAdmin", "true");
              } else {
                FirebaseService.isAdmin = false;
                localStorage.setItem("isAdmin", "false");
              }
              // console.log("is agency =======================");
              if (
                user.data().isAgency != undefined &&
                user.data().isAgency != "false" &&
                user.data().isAgency != false
              ) {
                FirebaseService.isAgency = true;
                localStorage.setItem("isAgency", "true");
              } else {
                FirebaseService.isAgency = false;
                localStorage.setItem("isAgency", "false");
              }
            } else {
              console.log("Error: No valid user.data() -->", user.data());
            }
            res(user);
          });
        })
        .catch((err) => {
          console.log(err);
          rej(err);
        });
    });
  }

  getCurrentUser(): Promise<string> {
    return new Promise((res, rej) => {
      firebase.auth().onAuthStateChanged((user) => {
        // console.log("GET CURRENT USER observable", user.email);
        if (user != null || user != undefined) res(user.email);
        else res("");
      });
      setTimeout(() => {
        rej("TIME OUT FOR get CURRENT USER");
      }, 10000);
    });
  }

  updateUser(userName, storeName, agency) {
    return new Promise((res, rej) => {
      this.getCurrentUser().then((userEmail) => {

        if (!storeName) {
          rej("No storeName");
        }

        this.httpService.requestBackendOrCloud(
          { method: 'POST', endpoint: `user/update-user?agency=${agency}&userEmail=${userEmail}&userName=${userName}&storeName=${storeName}` },
          { method: 'GET', endpoint: `updateUser?agency=${agency}&userEmail=${userEmail}&userName=${userName}&storeName=${storeName}` }
        )
          .subscribe((result) => {
            res(result);
          });
      });
    });
  }

  setNewTicketNotification(activationFlag: boolean, agency: string) {
    return new Promise((res, rej) => {
      this.getCurrentUser().then((userEmail) => {

        if (!agency && !userEmail) {
          rej("No agency or userEmail");
        }
        let requestEndpoint = `setNewTicketNotification?agency=${agency}&userEmail=${userEmail}&activationFlag=${activationFlag}`;
        this.httpService.get(requestEndpoint).subscribe((result) => {
          res(result);
        });
      });
    });
  }

  setReportNotification(activationFlag: boolean, agency: string) {
    return new Promise((res, rej) => {
      this.getCurrentUser().then((userEmail) => {

        if (!agency && !userEmail) {
          rej("No agency or userEmail");
        }
        let requestEndpoint = `setReportNotification?agency=${agency}&userEmail=${userEmail}&activationFlag=${activationFlag}`;
        this.httpService.get(requestEndpoint).subscribe((result) => {
          res(result);
        });
      });
    });
  }

  unsubscribeOnLogOut() {
    //remember to unsubscribe from the snapshotChanges
    // if(this.snapshotChangesSubscription)
    // this.snapshotChangesSubscription.unsubscribe();
  }
  saveAnalyticsGA4Association(params:object) {
    return new Promise((res, rej) => {

      const body = params

        let requestEndpoint = `analyticsFunctions-saveAnalyticsAssociationGA4`;
        //console.log("get messages: " + requestEndpoint);

        this.httpService.post(requestEndpoint, body, true).subscribe((result) => {
          res(result);
        });
    });
  }

  saveAnalyticsAssociation(params: object) {
    return new Promise((res, rej) => {

      const body = params;

      let requestEndpoint = `analyticsFunctions-saveAnalyticsAssociation`;
      //console.log("get messages: " + requestEndpoint);

      this.httpService.post(requestEndpoint, body, true).subscribe((result) => {
        res(result);
      });
    });
  }
  getAgencyMessages() {
    return new Promise((res, rej) => {
      this.getCurrentUser().then((userEmail) => {

        const agency = whitelabelAgency;
        if (!agency && !userEmail) {
          rej("No agency or userEmail");
        }

        let requestEndpoint = `getAgencyMessages?agency=${agency}&userEmail=${userEmail}`;
        //console.log("get messages: " + requestEndpoint);

        this.httpService.get(requestEndpoint).subscribe((result) => {
          console.log("agency messages: ", result);
          res(result);
        });
      });
    });
  }

  getAgencyUsers() {
    return new Promise((res, rej) => {
      this.getCurrentUser().then((userEmail) => {

        const agency = localStorage.getItem("agency");
        if (!agency && !userEmail) {
          rej("No agency or userEmail");
        }
        let requestEndpoint = `getAgencyUsers?agency=${agency}&userEmail=${userEmail}`;
        this.httpService.get(requestEndpoint).subscribe((result) => {
          console.log("agency users: ", result);
          res(result);
        });
      });
    });
  }

  isValidCurrentUser(currentUser) {
    return currentUser != undefined && currentUser != null;
  }

  isValidToken(token) {
    return (
      token != "" && token != undefined && token != null && token != "undefined"
    );
  }

  sendBroadcastMessage(title, body, topic) {

    const agency = localStorage.getItem("agency");
    const params = `?agency=${agency}&topic=${topic}`;
    let reqBody = {
      title: title,
      body: body,
    };
    let requestEndpoint = `broadcastNotification-sendMessageToTopic${params}`;
    return this.httpService.post(requestEndpoint, reqBody, true);
  }

  updateClientPermission(clientEmail, isAdmin) {
    return new Promise((res, rej) => {
      this.getCurrentUser().then((userEmail) => {
        const agency = localStorage.getItem("agency");

        if (!userEmail) {
          rej("Invalid Email");
        }

        let requestEndpoint = `updateClientPermission?agency=${agency}&userEmail=${userEmail}&clientEmail=${clientEmail}&isAdmin=${isAdmin.toString()}`;
        this.httpService.get(requestEndpoint).subscribe(
          (result) => {
            res(result);
          },
          (error) => {
            rej("Error updating permission : " + JSON.stringify(error));
          }
        );
      });
    });
  }

  updateClientStore(clientEmail, storeName, woocommerceApi, woocommerceSecret) {
    return new Promise((res, rej) => {
      this.getCurrentUser().then((userEmail) => {
        const agency = localStorage.getItem("agency");

        if (!userEmail) {
          rej("Invalid Email");
        }

        let backendEndpoint = `user/update-client-store?agency=${agency}&userEmail=${userEmail}&clientEmail=${clientEmail}&storeName=${storeName}&woocommerceApi=${woocommerceApi}&woocommerceSecret=${woocommerceSecret}`
        let requestEndpoint = `updateClientStore?agency=${agency}&userEmail=${userEmail}&clientEmail=${clientEmail}&storeName=${storeName}&woocommerceApi=${woocommerceApi}&woocommerceSecret=${woocommerceSecret}`;
        
        this.httpService.requestBackendOrCloud(
          { method: 'POST', endpoint: backendEndpoint },
          { method: 'GET', endpoint: requestEndpoint }
        )
          .subscribe(
            (result) => {
              res(result);
            },
            (error) => {
              rej("Error updating store: " + JSON.stringify(error));
            }
          );
      });
    });
  }

  clearNewMessages() {
    return new Promise((res, rej) => {
      this.getCurrentUser().then((userEmail) => {

        const agency = localStorage.getItem("agency");
        if (!agency || !userEmail) {
          rej("No agency or userEmail");
        }
        let requestEndpoint = `clearNewMessages?agency=${agency}&userEmail=${userEmail}`;
        this.httpService.get(requestEndpoint).subscribe(
          (result) => {
            res(result);
          },
          (error) => {
            rej("Error clearing messages: " + JSON.stringify(error));
          }
        );
      });
    });
  }

  createClient(clientName, clientStore, clientEmail): Promise<any> {
    return new Promise((res, rej) => {
      this.getCurrentUser().then((userEmail) => {

        const userName = clientName;
        const storeName = clientStore;
        const agency = localStorage.getItem("agency");
        if (!agency || !storeName || !clientEmail) {
          rej("invalid params");
        }

        this.httpService.requestBackendOrCloud(
          { method: 'POST', endpoint: `user/create-client?agency=${agency}&userEmail=${userEmail}&clientEmail=${clientEmail}&storeName=${storeName}&userName=${userName}` },
          { method: 'GET', endpoint: `createClient?agency=${agency}&userEmail=${userEmail}&clientEmail=${clientEmail}&storeName=${storeName}&userName=${userName}` }
        )
          .subscribe(
            (result) => {
              res(result);
            },
            (error) => {
              rej("Error creating client: " + JSON.stringify(error));
            }
          );
      });
    });
  }

  saveToken(token, userEmail) {
    return new Promise((res, rej) => {

      const agency = localStorage.getItem("agency");
      if (!agency || !userEmail || !token) {
        rej("invalid params");
      }
      let requestEndpoint = `broadcastNotification-saveToken?agency=${agency}&token=${token}`;
      this.httpService.get(requestEndpoint).subscribe(
        (result) => {
          res(result);
        },
        (error) => {
          rej("Error saving token: " + JSON.stringify(error));
        }
      );
    });
  }

  getUserStores(agency) {
    return new Promise((res, rej) => {
      // if (agency != "syscoin" && agency != "dashcommerce" && agency != "flow") {
      //   rej("agency invalid");
      //   return;
      // }
      this.getCurrentUser().then((userEmail) => {

        if (userEmail) {
          this.httpService.getFromBackendOrCloud(
            { endpoint: `user/user-stores?agency=${agency}&userEmail=${userEmail}` },
            { endpoint: `getUserStores?agency=${agency}&userEmail=${userEmail}` }
          )
            .subscribe((result: any) => {
              if (result.res === 'empty collection') {
                FirebaseService.storesAmount = 0;
                res([]);
              }
              else {
                FirebaseService.storesAmount = result.length;
                res(result);
              }
            });
        } else {
          FirebaseService.storesAmount = 0;
          rej("invalid userEmail" + userEmail);
        }
      });
    });
  }

  updateProfile(
    userEmail,
    agency,
    userName,
    userNumber,
    profilePicture = "",
    userCountry,
    userRole
  ) {
    return new Promise((res, rej) => {
      this.httpService.requestBackendOrCloud(
        { method: 'POST', endpoint: `user/update-profile?userEmail=${userEmail}&agency=${agency}&userName=${userName}&userNumber=${userNumber}&userRole=${userRole}&userCountry=${userCountry}&profilePicture=${profilePicture}`}, 
        { method: 'GET', endpoint: `updateProfile?userEmail=${userEmail}&agency=${agency}&userName=${userName}&userNumber=${userNumber}&userRole=${userRole}&userCountry=${userCountry}&profilePicture=${profilePicture}` }
      )
        .subscribe(
          (response) => {
            res(response);
          },
          (error) => {
            rej("Error updating profile: " + JSON.stringify(error));
          }
        );
    });
  }

  uploadImage(image: File, bucket: string) {
    return new Promise((res, rej) => {
      let formData = new FormData();
      formData.append("image", image);
      const endpoint = environment.endpoint;

      let requestEndpoint = `storeImage`;

      this.httpClient
        .post<{ imageUrl: string; imagePath: string; }>(
          `${endpoint}${requestEndpoint}?bucket=${bucket}`,
          formData
        )
        .subscribe(
          (response) => {
            res(response);
          },
          (error) => {
            rej("Error uploading image: " + JSON.stringify(error));
          }
        );
    });
  }

  deleteStore(userStore, agency) {
    return new Promise((res, rej) => {
      if (!userStore || !agency) {
        rej("Ivalid params");
      }
      const storeType = localStorage.getItem("storeType");

      this.httpService.requestBackendOrCloud(
        { method: 'DELETE', endpoint: `store/delete-store?userStore=${userStore}&agency=${agency}&storeType=${storeType}` },
        { method: 'GET', endpoint: `deleteStore?userStore=${userStore}&agency=${agency}&storeType=${storeType}` }
      )
        .subscribe(
          (response) => {
            console.log("Loja excluída");
            res(response);
          },
          (error) => {
            rej("Error deleting store: " + JSON.stringify(error));
          }
        );
    });
  }

  deleteUserAccount(agency, userEmail) {
    return new Promise((res, rej) => {
      if (!userEmail || !agency) {
        rej("Ivalid params");
      }

      this.httpService.requestBackendOrCloud(
        { method: 'POST', endpoint: `user/delete-user?agency=${agency}&email=${userEmail}` },
        { method: 'GET', endpoint: `deleteUserAccount?agency=${agency}&email=${userEmail}` }
      )
        .subscribe(
          (response) => {
            console.log("Loja excluída");
            res(response);
          },
          (error) => {
            rej("Error deleting user: " + JSON.stringify(error));
          }
        );
    });

  }

  removeNotificationToken(agency: string, userEmail: string) {
    return new Promise((res, rej) => {
      if (!userEmail || !agency) {
        rej("Ivalid params");
      }
      let notificationToken = "";
      if (localStorage.getItem("notificationToken")) {
        notificationToken = localStorage.getItem("notificationToken");
      }
      let requestEndpoint = `removeNotificationToken?agency=${agency}&userEmail=${userEmail}&notificationToken=${notificationToken}`;

      this.httpService.get(requestEndpoint).subscribe(
        (response) => {
          console.log("token de notificacao excluido");
          res(response);
        },
        (error) => {
          rej("Error deleting token: " + JSON.stringify(error));
        }
      );
    });
  }

  updateStore(
    agency: string,
    storeName: string,
    storeCategory: string,
    url: string,
    woocommerceApi: string,
    woocommerceSecret: string
  ) {
    return new Promise((res, rej) => {
      if (!storeName || !agency) {
        rej("Ivalid params");
      }

      let cloudfnEndpoint = `updateStore?agency=${agency}&storeName=${storeName}&storeCategory=${storeCategory}&storeUrl=${url}&woocommerceApi=${woocommerceApi}&woocommerceSecret=${woocommerceSecret}`;
      let backendEndpoint = `store/update-store?agency=${agency}&storeName=${storeName}&storeCategory=${storeCategory}&storeUrl=${url}&woocommerceApi=${woocommerceApi}&woocommerceSecret=${woocommerceSecret}`;
      
      this.httpService.requestBackendOrCloud(
        { method: 'POST', endpoint: backendEndpoint },
        { method: 'GET', endpoint: cloudfnEndpoint }
      )
        .subscribe(
          (response) => {
            res(response);
          },
          (error) => {
            rej("Error" + JSON.stringify(error));
          }
        );
    });
  }

  newWoocommerceRegister(
    storeName: string,
    storeCategory: string,
    url: string
  ) {
    return new Promise(async (res, rej) => {

      let agency = this.agency;

      let userEmail = localStorage.getItem("userEmail");

      let requestEndpoint = `newCreateStore?agency=${agency}&userEmail=${userEmail}&storeName=${storeName}&storeCategory=${storeCategory}&url=${url}`;

      this.httpService.get(requestEndpoint).subscribe(
        (response) => {
          console.log("Loja criada");
          res(response);
        },
        (error) => {
          rej("Error: " + JSON.stringify(error));
        }
      );
    });
  }

  finishCreateStore(url: string, storeName: string) {
    return new Promise((res, rej) => {
      let requestEndpoint = "finishCreateStore";
      let requestUrl =
        url +
        `wc-auth/v1/authorize?app_name=dashcommerce&scope=read_write&user_id=${storeName}&return_url=http://localhost:8100/dashboard/tabs/tab1&callback_url=${requestEndpoint}`;
      this.httpService.get(requestUrl).subscribe(
        (response) => {
          console.log("Terminada a criação de loja");
          res(response);
        },
        (error) => {
          rej("error:" + JSON.stringify(error));
        }
      );
    });
  }
  deleteTempCollection(storeName: string) {
    return new Promise((res, rej) => {
      let requestEndpoint =
        `checkRegister?storeName=${storeName}`;
      this.httpService.get(requestEndpoint).subscribe(
        (response) => {
          console.log(response);
          res({ Response: "ok" });
        },
        (error) => {
          rej("Error: " + JSON.stringify(error));
        }
      );
    });
  }

  sendSelectedStores(storeList) {
    return new Promise((res, rej) => {
      //console.log("enviando essa lista de lojas pra ativar notif: " + JSON.stringify(storeList))
      let body = { storeList: storeList };

      this.httpService.requestBackendOrCloud(
        { method: 'POST', endpoint: `notification/toggle-multi-store-notification?agency=${this.agency}`, body },
        { method: 'POST', endpoint: `toggleMultiStoreNotification?agency=${this.agency}`, body },
        true
      )
        .subscribe(
          (response) => {
            //console.log("Deu certo");
            res(response);
          },
          (error) => {
            rej("Error sending storeList: " + JSON.stringify(error));
          }
        );
    });
  }

  sendSelectedStatusStores(storeList) {
    return new Promise((res, rej) => {
      let body = { storeList: storeList };

      this.httpService.requestBackendOrCloud(
        { method: 'POST', endpoint: `notification/toggle-multi-store-status-notification?agency=${this.agency}`, body },
        { method: 'POST', endpoint: `toggleMultiStoreStatusNotification?agency=${this.agency}`, body },
        true
      )
        .subscribe(
          (response) => {
            res(response);
          },
          (error) => {
            rej("Error sending storeList: " + JSON.stringify(error));
          }
        );
    });
  }

  getSelectedStoreList() {
    return new Promise((res, rej) => {
      this.httpService.getFromBackendOrCloud(
        { endpoint: `store/selected-stores?agency=${this.agency}` },
        { endpoint: `getSelectedStores?agency=${this.agency}` }
      )
        .subscribe(
          (response) => { res(response); },
          (error) => { rej("Error getting selected store list: " + JSON.stringify(error)); }
        );
    });
  }

  getSelectedStoresStatusNotification() {
    return new Promise((res, rej) => {
      this.httpService.getFromBackendOrCloud(
        { endpoint: `store/selected-stores-status-notification?agency=${this.agency}` },
        { endpoint: `getSelectedStoresStatusNotification?agency=${this.agency}` }
      )
        .subscribe(
          (response) => { res(response); },
          (error) => { rej("Error getting selected store list: " + JSON.stringify(error)); }
        );
    });
  }

  getGeocorder(city: string, street: string) {
    return new Promise((res, rej) => {
      let requestEndpoint =
        `geocoderFunction?city=${city}&street=${street}`;
      this.httpService.get(requestEndpoint).subscribe((response) => {
        res(response);
      }),
        (error) => {
          rej(error);
        };
    });
  }

  saveReportHour(hour) {
    return new Promise((res, rej) => {
      const userEmail = localStorage.getItem("userEmail");
      const agency = localStorage.getItem("agency");

      this.httpService.requestBackendOrCloud(
        { method: 'POST', endpoint: `agency/set-report-hour?agency=${agency}&hour=${hour}` },
        { method: 'GET', endpoint: `setReportHour?agency=${agency}&hour=${hour}` }
      )
        .subscribe(
          (response) => {
          res(response);
        }),
          (error) => {
            rej(error);
          };
    });
  }

  getReportHour() {
    return new Promise((res, rej) => {
      const agency = localStorage.getItem("agency");

      this.httpService.getFromBackendOrCloud(
        { endpoint: `agency/get-report-hour?agency=${agency}` },
        { endpoint: `getReportHour?agency=${agency}` }
      )
        .subscribe((response) => {
          res(response);
        }),
        (error) => {
          rej(error);
        };
    });
  }

  saveUserLanguage(language) {
    return new Promise((res, rej) => {
      const agency = localStorage.getItem("agency");

      this.httpService.requestBackendOrCloud(
        { method: 'POST', endpoint: `user/user-language?agency=${agency}&selectedLanguage=${language}` },
        { method: 'GET', endpoint: `saveUserLanguage?agency=${agency}&selectedLanguage=${language}` }
      )
        .subscribe((response) => {
          res(response);
        }),
        (error) => {
          rej(error);
        };
    });
  }

  associateStore(clientEmail, storeName, permissionList) {
    console.log(JSON.stringify(permissionList));

    let permissionListQueryParam = new URLSearchParams();

    // Add the languages
    permissionList.forEach(function (permission) {
      permissionListQueryParam.append("permission", permission);
    });

    // Explicitly convert to a string
    console.log(permissionListQueryParam.toString());

    return new Promise((res, rej) => {
      const agency = localStorage.getItem("agency");
      const storeType = localStorage.getItem("storeType");

      this.httpService.requestBackendOrCloud(
        { method: 'POST', endpoint: `store/associate-store?${permissionListQueryParam.toString()}&agency=${agency}&clientEmail=${clientEmail}&storeName=${storeName}&storeType=${storeType}` },
        { method: 'GET', endpoint: `associateStore?${permissionListQueryParam.toString()}&agency=${agency}&clientEmail=${clientEmail}&storeName=${storeName}&storeType=${storeType}` }
      )
        .subscribe((response) => {
          res(response);
        }),
          (error) => {
            console.log("ERRRRO ================");
            rej(error);
          };
    });
  }

  disassociateStore(clientEmail, storeName) {
    return new Promise((res, rej) => {
      const agency = localStorage.getItem("agency");
      const storeType = localStorage.getItem("storeType");

      this.httpService.requestBackendOrCloud(
        { method: 'DELETE', endpoint: `store/disassociate-store?agency=${agency}&clientEmail=${clientEmail}&storeName=${storeName}&storeType=${storeType}`},
        { method: 'GET', endpoint: `disassociateStore?agency=${agency}&clientEmail=${clientEmail}&storeName=${storeName}&storeType=${storeType}` }
      )
        .subscribe((response) => {
          res(response);
        }),
          (error) => {
            console.log("ERRRRO ================");
            rej(error);
          };
    });
  }

  getStoreUserList(storeName) {
    return new Promise((res, rej) => {
      const agency = localStorage.getItem("agency");
      const storeType = localStorage.getItem("storeType");

      this.httpService.getFromBackendOrCloud(
        { endpoint: `store/store-users?agency=${agency}&storeName=${storeName}&storeType=${storeType}` },
        { endpoint: `getStoreUsers?agency=${agency}&storeName=${storeName}&storeType=${storeType}` }
      )
        .subscribe((response) => {
          res(response);
        }),
        (error) => {
          console.log("ERRRRO ================");
          rej(error);
        };
    });
  }

  isInactiveStore(storeName) {
    return new Promise((res, rej) => {
      const agency = localStorage.getItem("agency");
      let httpOptions = this.getHttpOptions;
      let requestEndpoint =

        `isInactiveStore?agency=${agency}&storeName=${storeName}`;
      this.httpService.get(requestEndpoint).subscribe((response) => {
        res(response);
      }),
        (error) => {
          console.log("ERRRRO ================");
          rej(error);
        };
    });
  }

  deleteInactiveStore(storeName) {
    return new Promise((res, rej) => {
      const agency = localStorage.getItem("agency");

      let httpOptions = this.getHttpOptions;
      let requestEndpoint =

        `deleteInactiveStore?agency=${agency}&storeName=${storeName}`;
      this.httpService.get(requestEndpoint).subscribe((response) => {
        res(response);
      }),
        (error) => {
          console.log("ERRRRO ================");
          rej(error);
        };
    });
  }

  getInactiveStores(storesArray) {
    return new Promise((res, rej) => {
      const agency = localStorage.getItem("agency");
      const reqBody = {
        storesArray,
      };
      let requestEndpoint =
        `getInactiveStores?agency=${agency}`;
      this.httpService.post(requestEndpoint, reqBody, true).subscribe((response) => {
        res(response);
      }),
        (error) => {
          console.log("ERRRRO ================");
          rej(error);
        };
    });
  }

  fixNotificationFlags() {
    return new Promise((res, rej) => {
      const agency = localStorage.getItem("agency");
      const storeName = localStorage.getItem("storeName");

      this.httpService.getFromBackendOrCloud(
        { endpoint: `notification/notification-flags?agency=${agency}&storeName=${storeName}` },
        { endpoint: `fixNotification-fixNotificationFlags?agency=${agency}&storeName=${storeName}` }
      )
        .subscribe(
          (response) => {
          res(response);
        }),
          (error) => {
            console.log("ERRRRO ================");
            rej(error);
          };
    });
  }

  fixWebhook(webhookType) {
    return new Promise((res, rej) => {
      const agency = localStorage.getItem("agency");
      const storeName = localStorage.getItem("storeName");
      const params = `?agency=${agency}&storeName=${storeName}&webhookType=${webhookType}`;

      this.httpService.getFromBackendOrCloud(
        { endpoint: `notification/fix-webhook` + params },
        { endpoint: `fixNotification-fixWebhook` + params }
      )
        .subscribe((response) => {
          res(response);
        }),
          (error) => {
            console.log("ERRRRO ================");
            rej(error);
          };
    });
  }

  getAdminNotificationFlags() {
    return new Promise((res, rej) => {
      const agency = localStorage.getItem("agency");
      const params = `?agency=${agency}`;

      this.httpService.getFromBackendOrCloud(
        { endpoint: `user/get-admin-notification-flags` + params },
        { endpoint: `getAdminNotificationFlags` + params }
      )
        .subscribe((response) => {
          res(response);
        }),
        (error) => {
          console.log("ERRRRO ================");
          rej(error);
        };
    });
  }

  sendTestNotification() {
    return new Promise((res, rej) => {
      const agency = localStorage.getItem("agency");
      const storeName = localStorage.getItem("storeName");
      const params = `?agency=${agency}&storeName=${storeName}`;

      this.httpService.getFromBackendOrCloud(
        { endpoint: `notification/test-notification` + params },
        { endpoint: `fixNotification-sendTestNotification` + params }
      )
        .subscribe((response) => {
          res(response);
        }),
          (error) => {
            console.log("ERRRRO ================");
            rej(error);
          };
    });
  }

  toggleAdminNotification(type, value) {
    return new Promise((res, rej) => {
      const agency = localStorage.getItem("agency");
      let httpOptions = this.getHttpOptions;
      const params = `?agency=${agency}&type=${type}&active=${value}`;

      let cloudfnEndpoint = `toggleAdminNotification` + params;
      let backendEndpoint = `notification/toggle-admin-notification` + params;

      this.httpService.requestBackendOrCloud(
        { method: 'POST', endpoint: backendEndpoint},
        { method: 'GET', endpoint: cloudfnEndpoint }
      )
        .subscribe((response) => {
          res(response);
        }),
          (error) => {
            console.log("ERRRRO ================");
            rej(error);
          };
    });
  }

  toggleFunnyNotifications(value) {
    return new Promise((res, rej) => {
      const agency = localStorage.getItem("agency");
      let httpOptions = this.getHttpOptions;
      const params = `?agency=${agency}&disableFunnyNotifications=${value}`;

      let cloudfnEndpoint = `toggleFunnyNotifications` + params;
      let backendEndpoint = `notification/funny-notifications` + params;

      this.httpService.getFromBackendOrCloud(
        { endpoint: backendEndpoint },
        { endpoint: cloudfnEndpoint }
      )
        .subscribe((response) => {
          res(response);
        }),
          (error) => {
            console.log("ERRRRO ================");
            rej(error);
          };
    });
  }

  toggleStoreDirectRequest(value) {
    return new Promise((res, rej) => {
      const agency = localStorage.getItem("agency");
      const params = `?agency=${agency}&storeDirectRequest=${value}`;

      this.httpService.requestBackendOrCloud(
        { method: 'POST', endpoint: `user/toggle-direct-request` + params },
        { method: 'GET', endpoint: `userFlagsFunctions-toggleStoreDirectRequest` + params }
      )
        .subscribe((response) => {
          res(response);
        }),
          (error) => {
            console.log("ERRRRO ================");
            rej(error);
          };
    });
  }

  getNews() {
    return new Promise((res, rej) => {
      const agency = localStorage.getItem("agency");
      const language = localStorage.getItem("language");
      const params = `?agency=${agency}&language=${language}`;

      this.httpService.getFromBackendOrCloud(
        { endpoint: "agency/news" + params },
        { endpoint: "getNews" + params }
      )
        .subscribe((response) => {
          res(response);
        }, (error) => {
          rej(error);
        });
    });
  }

  getLastNewsTimestamp() {
    return new Promise((res, rej) => {
      const agency = localStorage.getItem("agency");
      const userEmail = localStorage.getItem("userEmail");
      const language = localStorage.getItem("language");
      const params = `?agency=${agency}&userEmail=${userEmail}&language=${language}`;

      this.httpService
        .getFromBackendOrCloud(
          { endpoint: "woocommerce/get-last-news-timestamp" + params },
          { endpoint: "getLastNewsTimestamp" + params }
        )
        .subscribe((response) => {
          res(response);
        }, (error) => {
          rej(error);
        });
    });
  }

  setUserNewsTimestamp() {
    return new Promise((res, rej) => {
      const agency = localStorage.getItem("agency");
      const userEmail = localStorage.getItem("userEmail");
      const params = `?agency=${agency}&userEmail=${userEmail}`;

      this.httpService.requestBackendOrCloud(
        { method: 'POST', endpoint: "user/set-news-timestamp" + params },
        { method: 'GET', endpoint: "setUserNewsTimestamp" + params}
      )
        .subscribe((response) => {
          res(response);
        }, (error) => {
          rej(error);
        });
    });
  }

  getAdminNews() {
    return new Promise((res, rej) => {
      let requestEndpoint = "getAdminNews";

      this.httpService.getFromBackendOrCloud(
        { endpoint: "user/admin-news" },
        { endpoint: "getAdminNews" }
      )
        .subscribe((response) => {
          res(response);
        }, (error) => {
          rej(error);
        });
    });
  }

  getOrdersBadge() {
    const storeName = localStorage.getItem("storeName");
    const agency = localStorage.getItem("agency");
    let requestEndpoint = `woocommerceFunctions-getOrdersBadge?storeName=${storeName}&agency=${agency}`;

    return this.httpService.get(requestEndpoint);
  }

  getProfileInfo() {
    return new Promise((res, rej) => {
      const userEmail = localStorage.getItem("userEmail");

      this.httpService.getFromBackendOrCloud(
        { endpoint: `user/get-user?userEmail=${userEmail}` },
        { endpoint: `getUser?userEmail=${userEmail}` }
      )
        .subscribe((response) => {
          res(response);
        }, (error) => {
          rej(error);

        });;
    });
  }

  checkPermission(permissionToCheck) {
    return FirebaseService.checkPermissionStatic(permissionToCheck);
  }

  static checkPermissionStatic(permissionToCheck) {
    if (FirebaseService.permission == "ALL" || FirebaseService.permission == "DEPRECATED_PERMISSION") return true;
    else if (FirebaseService.permission == "NONE") return false;
    else if (FirebaseService.permission.indexOf(permissionToCheck) >= 0) return true;
    else return false;
  }

  static firstPermission() {
    //Checa se tem mais de uma permissão na string
    let permissions: string = FirebaseService.permission.toString();
    console.log("Checando a FIRST PERMISSION: " + permissions);
    console.log("index da virgula: " + permissions.indexOf(','));
    if (permissions.indexOf(",") >= 0) {
      let firstPermission = permissions.split(",");
      console.log("a FIRSTT PERMISSION: " + firstPermission);

      return firstPermission[0];
    }
    else {
      console.log("a FIRST PERMISSION: " + FirebaseService.permission);
      return FirebaseService.permission;
    }
  }

}
