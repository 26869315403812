import { Injectable } from "@angular/core";
import { AngularFirestore } from "@angular/fire/firestore";
import { AngularFireAuth } from "@angular/fire/auth";
import { environment } from "../environments/environment";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import * as firebase from "firebase/app";
import { error } from "util";
import { Subscription } from "rxjs/Subscription";
import { HttpService } from "./http.service";

@Injectable({
  providedIn: "root",
})
export class AnalyticsService {
  constructor(
    public firestore: AngularFirestore,
    public afAuth: AngularFireAuth,
    private httpClient: HttpClient,
    private httpService: HttpService
  ) {}

  postHttpOptions = {
    headers: new HttpHeaders({
      "Content-Type": "text/plain",
      Authorization: "UNAUTHORIZED",
    }),
  };

  getHttpOptions = {
    headers: new HttpHeaders({
      "Content-Type": "application/x-www-form-urlencoded",
      Authorization: "UNAUTHORIZED",
    }),
  };

  httpOptions = {
    headers: new HttpHeaders({
      "Content-Type": "application/x-www-form-urlencoded",
      Authorization: "UNAUTHORIZED",
    }),
  };

  isValidCurrentUser(currentUser) {
    return (
      currentUser != undefined &&
      currentUser != null &&
      currentUser != "undefined"
    );
  }

  isValidToken(token) {
    return (
      token != "" && token != undefined && token != null && token != "undefined"
    );
  }

  isLoggedIn(): boolean {
    const user = JSON.parse(localStorage.getItem("user"));
    return user !== null;
  }



  createAnalyticsDoc(userEmail) {
    return new Promise((res, rej) => {
      let requestEndpoint = `createAnalyticsDoc?userEmail=${userEmail}&version=10.1`;
      this.httpService.get(requestEndpoint).subscribe(
        (response) => {
          res("OK");
        },
        (error) => {
          rej("Error: " + JSON.stringify(error));
        }
      );
    });
  }

  deleteAnalyticsDoc(userEmail) {
    return new Promise((res, rej) => {
      let requestEndpoint = `deleteAnalyticsDoc?userEmail=${userEmail}&version=10.1`;
      this.httpService.get(requestEndpoint).subscribe(
        (response) => {
          res("OK");
        },
        (error) => {
          rej("Error: " + JSON.stringify(error));
        }
      );
    });
  }

  getAnalyticsProfiles() {
    return new Promise((res, rej) => {
      //const storeName = localStorage.getItem('storeName')
      const tokens = JSON.stringify(localStorage.getItem("analyticsToken"));

      let requestEndpoint = `analyticsFunctions-newAnalyticsProfiles`;
      this.httpService.get(requestEndpoint).subscribe(
        (response) => {
          res(JSON.stringify(response));
        },
        (error) => {
          rej("Error: " + JSON.stringify(error));
        }
      );
    });
  }

  getPropertyId() {
    return new Promise((res, rej) => {
      const storeName = localStorage.getItem('storeName');
      const agency = environment.whitelabelAgency;
      // const tokens = JSON.stringify(localStorage.getItem("analyticsToken"));

      let requestEndpoint = `analyticsFunctions-getPropertyId?storeName=${storeName}&agency=${agency}`;
      console.log("CHAMANDO A GETPROPERTIID: "+ requestEndpoint)
      this.httpService.get(requestEndpoint).subscribe(
        (response) => {
          console.log("GETPROPERTIID - ok:" + JSON.stringify(response));
          res(response.result);
        },
        (error) => {
          console.log("GETPROPERTIID - error:" + JSON.stringify(error));

          rej("Error: " + JSON.stringify(error));
        }
      );
    });
  }

  getActiveUsersGA4(viewId, storeName) {
    return new Promise((res, rej) => {
      const userEmail = localStorage.getItem("userEmail");
      let requestEndpoint = `analyticsFunctions-analyticsRealTimeGA4?propertyID=${viewId}&storeName=${storeName}`;
      this.httpService.get(requestEndpoint).subscribe(
        (response) => {
          res(response);
        },
        (error) => {
          rej("Error: " + JSON.stringify(error));
        }
      );
    });
  }

  getActiveUsers(viewId) {
    return new Promise((res, rej) => {
      const userEmail = localStorage.getItem("userEmail");
      let requestEndpoint = `analyticsFunctions-analyticsRealTimeGA4?propertyID=${viewId}`;
      this.httpService.get(requestEndpoint).subscribe(
        (response) => {
          res(response);
        },
        (error) => {
          rej("Error: " + JSON.stringify(error));
        }
      );
    });
  }

  getSourceSessionsGA4(viewId, startDate, endDate) {
    return new Promise((res, rej) => {
      const userEmail = localStorage.getItem("userEmail");
      let requestEndpoint = `analyticsFunctions-analyticsSourceSessionsGA4?propertyID=${viewId}&startDate=${startDate}&endDate=${endDate}`;
      this.httpService.get(requestEndpoint).subscribe(
        (response) => {
          res(response);
        },
        (error) => {
          rej("Error: " + JSON.stringify(error));
        }
      );
    });
  }

  getPagePathSessionsGA4(viewId, startDate, endDate) {
    return new Promise((res, rej) => {
      const userEmail = localStorage.getItem("userEmail");
      let requestEndpoint = `analyticsFunctions-analyticsPagePathSessionsGA4?propertyID=${viewId}&startDate=${startDate}&endDate=${endDate}`;
      this.httpService.get(requestEndpoint).subscribe(
        (response) => {
          res(response);
        },
        (error) => {
          rej("Error: " + JSON.stringify(error));
        }
      );
    });
  }

  getEachPageViewGA4(propertyID) {
    return new Promise((res, rej) => {
      //const storeName = localStorage.getItem("storeName");
      let requestEndpoint = `analyticsFunctions-analyticsRealTimeEachPageDataGA4?propertyID=${propertyID}`;
      this.httpService.get(requestEndpoint).subscribe(
        (response) => {
          res(response);
        },
        (error) => {
          rej("Error GA4: " + JSON.stringify(error));
        }
      );
    });
  }

  getPageViewsGA4(propertyID) {
    return new Promise((res, rej) => {
      //const storeName = localStorage.getItem("storeName");
      let requestEndpoint = `analyticsFunctions-analyticsRealTimePagesGA4?propertyID=${propertyID}`;
      this.httpService.get(requestEndpoint).subscribe(
        (response) => {
          res(response);
        },
        (error) => {
          rej("Error GA4: " + JSON.stringify(error));
        }
      );
    });
  }

  getActivePages(viewId) {
    return new Promise((res, rej) => {
      const userEmail = localStorage.getItem("userEmail");
      let requestEndpoint = `analyticsRealTimePages?viewId=${viewId}&userEmail=${userEmail}&version=10.1`;
      this.httpService.get(requestEndpoint).subscribe(
        (response) => {
          res(response);
        },
        (error) => {
          rej("Error GA4: " + JSON.stringify(error));
        }
      );
    });
  }

  getTrafficOrigin(viewId) {
    return new Promise((res, rej) => {
      const userEmail = localStorage.getItem("userEmail");
      let requestEndpoint = `analyticsRealTimeTrafficOrigin?viewId=${viewId}&userEmail=${userEmail}&version=10.1`;
      this.httpService.get(requestEndpoint).subscribe(
        (response) => {
          res(response);
        },
        (error) => {
          rej("Error: " + JSON.stringify(error));
        }
      );
    });
  }

  getSessions(viewId, date) {
    return new Promise((res, rej) => {
      const userEmail = localStorage.getItem("userEmail");
      let requestEndpoint = `analyticsSessions?viewId=${viewId}&userEmail=${userEmail}&date=${date}&version=10.1`;
      this.httpService.get(requestEndpoint).subscribe(
        (response) => {
          res(response);
        },
        (error) => {
          rej("Error: " + JSON.stringify(error));
        }
      );
    });
  }

  getKpiSessions(minDate, maxDate) {
    return new Promise((res, rej) => {
      const storeName = localStorage.getItem("storeName");
      const agency = localStorage.getItem("agency");
      let requestEndpoint = `analyticsFunctions-analyticsSessionsKpi?&startDate=${minDate}&endDate=${maxDate}&storeName=${storeName}&agency=${agency}&version=10.1`;
      this.httpService.get(requestEndpoint).subscribe(
        (response) => {
          res(response);
        },
        (error) => {
          rej("Error: " + JSON.stringify(error));
        }
      );
    });
  }

  getKpiSessionsGA4(propertyID, minDate, maxDate) {
    return new Promise((res, rej) => {
      const storeName = localStorage.getItem('storeName')
      //console.log('=====KPIS DATE======', minDate, maxDate)
      let requestEndpoint = `analyticsFunctions-analyticsPagePathSessionsGA4?propertyID=${propertyID}&startDate=${minDate}&endDate=${maxDate}`;
      this.httpService.get(requestEndpoint).subscribe(
        (response) => {
          res(response);
        },
        (error) => {
          rej("Error: " + JSON.stringify(error));
        }
      );
    });
  }

  getKpiConversionBySourceGA4(propertyID, minDate, maxDate) {
    return new Promise((res, rej) => {
      const agency = localStorage.getItem('agency'); 
      //console.log('=-=-- DATW CONVERSION +_+__', minDate, maxDate);
      let requestEndpoint = `analyticsFunctions-analyticsConversionRateSourceGA4?propertyID=${propertyID}&startDate=${minDate}&endDate=${maxDate}&agency=${agency}`;
      this.httpService.get(requestEndpoint).subscribe(
        (response) => {
          res(response);
        },
        (error) => {
          rej("Error: " + JSON.stringify(error));
        }
      );
    });
  }
}
