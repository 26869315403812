import { Component, NgZone, ViewChild, ChangeDetectorRef } from '@angular/core';
import { IAPQueryCallback, IAPProduct, IAPError } from '@ionic-native/in-app-purchase-2/ngx';
import { PremiumService } from '../../services/premium.service';
import {LoadingProvider} from "../../providers/loading";
import { AlertController, IonSlides, Platform } from '@ionic/angular';
import { environment } from '../../environments/environment';
import { ModalController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { Device } from '@ionic-native/device/ngx';
import { FirebaseService } from 'src/services/firebase.service';
import { LottieModule } from "ngx-lottie";
import player from 'lottie-web';
import { Plugins } from '@capacitor/core';

const { Browser } = Plugins;

export function playerFactory() {
  return player;
}

const tab1Logo = environment.tab1Logo;
const tab1LogoLight = environment.tab1LogoLight;
const whatsappLink = environment.whatsappLink;
const privacyPolicyLink = environment.privacyPolicyLink;
@Component({
  selector: 'app-premium',
  templateUrl: './premium.page.html',
  styleUrls: ['./premium.page.scss'],
})


export class PremiumPage {

  public showStoreLimitWarning = false;
  public tab1Logo = tab1Logo;
  public tab1LogoLight = tab1LogoLight;
  public ls = localStorage;
  public lottieSlide1: any;
  public lottieSlide2: any;
  public lottieProgress: any;
  public lottieSuccess: any;
  public lottieIcon: any;
  public lottieIconPro: any;
  public planTypeSelected = '';
  filteredProducts = [];
  public whatsappLink = whatsappLink;
  public privacyPolicyLink = privacyPolicyLink;
  inProgress = false;
  onSuccess = false;
  private disableSingle = false
  private disableAgency = false
  private disableEnterprise = false


  public singlePlanAmountOfStores = PremiumService.singlePlanAmountOfStores;
  public agencyPlanAmountOfStores = PremiumService.agencyPlanAmountOfStores;
  public enterprisePlanAmountOfStores = PremiumService.enterprisePlanAmountOfStores;
  
  @ViewChild('mySlider', { static: true }) slides: IonSlides;
  public currentIndex = 0;
  debugCount = 0;
  constructor(
    public modalController: ModalController,
    public loading: LoadingProvider,
    public platform: Platform,
    private translate: TranslateService,
    private device: Device,
    private _changeDetection: ChangeDetectorRef,
    public premiumService: PremiumService,
    private firebaseService: FirebaseService,
    public alertCtrl: AlertController) {
      LottieModule.forRoot({ player: playerFactory });
    this.lottieSlide1= {
      path: "../../assets/animations/premium0.json",
      autoplay: true,
      loop: true,
    };
    this.lottieSlide2= {
      path: "../../assets/animations/premium2.json",
      autoplay: true,
      loop: false,
    };

    this.lottieProgress= {
      path: "../../assets/animations/loading_crown.json",
      autoplay: true,
      loop: true,
    };
    
    this.lottieSuccess= {
      path: "../../assets/animations/purchase_completed.json",
      autoplay: true,
      loop: false,
    };

    this.lottieIcon= {
      path: "../../assets/animations/premium3.json",
      autoplay: true,
      loop: false,
    };
    this.lottieIconPro= {
      path: "../../assets/animations/pro.json",
      autoplay: false,
      loop: false,
    };
  }
  

  
  public serviceReference = PremiumService;

  
  public isMobile = true;

  slideChanged(){
    this.slides.getActiveIndex().then(number => {
      this.currentIndex = number;    
      this._changeDetection.detectChanges();}
    );
  }
  
  swipeNext(){
    this.slides.slideNext();
    this.slides.getActiveIndex().then(number => this.currentIndex = number);
    this._changeDetection.detectChanges();
  }
  swipeBack(){
    this.slides.slidePrev();
    this.slides.getActiveIndex().then(number => this.currentIndex = number);
    this._changeDetection.detectChanges();
  }
  async blockPlansByStoreNumber(){
    if(!(FirebaseService.storesAmount <= PremiumService.singlePlanAmountOfStores)){
      if(FirebaseService.storesAmount > PremiumService.singlePlanAmountOfStores)this.disableSingle = true
      if(FirebaseService.storesAmount > PremiumService.agencyPlanAmountOfStores)this.disableAgency = true
      if(FirebaseService.storesAmount > PremiumService.enterprisePlanAmountOfStores)this.disableEnterprise = true  
    } 
  }

  ionViewDidEnter() {
    PremiumService.onSuccess = false; //Reseting the purchasing process
    this.blockPlansByStoreNumber()
    if (this.platform.is('cordova')){
      this.isMobile = true;
    }
    else {
      this.isMobile = false;
    }
  }


  private async processValidation (product: IAPProduct, callback, response){
    this.hideLoader();
    if (response.status === 'success') {
      callback(true, product.transaction);
      try {
        const platform = this.device.platform === 'Android' ? 'android': 'ios'
        this.showLoader()
        await this.premiumService.setPremiumUser(product,platform)
        this.hideLoader()
        this.premiumService.showSuccessPurchaseMessage();
        product.finish();
      } catch (error) {
        alert('Error setting premium user')
        alert(JSON.stringify(error))
        this.hideLoader()
      }
    }
    else {
      callback(false, 'validation-fails');
    }
  }

  private validationError(callback){
    this.hideLoader();
    callback(false, 'validation-fails');
  }

  private hideLoader() {
    // TODO : Hide loader spinner
    this.loading.dismiss();
  }

  private showLoader() {
    // TODO : Show loader spinner
    this.loading.present();

  }

  public order(creditID){
    this.inProgress = true;
    // this.showLoader();
    this.premiumService.order(creditID).then(data => {
      // this.hideLoader();
    }, err => {
      // this.hideLoader();
      this.inProgress = false;
      this.premiumService.showUnexpectedErrorMessage();
    })
  }

  public convertPriceFromStringToNumber(price){

    let newNumber = Number(price.replace(/[^0-9.-]+/g,"").split('.').join(""))/100
    if(newNumber == undefined || newNumber == null) newNumber = 1;
      return newNumber;
  }

  public calculateDiscountPercentage(originalPrice, promotionalPrice){
    let price = 1;
    let promoPrice = 1;
    if (originalPrice) price = this.convertPriceFromStringToNumber(originalPrice);
    if (promotionalPrice) promoPrice = this.convertPriceFromStringToNumber(promotionalPrice);


    if(price == 0) price = 1;
    let discount = 100* (1 - (promoPrice/price))

    if(discount == undefined || discount == null) discount = 1;

    return discount;
  }

  public calculateEquivalentMonthPrice(price, introPrice, billingPeriodUnit){
    if(price == null) return;

    let priceFix  = this.convertPriceFromStringToNumber(price);
    let introPriceFix;
    if(introPrice == null || Number(introPrice.replace(/[^0-9.-]+/g,"").split('.').join("")) == 0  ) introPriceFix = priceFix;
    else  introPriceFix = Number(introPrice.replace(/[^0-9.-]+/g,"").split('.').join(""))/100;

    if(introPriceFix == undefined || introPriceFix == null) introPriceFix = 1;

    if(billingPeriodUnit == "Month") return introPriceFix;
    else return introPriceFix/12;
  }

  public calculateEquivalentStorePrice(monthPrice, id){

    let equivalentPrice;
    if (id.includes('single')){
      equivalentPrice = Number(monthPrice)/this.singlePlanAmountOfStores;
    } else if (id.includes('agency')){
      equivalentPrice = Number(monthPrice)/this.agencyPlanAmountOfStores;
    } else if (id.includes('enterprise')){
      equivalentPrice = Number(monthPrice)/this.enterprisePlanAmountOfStores
    }
    else return 1;

    if(equivalentPrice == undefined || equivalentPrice == null) equivalentPrice = 1;

    return equivalentPrice;
  }

  protected getUserCurrency() {
    if (localStorage.getItem("currency") === null) {
      return "$";
    } else {
      return localStorage.getItem("currency");
    }
  }

  protected getUserCurrencyLocale() {
    return this.translate.instant("LOCALE_UNICODE");
  }


  public getAmountOfStores(productId){
    if(productId == undefined) return 1;
    else if(productId.includes('single')) return this.singlePlanAmountOfStores;
    else if (productId.includes('agency'))return this.agencyPlanAmountOfStores;
    else if (productId.includes('enterprise'))return this.enterprisePlanAmountOfStores;
    else return 1;
  }
 
  async closeModal() {
    const onClosedData: string = "Wrapped Up!";
    await this.modalController.dismiss();
  }
  
  isDarkTheme() {
    return document.getElementsByClassName('dark').length > 0;
  }
  setPlanType(planTypeSelected) {
    this.filterProductsByType(planTypeSelected) 
    this.planTypeSelected = planTypeSelected;
  }

  filterProductsByType(planType){
    const products = PremiumService.products
    if(planType === 'single'){
      this.filteredProducts = products.filter( product => {
       return (product.id === 'app.dashcommerce.subscription.single.year'|| 
       product.id === 'app.dashcommerce.subscription.single.month')
      })
    }
    if(planType === 'agency'){
      this.filteredProducts = products.filter( product => {
       return (product.id === 'app.dashcommerce.subscription.agency.year'|| 
       product.id === 'app.dashcommerce.subscription.agency.month')
      })
    }
    if(planType === 'enterprise'){
      this.filteredProducts = products.filter( product => {
       return (product.id === 'app.dashcommerce.subscription.enterprise.year'|| 
       product.id === 'app.dashcommerce.subscription.enterprise.month')
      })
    }
    if(planType === ''){
      this.filteredProducts = []
    }
  }

  async openPage(page) {
    if ((this.platform.is('capacitor'))) {
        await Browser.open({
            presentationStyle: 'popover',
            url: page,
          });
    } else {
        window.open(page, '_blank', 'location=yes');
    }

  }

  showPlans(){
    return this.planTypeSelected === '' &&
           !this.serviceReference.inProgress && 
           !this.serviceReference.onSuccess &&
           this.isMobile
  }

  public async showAlert(message) {
    let alert = await this.alertCtrl.create({
      message: message,
      cssClass: "secondary",
      buttons: [
        {
          text: "Ok",
          handler: (data) => {},
        },
      ],
    });

    await alert.present();
  }

  startDebugCount(){
    const products = PremiumService.products
    this.debugCount++
    if(this.debugCount === 5){
      this.showAlert('Product sample (products[0]) :  ' + JSON.stringify(products[0]))
      this.showAlert('Products on category :  ' + JSON.stringify(this.filteredProducts))
      this.showAlert('calculateEquivalentStorePrice (products[0]) => '+ this.calculateEquivalentStorePrice(this.calculateEquivalentMonthPrice(products[0].price, 
                                                                                                                                              products[0].introPrice, 
                                                                                                                                              products[0].billingPeriodUnit), 
                                                                                                                                              products[0].id
                                                                                                                                              ))
      this.showAlert('calculateEquivalentMonthPrice (products[0]) => '+ this.calculateEquivalentMonthPrice(products[0].price,
                                                                                                           products[0].introPrice,
                                                                                                           products[0].billingPeriodUnit) )
      this.showAlert('convertPriceFromStringToNumber(product.introPrice) (products[0]) => '+ this.convertPriceFromStringToNumber(products[0].introPrice) )
      this.showAlert('this.calculateDiscountPercentage(product.price, product.introPrice) (products[0]) => '+ this.calculateDiscountPercentage(products[0].price, products[0].introPrice) )
    }

    setTimeout(() => {
      this.debugCount = 0 
    }, 6000);
  }

  slideOpts = {
    initialSlide: 0,
    pagination: false,
    allowTouchMove:false,
    speed: 400
  };

}
