
import { Component, OnInit } from "@angular/core";
import { BarcodeScanner } from "@ionic-native/barcode-scanner/ngx";
import {
  Platform,
  AlertController,
  NavParams,
  ModalController,
} from "@ionic/angular";
import { WoocommerceService } from "../../../services/woocommerce.service";
import { LoadingProvider } from "../../../providers/loading";
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-tracking-code-modal',
  templateUrl: './tracking-code-modal.component.html',
  styleUrls: ['./tracking-code-modal.component.scss']
})
export class TrackingCodeModalComponent implements OnInit {

  public barcodeData: string;
  private orderId: string = this.navParams.data.orderId;

  constructor(
    private barcodeScanner: BarcodeScanner,
    private plataform: Platform,
    private alertCtrl: AlertController,
    private woocommerceService: WoocommerceService,
    private loading: LoadingProvider,
    private navParams: NavParams,
    private modalController: ModalController,
    private translate: TranslateService,
  ) {}


  ngOnInit() {
  }

  async openScanner() {
    if (this.plataform.is("cordova")) {
      this.barcodeScanner
        .scan()
        .then((barcodeData) => {
          this.barcodeData = barcodeData.text;
        })
        .catch((err) => {
          console.log(err);
        });
    } else {
      const alertModal = await this.alertCtrl.create({
        header: this.translate.instant('MOBILE_ONLY'),
        buttons: [{ text: "Ok", handler: (data) => {} }],
      });
      await alertModal.present();
    }
  }

  async insertTrackingCode() {
    if (this.barcodeData !== "" && this.barcodeData !== undefined) {
      this.loading.present();
      this.woocommerceService
        .updateTrackingCode(this.orderId, this.barcodeData)
        .then(() => {
          this.loading.dismiss();
          this.modalController.dismiss(this.barcodeData);
        })
        .catch(async (erro) => {
          this.loading.dismiss();
          const errorAlert = await this.alertCtrl.create({
            header: this.translate.instant('DEFAULT_ERROR'),
            buttons: [{ text: "Ok", handler: (data) => {} }],
          });
          await errorAlert.present();
        });
    } else {
      const errorAlert = await this.alertCtrl.create({
        header: this.translate.instant('EMPTY_TRACKING_CODE'),
        buttons: [{ text: "Ok", handler: (data) => {} }],
      });
      await errorAlert.present();
    }
  }

  async closeModal() {
    await this.modalController.dismiss();
  }


}


