import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { IonicModule } from '@ionic/angular';
import { ReportListModalPage } from './report-list-modal.page';
import { TranslateModule } from '@ngx-translate/core';
import { RouterModule, Routes } from '@angular/router';
import { StatusOrdersComponent } from '../components/status-orders/status-orders.component';
import { TotalOrdersComponent } from '../components/total-orders/total-orders.component';
import { ItensOrderCardComponent } from '../components/itens-order-card/itens-order-card.component';

const routes: Routes = [
  {
    path: 'report-list-modal',
    component: ReportListModalPage
  }
];

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    IonicModule,
    ReactiveFormsModule,
    RouterModule.forChild(routes),
    TranslateModule.forChild(),
  ],
  declarations: [ReportListModalPage, StatusOrdersComponent, TotalOrdersComponent]
})
export class ReportListModalPageModule {}
