import { Component, OnInit } from "@angular/core";
import { ModalController } from "@ionic/angular";
import { ViewChild } from "@angular/core";
import { IonInfiniteScroll } from "@ionic/angular";
import { AlertController } from "@ionic/angular";
import { IonSlides } from "@ionic/angular";
import { FirebaseService } from "../../services/firebase.service";
import { FormBuilder, Validators } from "@angular/forms";
import { LoadingProvider } from "../../providers/loading";
import { TranslateService } from "@ngx-translate/core";
import { Platform } from "@ionic/angular";
import { Router } from "@angular/router";
import { FirebaseAnalyticsService } from "../../services/firebase-analytics.service";
import { Plugins } from "@capacitor/core";
import { IAngularMyDpOptions, IMyDateModel } from "angular-mydatepicker";
import { WoocommerceService } from "src/services/woocommerce.service";
import { environment } from "src/environments/environment";

const { Browser } = Plugins;
const whitelabelAgency = environment.whitelabelAgency;

@Component({
  selector: "app-messages-modal",
  templateUrl: "./messages-modal.page.html",
  styleUrls: ["./messages-modal.page.scss"],
})
export class MessagesModalPage implements OnInit {
  @ViewChild(IonInfiniteScroll) infiniteScroll: IonInfiniteScroll;
  @ViewChild("mySlider") slides: IonSlides;

  messageForm = this.formBuilder.group({
    title: ["", [Validators.required, Validators.maxLength(55)]],
    body: ["", [Validators.required]],
  });

  slideOpts = {
    initialSlide: 0,
    speed: 400,
  };

  public messageList = [];
  public myDateRangePickerOptions: IAngularMyDpOptions;
  private myDateRangePickermodel: IMyDateModel;
  public minDateForDatePicker;
  public maxDateForDatePicker;
  count = 1;
  showLoading: boolean = true;
  hideList: boolean;
  modalType: any;
  bestSellers: any;
  public whitelabelAgency = whitelabelAgency;
  // agencyTopics = ["syscoin", "dashcommerce"];
  plans = ["free", "premium"];
  languages = ["en", "esMX", "es", "da", "ge", "ptBR", "it"];
  selectedAgencyTopic = whitelabelAgency;
  selectedPlan = null;
  selectedLanguage = null;

  constructor(
    private modalController: ModalController,
    public alertCtrl: AlertController,
    private firebaseService: FirebaseService,
    private formBuilder: FormBuilder,
    public loading: LoadingProvider,
    private translate: TranslateService,
    public platform: Platform,
    public router: Router,
    private analyticsService: FirebaseAnalyticsService,
    public woocommerceService: WoocommerceService
  ) {
    // if(!this.isAdmin()){
    //   this.slides.lockSwipeToNext(true);
    //   this.slides.lockSwipeToPrev(true);
    // }
    this.myDateRangePickermodel = {
      isRange: true,
      singleDate: null,
    };

    this.myDateRangePickerOptions = {
      dayLabels:
        this.translate.currentLang === "ptBR"
          ? {
              su: "Dom",
              mo: "Seg",
              tu: "Ter",
              we: "Qua",
              th: "Qui",
              fr: "Sex",
              sa: "Sab",
            }
          : {
              su: "Sun",
              mo: "Mon",
              tu: "Tue",
              we: "Wen",
              th: "Thu",
              fr: "Fri",
              sa: "Sat",
            },
      monthLabels:
        this.translate.currentLang === "ptBR"
          ? {
              1: "Jan",
              2: "Fev",
              3: "Mar",
              4: "Abr",
              5: "Mai",
              6: "Jun",
              7: "Jul",
              8: "Ago",
              9: "Set",
              10: "Out",
              11: "Nov",
              12: "Dez",
            }
          : {
              1: "Jan",
              2: "Feb",
              3: "Mar",
              4: "Apr",
              5: "May",
              6: "Jun",
              7: "Jul",
              8: "Aug",
              9: "Sep",
              10: "Oct",
              11: "Nov",
              12: "Dec",
            },
      firstDayOfWeek: "su",
      dateRange: true,
    };
    if (this.modalType != "Products") {
      this.loadData(1);
    } else {
      setTimeout(() => {
        this.showLoading = false;
      }, 2000);
    }
  }

  async ngOnInit() {
    this.hideList = false;
  }

  ionViewDidEnter() {
    this.analyticsService.trackScreen("Messages Modal");
    if (!this.isAdmin()) {
      this.slides.lockSwipeToNext(true);
      this.slides.lockSwipeToPrev(true);
    }
  }

  getTitle() {
    return this.modalType === "Products"
      ? this.translate.instant("PRODUCTS")
      : this.translate.instant("MESSAGES");
  }

  async closeModal() {
    const onClosedData: string = "Wrapped Up!";
    await this.modalController.dismiss(onClosedData);
  }

  loadData(event) {
    this.showLoading = true;
    this.messageList = [];
    this.firebaseService.getAgencyMessages().then((result: any) => {
      result.reverse().forEach((doc) => {
        let messageObject = {
          title: doc.title,
          body: doc.body,
          createdAt: doc.createTime,
        };
        this.messageList.push(messageObject);
      });
      this.showLoading = false;
      this.messageList.sort(function (x, y) {
        let date1: any = new Date(x.createdAt);
        let date2: any = new Date(y.createdAt);
        return date2 - date1;
      });
    });
    setTimeout(() => {
      if (event) {
        if (event.target) event.target.complete();
      }
    }, 500);
  }

  toggleInfiniteScroll() {
    this.infiniteScroll.disabled = !this.infiniteScroll.disabled;
  }

  async showMessage(message) {
    const displayMessage = await this.alertCtrl.create({
      message: message.body,
      header: message.title,
      cssClass: "secondary",
      buttons: [
        {
          text: "Ok",
          handler: (data) => {},
        },
      ],
    });
    await displayMessage.present();
  }

  public openLink(html) {
    let parser = new DOMParser();
    let temp = parser.parseFromString(html, "text/html").documentElement;
    let links = temp.getElementsByTagName("a"),
      hrefs = [];
    for (var i = 0; i < links.length; i++) {
      hrefs.push(links[i].href);
    }

    for (var i = 0; i < hrefs.length; i++) {
      this.openPageExternally(hrefs[i]);
    }
  }

  async openPageExternally(page) {
    if (this.platform.is("capacitor")) {
      await Browser.open({
        presentationStyle: "popover",
        url: page,
      });
    } else {
      window.open(page, "_blank", "location=yes");
    }
  }

  newMessage() {
    this.slides.lockSwipeToNext(false);
    this.slides.slideNext();
    setTimeout(() => {
      this.hideList = true;
    }, 500);
  }

  isTopicSelected() {
    const isWhitelabel = this.selectedAgencyTopic != "dashcommerce";
    const isDashTopicValid = this.selectedLanguage && this.selectedPlan;
    return isWhitelabel ? true : isDashTopicValid;
  }

  async submitMessage() {
    if (this.isTopicSelected()) {
      this.loading.present();
      const agencyTopic = this.selectedAgencyTopic;
      const language = this.selectedLanguage;
      const plan = this.selectedPlan;
      const isWhitelabel = agencyTopic != "dashcommerce";
      const agencyDash = "dashcommerce" + "-" + plan + "-" + language;
      const topic = isWhitelabel ? agencyTopic : agencyDash;
      if (!this.messageForm.invalid) {
        let title = this.messageForm.value.title;
        let body = this.messageForm.value.body;
        this.firebaseService
          .sendBroadcastMessage(title, body, topic)
          .subscribe(async (result) => {
            if (result["status"] === "OK") {
              this.slideBack();
              this.loadData(null);
            } else {
              const displayMessage = await this.alertCtrl.create({
                message: this.translate.instant("FAILED_MESSAGE"),
                header: "Error sending message",
                cssClass: "secondary",
                buttons: [
                  {
                    text: "Ok",
                    handler: (data) => {},
                  },
                ],
              });
              await displayMessage.present();
            }
            this.loading.dismiss();
          });
      } else {
      }
    } else {
      alert("Fill all topic atributes");
    }
  }

  slideBack() {
    this.messageForm.reset();
    this.slides.lockSwipeToPrev(false);
    this.slides.slidePrev().then(() => {
      this.slides.lockSwipeToNext(true);
      this.slides.lockSwipeToPrev(true);
      this.hideList = false;
    });
  }

  isAdmin() {
    return FirebaseService.isAdmin;
  }

  onDateRangeChanged(event) {
    const formattedBeginDate = event.dateRange.formatted.split(" - ")[0];
    const formattedEndDate = event.dateRange.formatted.split(" - ")[1];
    this.getBestSellers(formattedBeginDate, formattedEndDate);
  }

  protected getBestSellers(beginDate, endDate) {
    const storeName = localStorage.getItem("storeName");
    this.showLoading = true;
    this.woocommerceService
      .getTopSellersReport(beginDate, endDate, storeName)
      .then(
        (topSellersReport) => {
          this.showLoading = false;
          this.bestSellers = topSellersReport;
        }
      );
  }
}

// dashcommerce-free-ptbr
// dashcommerce-free-en
// dashcommerce-free-ge
// dashcommerce-free-es
// dashcommerce-free-esmx
// dashcommerce-free-da
// dashcommerce-free-it

// dashcommerce-premium-ptbr
// dashcommerce-premium-en
// dashcommerce-premium-ge
// dashcommerce-premium-es
// dashcommerce-premium-esmx
// dashcommerce-premium-da
// dashcommerce-premium-it

// syscoin
