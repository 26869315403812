import { TargetServerService } from './../services/target-server.service';
import { NgModule, ChangeDetectorRef } from "@angular/core";
import { BrowserModule } from "@angular/platform-browser";
import { RouteReuseStrategy } from "@angular/router";
import { CommonModule } from "@angular/common";
import { IonicModule, IonicRouteStrategy } from "@ionic/angular";
import { SplashScreen } from "@ionic-native/splash-screen/ngx";
import { StatusBar } from "@ionic-native/status-bar/ngx";
import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";
import { NgxErrorsModule } from "@ultimate/ngxerrors";
import { ChartsModule } from "ng2-charts";
import { ServiceWorkerModule } from "@angular/service-worker";
import { IonicStorageModule } from "@ionic/storage"; // ionic storage module
import { environment } from "../environments/environment";
import { AngularFireModule } from "@angular/fire";
import {
  AngularFirestoreModule,
  FirestoreSettingsToken,
} from "@angular/fire/firestore";
import { AngularFireStorageModule } from "@angular/fire/storage";
import { AngularFireAuthModule } from "@angular/fire/auth";
import { FirebaseX } from "@ionic-native/firebase-x/ngx";
import { Badge } from "@ionic-native/badge/ngx";
import { HttpClientModule, HttpClient } from "@angular/common/http";
import { HTTP } from '@awesome-cordova-plugins/http/ngx';
import { AuthService } from "../services/auth.service";
import { LoadingProvider } from "../providers/loading";
import { GooglePlus } from "@ionic-native/google-plus/ngx";
import { registerLocaleData } from "@angular/common";
import localePt from "@angular/common/locales/pt";
import { PushService } from "../services/push.service";
import { AssignStorePageModule } from "./assign-store/assign-store.module";
import { MessagesModalPageModule } from "./messages-modal/messages-modal.module";
import { CreateUserModalPageModule } from "./create-user-modal/create-user-modal.module";
import { EditProfileModalPageModule } from "./edit-profile-modal/edit-profile-modal.module";
import { Globalization } from "@ionic-native/globalization/ngx";
import { BarcodeScanner } from "@ionic-native/barcode-scanner/ngx";
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { CallNumber } from '@ionic-native/call-number/ngx';
import { StorePageModule } from './store/store.module';
import { LottieModule } from 'ngx-lottie';
import { InAppBrowser } from '@ionic-native/in-app-browser/ngx';
import { Deeplinks } from '@ionic-native/deeplinks/ngx';
import { Device } from '@ionic-native/device/ngx';
import { NotificationFixModalPageModule } from './notification-fix-modal/notification-fix-modal.module';
import { DatePicker } from "@ionic-native/date-picker/ngx";
import player from 'lottie-web';
import { ReportModalPageModule } from "./report-modal/report-modal.module";
import { AssociateStoreModalPageModule } from './associate-store-modal/associate-store-modal.module';
import { ProductImageManagerComponent } from './components/product-image-manager/product-image-manager.component';
import { NewsModalPage } from './news-modal/news-modal.page';
import { FormsModule } from '@angular/forms';
// import { InfoPopoverComponent } from "./components/info-popover/info-popover.component";
import { InAppPurchase2 } from '@ionic-native/in-app-purchase-2/ngx';
import { PremiumPage } from "./premium/premium.page";
import { PremiumPageModule } from './premium/premium.module';
import { VariableProductDetailsComponent } from "./components/variable-product-details/variable-product-details.component";
import { AnalyticsAssociationModalPageModule } from "./analytics-association-modal/analytics-association-modal.module";
import { ReportListModalPageModule } from "./report-list-modal/report-list-modal.module";



// Note we need a separate function as it's required
// by the AOT compiler.
export function playerFactory() {
  return player;
}
registerLocaleData(localePt, "pt");

export function createTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http, "./assets/i18n/", ".json");
}
declare module "@angular/core" {
  interface ModuleWithProviders<T = any> {
    ngModule: Type<T>;
    providers?: Provider[];
  }
}

@NgModule({
  declarations: [AppComponent, NewsModalPage, VariableProductDetailsComponent],
  entryComponents: [NewsModalPage, VariableProductDetailsComponent],
  imports: [
    BrowserModule,
    IonicModule.forRoot({ hardwareBackButton: false }),
    AppRoutingModule,
    IonicStorageModule.forRoot(),
    NgxErrorsModule,
    ChartsModule,
    FormsModule,
    AngularFireModule.initializeApp(environment.firebase), // imports firebase/app
    AngularFirestoreModule, // imports firebase/firestore
    AngularFireAuthModule, // imports firebase/auth
    AngularFireStorageModule, // imports firebase/storage
    HttpClientModule,
    CommonModule,
    LottieModule.forRoot({ player: playerFactory }),
    ServiceWorkerModule.register("ngsw-worker.js", {
      enabled: environment.production,
    }),
    AssociateStoreModalPageModule,
    MessagesModalPageModule,
    StorePageModule,
    CreateUserModalPageModule,
    EditProfileModalPageModule,
    NotificationFixModalPageModule,
    AssignStorePageModule,
    ReportModalPageModule,
    ReportListModalPageModule,
    PremiumPageModule,
    AnalyticsAssociationModalPageModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: createTranslateLoader,
        deps: [HttpClient],
      },
    }),
  ],
  providers: [
    FirebaseX,
    StatusBar,
    LoadingProvider,
    AuthService,
    PushService,
    HTTP,
    SplashScreen,
    GooglePlus,
    CallNumber,
    Globalization,
    Badge,
    BarcodeScanner,
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
    Deeplinks,
    Device,
    InAppBrowser,
    InAppPurchase2,
    DatePicker,
    { provide: FirestoreSettingsToken, useValue: {} },
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
    TargetServerService
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
