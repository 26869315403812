import { TargetServerService } from './../services/target-server.service';
import { Component, NgZone } from "@angular/core";
import { ModalController, Platform } from "@ionic/angular";
import { StatusBar } from "@ionic-native/status-bar/ngx";
import { Router } from "@angular/router";
import { LoadingProvider } from "../providers/loading";
import { AuthService } from "../services/auth.service";
import { TranslateService } from "@ngx-translate/core";
import { NavController } from "@ionic/angular";
import { AngularFireAuth } from "@angular/fire/auth";
import { BrowserModule } from "@angular/platform-browser";
import { Globalization } from "@ionic-native/globalization/ngx";
import { Deeplinks } from "@ionic-native/deeplinks/ngx";
import { Badge } from "@ionic-native/badge/ngx";
import { Device } from "@ionic-native/device/ngx";
import { environment } from "../environments/environment";
import { FirebaseAnalyticsService } from "../services/firebase-analytics.service";
import { Plugins } from "@capacitor/core";
import { WoocommerceService } from "../services/woocommerce.service";
import { EditProfileModalPage } from "./edit-profile-modal/edit-profile-modal.page";
import { FirebaseService } from "../services/firebase.service";
import { Observable } from "rxjs";
const { SplashScreen } = Plugins;
const { Browser } = Plugins;
import { PremiumService } from "../services/premium.service";
import { PremiumPage } from "./premium/premium.page";
import { AssociateStoreModalPage } from "./associate-store-modal/associate-store-modal.page";
import { AnalyticsAssociationModalPage } from "./analytics-association-modal/analytics-association-modal.page";

const donateLink = environment.donateLink;
const instagramLink = environment.instagramLink;
const facebookLink = environment.facebookLink;
const linkedinLink = environment.linkedinLink;
const whatsappLink = environment.whatsappLink;
const whitelabelAgency = environment.whitelabelAgency;

@Component({
  selector: "app-root",
  templateUrl: "app.component.html",
})
export class AppComponent {
  userName: string;
  public static homeUrlStatic = "/dashboard/tabs/tab1";
  public static ordersUrlStatic = "/dashboard/tabs/tab2";
  public static analyticsUrlStatic = "/dashboard/tabs/analytics";
  public static insightsUrlStatic = "/dashboard/tabs/tab4";
  public static overviewUrlStatic = "/dashboard/tabs/tabAdmin";
  public static settingsUrlStatic = "/dashboard/tabs/tab3";
  public static newStoreUrlStatic = "/woocommerce-register";
  public static couponsUrlStatic = "dashboard/tabs/list-cupons";
  public static premiumUrlStatic = "/premium";
  public static productsUrlStatic = "/dashboard/tabs/list-product";
  public static blingUrlStatic = "/dashboard/tabs/panels";
  public static blingOrderUrlStatic = "/dashboard/tabs/bling-list-orders";
  public static tinyRegisterUrlStatic = "/tiny-register";
  public static tinyPanelsUrlStatic = "/dashboard/tabs/tiny-panels";
  public static tinyOrdersUrlStatic = "/dashboard/tabs/tiny-list-orders";


  public homeUrl = AppComponent.homeUrlStatic;
  public ordersUrl = AppComponent.ordersUrlStatic;
  public analyticsUrl = AppComponent.analyticsUrlStatic;
  public insightsUrl = AppComponent.insightsUrlStatic;
  public overviewUrl = AppComponent.overviewUrlStatic;
  public settingsUrl = AppComponent.settingsUrlStatic;
  public newStoreUrl = AppComponent.newStoreUrlStatic;
  public couponsUrl = AppComponent.couponsUrlStatic;
  public premiumUrl = AppComponent.premiumUrlStatic;
  public productsUrl = AppComponent.productsUrlStatic;
  public blingUrl = AppComponent.blingUrlStatic;
  public blingOrdersUrl = AppComponent.blingOrderUrlStatic;
  public tinyRegisterUrl = AppComponent.tinyRegisterUrlStatic;
  public tinyPanelsUrl = AppComponent.tinyPanelsUrlStatic;
  public tinyOrdersUrl = AppComponent.tinyOrdersUrlStatic;



  public static permissionsList = [{ permissionName: "DASHBOARD" },
  { permissionName: "ORDERS" },
  { permissionName: "PRODUCTS" },
  { permissionName: "INSIGHTS" },
  { permissionName: "ANALYTICS" },
  { permissionName: "COUPONS" },
  { permissionName: "OVERVIEW" },
  { permissionName: "BLING" },
  { permissionName: "TINY" },
  { permissionName: "TINY-ORDERS" },
  { permissionName: "BLING-ORDERS" },
  { permissionName: "CLIENTS" }];

  public donateLink = donateLink;
  public instagramLink = instagramLink;
  public facebookLink = facebookLink;
  public linkedinLink = linkedinLink;
  public whatsappLink = whatsappLink;
  public whitelabelAgency = whitelabelAgency;
  public ordersBadge: Number = 0;
  public ls = localStorage;
  public profilePicture: any =
    localStorage.getItem("profilePicture") != null
      ? localStorage.getItem("profilePicture")
      : "";
  public darkMode: boolean;
  public isPremium: boolean = false;
  public userStore: string =
    localStorage.getItem("storeName") != null
      ? localStorage.getItem("storeName")
      : "";
  public storeUrl: string = "";

  constructor(
    private platform: Platform,
    private statusBar: StatusBar,
    public loading: LoadingProvider,
    public afAuth: AngularFireAuth,
    private auth: AuthService,
    private navController: NavController,
    private globalization: Globalization,
    private router: Router,
    private translate: TranslateService,
    private deeplinks: Deeplinks,
    private badge: Badge,
    public premiumService: PremiumService,
    private device: Device,
    private analyticsService: FirebaseAnalyticsService,
    public modalController: ModalController,
    private firebaseService: FirebaseService,
    private woocommerceService: WoocommerceService,
    private ngZone: NgZone,
    private targetServerService: TargetServerService
  ) {
    this.initializeApp();
  }
  private agency: string;

  //   initializeApp() {
  //       // Hide the splash (you should do this on app launch)

  //     this.platform.ready().then(() => {

  //         this.setTheme();
  //         this.darkMode = this.isDarkTheme();
  //         this.badge.clear();
  //       // this.setupDeepLinks()
  //       // this.mixpanel.init('b6e9dfde6a4efb788ddbf22868ee8d2c');
  //       // this.mixpanel.track('App inicializado');
  //       localStorage.setItem('analyticsCheck', 'false');

  //       // this.router.navigateByUrl('login');
  //       // this.statusBar.styleDefault();

  //         if (this.device != null && this.device.platform == 'Android') {
  //             this.statusBar.styleBlackTranslucent();
  //             this.statusBar.backgroundColorByHexString('#33000000');
  //         }
  //         // this.loading.present();
  //         // Verify if user is already logged in
  //         console.log('PRÉ');
  //         if (this.auth.isLoggedIn()) {
  //             this.agency = localStorage.getItem('agency');
  //             const previousAgency = localStorage.getItem('agency');

  //             console.log('TO NO APP COMPONENT');
  //             console.log(localStorage.getItem('userName')  );

  //             console.log(localStorage.getItem('agency')  );

  //             console.log(JSON.parse(localStorage.getItem('user')));
  //             if (previousAgency === 'undefined' || previousAgency === '' || previousAgency === null || previousAgency === undefined ) {

  //                 localStorage.removeItem('storeName');
  //                 localStorage.removeItem('userName');
  //                 localStorage.removeItem('agency');
  //                 localStorage.removeItem('user');
  //                 localStorage.removeItem('newMessages');

  //                 this.goToLogin();
  //             }

  //             this.userName = localStorage.getItem('userName');
  //             if (this.agency === 'undefined' || this.agency === undefined || this.agency === null  ||
  //                this.agency === '' || this.userName === 'undefined' || this.userName === '' || this.userName === null) {

  //               // AGENCY NOT DEFINED, REDIRECT TO /AGENCY
  //               // this.router.navigateByUrl('/agency');
  //               // this.router.initialNavigation();
  //                 this.goToLogin();
  //             } else {
  //                 const userEmail = localStorage.getItem("userEmail");
  //                 if (userEmail != null) {
  //                     this.firebaseService.getUser(userEmail).then((response) => {
  //                         this.profilePicture = response["_document"]["proto"][
  //                             "fields"
  //                             ].hasOwnProperty("profilePicture")
  //                             ? response["_document"]["proto"]["fields"]["profilePicture"][
  //                                 "stringValue"
  //                                 ]
  //                             : "";
  //                         if(this.profilePicture != null){

  //                             localStorage.setItem('profilePicture',this.profilePicture);
  //                         }
  //                     });
  //                 }

  //                 if (this.getStore()) {
  //                     this.woocommerceService.getOrdersBadge().subscribe((data) => {
  //                         this.ordersBadge = data["processing_orders"];
  //                     });
  //                 }
  //                 this.woocommerceService.getOrdersBadge().subscribe((data) => {
  //                     this.ordersBadge = data["processing_orders"];
  //                 });
  //                 this.goToTabsControllerPage();

  //             }

  //         } else {
  //             this.goToLogin();
  //         }

  async initializeApp() {
    // Hide the splash (you should do this on app launch)
    this.getStoresAmount();
    this.platform.ready().then(async () => {
      this.setUpLanguage();
      this.setTheme();
      this.darkMode = this.isDarkTheme();

      if (this.platform.is('capacitor')) {
        this.badge.clear();
      }

      // this.setupDeepLinks()
      // this.mixpanel.init('b6e9dfde6a4efb788ddbf22868ee8d2c');
      // this.mixpanel.track('App inicializado');
      localStorage.setItem("analyticsCheck", "false");

      this.targetServerService.getTargetServer();
      this.targetServerService.getIsInsightsEnabled();

      console.log(`Local agency: ${localStorage.getItem('agency')}, code agency: ${environment.whitelabelAgency}`);
      if(localStorage.getItem('agency') != environment.whitelabelAgency) {
      console.log("Logging out")
      this.logout();
      }

      // this.router.navigateByUrl('login');
      // this.statusBar.styleDefault();

      if (this.platform.is('capacitor') && this.device != null && this.device.platform == "Android") {
        this.statusBar.styleBlackTranslucent();
        this.statusBar.backgroundColorByHexString("#33000000");
      }
      // this.loading.present();
      // Verify if user is already logged in
      // console.log("PRÉ");

      if (this.auth.isLoggedIn()) {

        this.agency = environment.whitelabelAgency
        const previousAgency = environment.whitelabelAgency

        if (
          previousAgency === "undefined" ||
          previousAgency === "" ||
          previousAgency === null ||
          previousAgency === undefined
        ) {
          localStorage.removeItem("storeName");
          localStorage.removeItem("userName");
          localStorage.removeItem("agency");
          localStorage.removeItem("user");
          localStorage.removeItem("newMessages");
          await this.auth.setAuthCredentials();
          this.goToLogin();
        }

        this.userName = JSON.parse(localStorage.getItem("user")).displayName;
        console.log("USERNAME: ", this.userName)
        localStorage.setItem("userName",  this.userName);
        if (
          this.agency === "undefined" ||
          this.agency === undefined ||
          this.agency === null ||
          this.agency === "" ||
          this.userName === "undefined" ||
          this.userName === "" ||
          this.userName === null
        ) {
          // AGENCY NOT DEFINED, REDIRECT TO /AGENCY
          // this.router.navigateByUrl('/agency');
          // this.router.initialNavigation();
          console.log("2 - VOU PRO LOGIN");

          this.goToLogin();
        } else {
          this.auth.setAuthCredentials().then(() => {
            this.getStoreUrl();
            this.goToTabsControllerPage();
          });

        }
      } else {
        console.log("3 - VOU PRO LOGIN");

        this.goToLogin();
      }

      // this.afAuth.authState.subscribe(res => {
      //     console.log("LULU");
      //
      //     console.log(this.auth.afAuth.auth.currentUser);
      //
      //     if (res) {
      //         this.goToTabsControllerPage();
      //     }
      //     else {
      //         this.goToLogin();
      //     }
      // }, error => {
      //     console.log(error);
      //     this.goToLogin();
      // });
    });
  }

  setBling() {
    if (localStorage.getItem('storeType') == 'bling') {
      return true;
    }
    else {
      return false;
    }
  }

  setTiny() {
    if (localStorage.getItem('storeType') == 'tiny') {
      return true;
    }
    else {
      return false;
    }
  }

  ionViewDidEnter() {
    this.darkMode = this.isDarkTheme();
  }
  setupDeepLinks() {
    this.deeplinks
      .route({
        "/:id": "",
      })
      .subscribe((match) => {
        const internalPath = `/${match.$route}/${match.$args["id"]}`;
        alert(
          "succesfully matched route" + " Internal path -> " + internalPath
        );
      });
  }

  goToLogin() {
    // if (this.loading.isLoading) { this.loading.dismiss(); }
    
    console.log("goToLogin - CHEGUEI AQUI NO LOGIN");
    this.router.navigateByUrl("/login");
    this.router.initialNavigation();

    this.hideSplashScreen();
    this.premiumService.setUpStore();
  }

  hideSplashScreen() {
    setTimeout(() => {
      SplashScreen.hide();
    }, 2000);
    // this.setUpLanguage();
    // SplashScreen.hide();

    // this.splashScreen.hide();
  }

  goToTabsControllerPage() {
    // if (this.loading.isLoading) { this.loading.dismiss(); }
    if (localStorage.getItem("prm") != undefined) {
      FirebaseService.permission = window.atob(localStorage.getItem("prm"));

      this.router.navigateByUrl(AppComponent.getSelectedStoreHomePageUrl());

    } else {
      this.router.navigateByUrl(this.homeUrl);
    }

    this.router.initialNavigation();

    this.hideSplashScreen();
    this.premiumService.setUpStore();
    let agency = localStorage.getItem("agency");
    this.firebaseService.getUserStores(agency);
  }

  isAdmin() {
    if (localStorage.getItem("isAdmin") == "true") {
      return true;
    } else {
      return false;
    }
  }

  isAgency() {
    if (localStorage.getItem("isAgency") == "true") {
      return true;
    } else {
      return false;
    }
  }

  getStore() {
    return localStorage.getItem("storeName") ? true : false;
  }

  checkPermission(permissionToCheck) {
    return this.firebaseService.checkPermission(permissionToCheck);
  }

  static checkPermissionStatic(permissionToCheck) {
    return FirebaseService.checkPermissionStatic(permissionToCheck);
  }

  getStoreName() {
    return localStorage.getItem("storeName");
  }

  async openPage(page) {
    if (this.platform.is("capacitor")) {
      await Browser.open({
        presentationStyle: "popover",
        url: page,
      });
    } else {
      window.open(page, "_blank", "location=yes");
    }
  }

  //   async editProfile() {
  //     const modal = await this.modalController.create({
  //       component: EditProfileModalPage,
  //       backdropDismiss: false,
  //     });

  //     modal.onDidDismiss().then((returnData) => {
  //       if (returnData.data.changePicture) {
  //         this.profilePicture = returnData.data.imageUrl;
  //       }
  //     });

  //     return await modal.present();
  //   }

  setUpLanguage() {
    this.translate.use("en");

    if (this.platform.is('capacitor')) {
      this.globalization
      .getPreferredLanguage()
      .then((res) => {
        // MOBILE
        if (
          localStorage.getItem("language") != "" &&
          localStorage.getItem("language") != undefined
        ) {
          console.log(
            "Linguagem setada em localStorage: ",
            localStorage.getItem("language")
          );
          this.translate.setDefaultLang(localStorage.getItem("language"));
          this.translate.use(localStorage.getItem("language"));
        } else {
          if (
            res.value == "pt-BR" ||
            res.value == "PT-BR" ||
            res.value == "pt-br" ||
            res.value == "ptBR" ||
            res.value == "pt" ||
            res.value == "PTbr" ||
            res.value == "br" ||
            res.value == "BR" ||
            res.value == "PT"
          ) {
            localStorage.setItem("language", "ptBR");
            this.translate.setDefaultLang("ptBR");
            this.translate.use("ptBR");
          } else {
            this.translate.setDefaultLang("en");
            this.translate.use("en");
          }
        }
      })
      .catch((err) => {
        console.log(err);
      });
    }
    else {
      // WEB
      if (
        localStorage.getItem("language") != "" &&
        localStorage.getItem("language") != undefined
      ) {
        // console.log(
        //   "Linguagem setada em localStorage: ",
        //   localStorage.getItem("language")
        // );
        this.translate.setDefaultLang(localStorage.getItem("language"));
        this.translate.use(localStorage.getItem("language"));
      } else {
        console.log("Linguagem do nav: ", this.translate.getBrowserLang());
        if (
          this.translate.getBrowserLang() == "pt-BR" ||
          this.translate.getBrowserLang() == "pt"
        ) {
          localStorage.setItem("language", "ptBR");
          this.translate.setDefaultLang("ptBR");
          this.translate.use("ptBR");
        } else {
          localStorage.setItem("language", "en");
          this.translate.setDefaultLang("en");
          this.translate.use("en");
        }
      }
    }
  }

  setTheme() {
    // const systemDark = window.matchMedia('(prefers-color-scheme: dark)');
    const theme = localStorage.getItem("theme");
    const isDark = this.isDarkTheme();
    if (theme == null && !isDark) {
      document.body.classList.toggle("dark");
    } else if (theme === "light" && isDark) {
      document.body.classList.toggle("dark");
      // tslint:disable-next-line:triple-equals
    } else if (theme === "dark" && !isDark) {
      document.body.classList.toggle("dark");
    }
  }

  changeTheme(event) {
    // const systemDark = window.matchMedia('(prefers-color-scheme: dark)');
    document.body.classList.toggle("dark", event.detail.checked);
    if (this.isDarkTheme()) {
      localStorage.setItem("theme", "dark");
      this.darkMode = true;
    } else {
      localStorage.setItem("theme", "light");
      this.darkMode = false;
    }
  }

  isDarkTheme() {
    return document.getElementsByClassName("dark").length > 0;
  }

  showRegisterButton() {
    // let storeList = JSON.parse(localStorage.getItem('storeList'));
    // if(storeList === undefined) storeList = [];
    // let length = storeList.length;
    //return length;
    return FirebaseService.storesAmount !== undefined;
  }

  noStores() {
    return FirebaseService.storesAmount === 0;
  }

  async registerStore() {
    if (this.showRegisterButton()) {
      console.log('STORES LOADED');
      console.log('NO STORES', this.noStores());
      if (this.noStores()) {
        console.log('No stores registred');
        this.router.navigateByUrl("/choose-plataform");
      }
      else {
        if (!this.premiumService.isUserPremium(this.modalController, PremiumPage)) {
          console.log('User not premium, premium modal opened');
        }
        else {
          if (this.premiumService.allowRegistration(FirebaseService.storesAmount)) {
            this.router.navigateByUrl("/choose-plataform");
          }
          else {
            this.premiumService.openPremiumPage(this.modalController, PremiumPage);
          }
        }
      }
    }
  }

  getPremiumSignatureName() {
    return this.premiumService.getPremiumName();
  }

  getStoresAmount() {
    let storeList = JSON.parse(localStorage.getItem('storeList'));
    if (storeList === undefined) storeList = [];
    let length = storeList ? storeList.length : '0';
    FirebaseService.storesAmount = length;
    return this.showRegisterButton() ? FirebaseService.storesAmount : length;
  }

  getSignatureLimit() {
    if (this.getPremiumSignatureName() === '') {
      return 0;
    }
    else {
      return PremiumService[`${this.getPremiumSignatureName().toLowerCase()}PlanAmountOfStores`];
    }
  }

  async editProfile() {
    const modal = await this.modalController.create({
      component: EditProfileModalPage,
      backdropDismiss: false,
    });

    modal.onDidDismiss().then((returnData) => {
      if (returnData.data.changePicture) {
        this.profilePicture = returnData.data.imageUrl;
      }
    });

    return await modal.present();
  }

  getAssociatedStores() {
    let totalStores = 20;
    let usedStores = 10;
    return `${usedStores}/${totalStores}`;
  }

  getStoreUrl() {
    this.storeUrl = "https://dashcommerce.app/";
  }

  isUserPremium() {
    return this.premiumService.isUserPremium(this.modalController, PremiumPage);
  }

  showPremiumCrown() {
    return PremiumService.isPremium;
  }

  async openModalAssociateAnalytcis() {
    const modal = await this.modalController.create({
      component: AnalyticsAssociationModalPage,
      componentProps: {

      },
    });

    modal.onDidDismiss().then((result) => {
      console.log("Result");
    });

    return await modal.present();
  }

  async openUsersModal() {
    if (this.isUserPremium()) {
      let storeName = localStorage.getItem("storeName");
      const modal = await this.modalController.create({
        component: AssociateStoreModalPage,
        componentProps: {
          storeName: storeName,
          isList: true,
        },
      });

      modal.onDidDismiss().then((dataReturned) => {
        if (dataReturned !== null) {
          console.log("Modal Fechado");
        }
      });

      return await modal.present();
    }
  }


  async associateStore() {
    if (this.isUserPremium()) {
      let storeName = localStorage.getItem("storeName");
      const modal = await this.modalController.create({
        component: AssociateStoreModalPage,
        componentProps: {
          storeName: storeName,
          isList: false,
        },
      });

      modal.onDidDismiss().then((dataReturned) => {
        if (dataReturned !== null) {
          console.log("Modal Fechado");
        }
      });
      return await modal.present();
    }
  }


  async openPremiumPage() {
    const modal = await this.modalController.create({
      component: PremiumPage,
    });
    return await modal.present();
  }

  getUserName() {
    return localStorage.getItem('userName');
  }

  static getSelectedStoreHomePageUrl() {
    let storeList = localStorage.getItem("storeList");

    let convertString = JSON.parse(storeList);

    let found = convertString?.find(element => element.storeName == localStorage.getItem("storeName"));
    if (found == undefined || !found.type) {
      return AppComponent.homeUrlStatic;
    }
    else if (found.type == "bling" || found.type == "Bling") {
      return AppComponent.blingUrlStatic;
    }
    else if (found.type == "tiny" || found.type == "Tiny") {
      return AppComponent.tinyPanelsUrlStatic;
    }
    else {
      return AppComponent.homeUrlStatic;
    }

  }

  public static getPageUrlByPermission(permissionName) {
    if (permissionName == "DASHBOARD" || "NONE") return AppComponent.getSelectedStoreHomePageUrl();
    else if (permissionName == "ORDERS") return AppComponent.ordersUrlStatic;
    else if (permissionName == "ANALYTICS") return AppComponent.analyticsUrlStatic;
    else if (permissionName == "INSIGHTS") return AppComponent.insightsUrlStatic;
    else if (permissionName == "OVERVIEW") return AppComponent.overviewUrlStatic;
    else if (permissionName == "COUPONS") return AppComponent.couponsUrlStatic;
    else if (permissionName == "BLING") return AppComponent.blingUrlStatic;
    else if (permissionName == "TINY") return AppComponent.tinyPanelsUrlStatic;
    else if (permissionName == "TINY-ORDERS") return AppComponent.tinyOrdersUrlStatic;
    else if (permissionName == "BLING-ORDERS") return AppComponent.blingOrderUrlStatic;
    else if (permissionName == "PRODUCTS") return AppComponent.productsUrlStatic;
    else return AppComponent.settingsUrlStatic;
  }

  static routingPermissionCheck(router, permissionToCheck) {

    //console.log("Estou checando a rota" + permissionToCheck)
    //Se o usuário possui permissão pra aquela página, tá tudo ok, retorna.
    if (AppComponent.checkPermissionStatic(permissionToCheck)) {
      //.log("Usuário tem permissão a essa rota: " + permissionToCheck)
      return true;
    }
    //Caso ele não tenha, redireciona automaticamente pra página 
    else {
      //console.log("Usuário NÃO tem permissão a essa rota: " + permissionToCheck + " REDIRECIONANDO...");
      let firstPermission = FirebaseService.firstPermission();
      //console.log("A primeira permissao é: " + firstPermission)
      let route = AppComponent.getPageUrlByPermission(firstPermission);
      console.log("A rota selecionada é: " + route, router);
      router.navigateByUrl(route);
      return false;
    }
  }

  async logout() {
    localStorage.removeItem("analyticsCheck");
    this.loading.present();

    this.auth.doLogout().then(
      () => {
        this.navController.navigateRoot("/login").then(() => {
          this.loading.dismiss();
        });
        this.premiumService.guestMode();
      },
      (err) => {
        this.loading.dismiss();
        console.log(err);
      }
    );
  }
}
