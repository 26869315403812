import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from "@angular/core";
import { IonSlides } from "@ionic/angular";
import { WoocommerceService } from "../../../services/woocommerce.service";
import { TranslateService } from "@ngx-translate/core";
import { LottieModule } from "ngx-lottie";
import player from "lottie-web";

// Note we need a separate function as it's required
// by the AOT compiler.
export function playerFactory() {
  return player;
}

@Component({
  selector: "app-itens-order-card",
  templateUrl: "./itens-order-card.component.html",
  styleUrls: ["./itens-order-card.component.scss"],
})
export class ItensOrderCardComponent implements OnInit {
  @Input() orderItens: any;
  @ViewChild("mySlider") slides: IonSlides;
  private productsPhotos: Array<any> = [];
  private lottieConfig: any;
  public isPictureAvailable: boolean = false;

  slideOpts = {
    initialSlide: 0,
    speed: 400,
  };

  constructor(
    private woocommerceService: WoocommerceService,
    private translate: TranslateService
  ) {
    LottieModule.forRoot({ player: playerFactory });
    this.lottieConfig = {
      path: "../../assets/animations/product.json",
      autoplay: true,
      loop: true,
    };
  }

  ngOnInit() {
    this.orderItens.forEach((item) => {
      if (item.product_id != 0) {
        this.woocommerceService
          .getProductPhoto(item.product_id)
          .then((response) => {
            if (response["product"] == "product without picture") {
              this.isPictureAvailable = false;
              item.product_photo = "teste" 
            } else {
              item.product_photo = response;
              this.isPictureAvailable = true;
            }
          });
      } else {
        this.woocommerceService.getProductPhoto(item.id).then((response) => {
          if (response["product"] == "product without picture") {
            this.isPictureAvailable = false;
            item.product_photo = "teste"
          } else {
            item.product_photo = response;
            this.isPictureAvailable = true;
          }
        });
      }
    });
  }

  protected getUserCurrency() {
    if (localStorage.getItem("currency") === null) return "$";
    else return localStorage.getItem("currency");
  }

  protected getUserCurrencyLocale() {
    return this.translate.instant("LOCALE_UNICODE");
  }

  onImgLoad(event) {}
  onThumbLoad() {}

  getProductImg(productId) {}
}
