// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
    production: false,
    firebase: {
        apiKey: "AIzaSyDUm9TzcVSo-4j4n7RzLREntc0FwaVA-OU",
        authDomain: "syscoin-dashboard-app.firebaseapp.com",
        databaseURL: "https://syscoin-dashboard-app.firebaseio.com",
        projectId: "syscoin-dashboard-app",
        storageBucket: "syscoin-dashboard-app.appspot.com",
        messagingSenderId: "246555591065",
        appId: "1:246555591065:web:0075b8e8a6c2741d"
    },
    endpoint: "https://us-central1-syscoin-dashboard-app.cloudfunctions.net/",
    whitelabelAgency: 'staker',
    backend: "https://backend.dashcommerce.cloud/",
    data: "https://data.dashcommerce.cloud/",
    phrase: "P4R4NG4R1",
    isWhitelabel: true,
    loginLogo: 'assets/img/logo/staker-light.png',
    loginLogoLink: 'https://staker.com.br/',
    loginLogoLight: 'assets/img/logo/staker-light.png',
    tab3Logo : 'assets/img/logo/staker-light.png',
    tab1Logo : 'assets/img/logo/staker-light.png',
    tab1LogoLight : 'assets/img/logo/staker-light.png',
    tabAdminLogo : 'assets/img/logo/staker-light.png',
    agencyLogo: 'assets/img/logo/staker-light.png',
    registerStoreLogo : 'assets/img/logo/staker-light.png',
    tab1NoStoreLink: 'https://api.whatsapp.com/send?phone=5546999894240&text=Ol%C3%A1,%20quero%20conversar%20com%20voc%C3%AA%20para%20vincular%20meu%20ecommerce.',
    supportLink1: 'https://api.whatsapp.com/send?phone=5546999894240&text=Ol%C3%A1,%20preciso%20de%20ajuda.',
    supportLink2: 'https://api.whatsapp.com/send?phone=5546999894240&text=Ol%C3%A1',
    supportLink3: 'https://api.whatsapp.com/send?phone=5546999894240&text=Ol%C3%A1',
    supportLink4: 'https://api.whatsapp.com/send?phone=5546999894240&text=Ol%C3%A1',
    supportEmailLink: 'https://api.whatsapp.com/send?phone=5546999894240&text=Ol%C3%A1',
    deleteAccountLink: "mailto:contato@staker.com.br",
    woocommerceNotificationPluginUrl: 'https://dashcommerce.app/',
    privacyPolicyLink: 'https://staker.com.br/',
    registerLogo: 'assets/img/logo/staker-light.png',
    woocommerceLogo: 'assets/img/woocommerce.svg',
    blingLogo: 'assets/img/logo/logo-bling.png',
    tinyLogo: "./assets/img/logo/tinyLogo.svg",
    analyticsLogo: 'assets/img/logo/logo_analytics.png',
    googleOauth2Link: 'https://accounts.google.com/o/oauth2/v2/auth?access_type=offline&scope=https%3A%2F%2Fwww.googleapis.com%2Fauth%2Fanalytics.readonly&prompt=consent&response_type=code&client_id=490354299771-77p98libmq46376p7h5rd0blct1q8iav.apps.googleusercontent.com&redirect_uri=https%3A%2F%2Fus-central1-syscoin-dashboard-app.cloudfunctions.net%2FsaveAnalyticsToken',
    donateLink: 'https://www.paypal.com/cgi-bin/webscr?cmd=_s-xclick&hosted_button_id=6DENNBMY8QPRS&source=url',
    instagramLink: 'https://www.instagram.com/staker.br/',
    facebookLink: 'https://www.instagram.com/staker.br/',
    linkedinLink: '',
    whatsappLink: 'https://api.whatsapp.com/send?phone=5546999894240&text=Oi, necessito de suporte no app da Staker',
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import shoulld be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.

//TODO
/*
- Trocar o nome do app na Tab1.page.html
- Trocar a página de suporte na Tab2.page.html
- Trocar a storeList
- Trocar as logos (syscoin-logo.svg para fundo escuro)
- Trocar o favicon na pasta assets/icon
- Trocar a cor primária
- Criar no banco do firebase do dashcommerce uma coleção chamada xxxUserList.
- Criar no banco do firebase do dashcommerce uma coleção chamada xxxStoreList.
- Dar ctrl + shift + f no projeto e procurar a string "syscoinUserList/" e adicionar o nome da loja.
- Dar ctrl + shift + f no projeto e procurar a string "syscoinStoreList/" e adicionar o nome da loja.
- No firebase adicionar o um app específico pro ios com o ID do pacote do app whitelabel. (Necessário pro login com o google funcionar)
- Gerar os certificados na página de developer da Apple pra notificação do aplicativo (https://www.freecodecamp.org/news/how-to-get-push-notifications-working-with-ionic-4-and-firebase-ad87cc92394e/)
*/


