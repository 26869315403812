import { Component, Input, OnInit } from "@angular/core";
import { ModalController } from "@ionic/angular";

@Component({
  selector: "app-variable-product-details",
  templateUrl: "./variable-product-details.component.html",
  styleUrls: ["./variable-product-details.component.scss"],
})
export class VariableProductDetailsComponent implements OnInit {
  @Input() variation;
  @Input() index;

  constructor(private modalController: ModalController) {}

  ngOnInit() {}

  dismissModal() {
    this.modalController.dismiss();
  }

  saveModal() {
    if (this.variation.stock_quantity > 0) {
      this.variation.manage_stock = true;
    }
    this.modalController.dismiss({
      variation: this.variation,
      index: this.index,
    });
  }
}
