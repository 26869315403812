import { environment } from '../environments/environment';
import { HttpService } from './http.service';
import { Injectable } from '@angular/core';
export type serverIdentifier = 'BACKEND' | 'CLOUD_FUNCTIONS';

@Injectable({
  providedIn: 'root'
})
export class TargetServerService {
  constructor(
    private httpService: HttpService
  ) {}

  // MUDAR PARA TRUE PARA IGNORAR TARGET SERVER E PRIORIZAR BACKEND NOVO
  private overrideTargetServerConfiguration = false;
  // MUDAR PARA TRUE PARA IGNORAR DATA AVAIL. E USAR DATA MESMO ASSIM
  private overrideDataAvailConfiguration = false;

  private checkEndpoint = 'getTargetServer';

  public backendURL = environment.backend;
  public cloudfnURL = environment.endpoint;

  public getPrioritizeBackend = () => this.httpService.prioritizeBackend;
  public getInsightsAreAvailable = () => this.httpService.insightsAreAvailable;

  async getTargetServer(): Promise<void> {
    if (this.overrideTargetServerConfiguration) {
      this.httpService.prioritizeBackend = true;
      return;
    }
    
    // console.log('Checking configured backend priority...');

    return await this.httpService.get(this.checkEndpoint).toPromise()
      .then((response) => {
        // console.log("Target-server endpoint responded: ", response);

        if (!response['target']) {
          this.httpService.prioritizeBackend = false;
          throw new Error('Invalid target-server endpoint response');
        }

        if (response['target'] == 'CLOUD_FUNCTIONS')
          this.httpService.prioritizeBackend = false;
        else
          this.httpService.prioritizeBackend = true;

        console.log(`Prioritize backend: ${this.getPrioritizeBackend()} (cloudfn: ${environment.endpoint}, backend: ${environment.backend})`);

        return;
      })
      .catch((e) => {
        console.log("Target-server endpoint did not respond or sent invalid response");
        console.log(e);

        this.httpService.prioritizeBackend = false;

        return;
      });
  };

  async getIsInsightsEnabled(): Promise<void> {
    this.httpService.insightsAreAvailable = true;
    return;

    // if (this.overrideDataAvailConfiguration) {
    //   this.httpService.insightsAreAvailable = true;
    //   return;
    // }
    
    // console.log('Checking configured data availability...');

    // return await this.httpService.get('isInsightsEnabled').toPromise()
    //   .then((response) => {
    //     console.log("Data avail. endpoint responded: ", response);

    //     if (!response['target']) {
    //       this.httpService.insightsAreAvailable = false;
    //       throw new Error('Invalid target-server endpoint response');
    //     }

    //     if (response['target'] == 'disabled')
    //       this.httpService.insightsAreAvailable = false;
    //     else
    //       this.httpService.insightsAreAvailable = true;

    //     console.log('Data insights available: ' + this.getInsightsAreAvailable());

    //     return;
    //   })
    //   .catch((e) => {
    //     console.log("Data avail. endpoint did not respond or sent invalid response");
    //     console.log(e);

    //     this.httpService.insightsAreAvailable = false;

    //     return;
    //   });
  };
}
