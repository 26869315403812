import { Component, Input, OnInit, ViewChild } from "@angular/core";
import { IonSlides, ModalController } from "@ionic/angular";
import { FirebaseService } from "../../services/firebase.service";
import { LoadingProvider } from "src/providers/loading";
import { Plugins } from "@capacitor/core";

const { Browser } = Plugins;


@Component({
  selector: "app-news-modal",
  templateUrl: "./news-modal.page.html",
  styleUrls: ["./news-modal.page.scss"],
})
export class NewsModalPage implements OnInit {
  @ViewChild("mySlider") slides: IonSlides;
  @Input() isAdmin:boolean; 

  public news: Array<any> = [];
  slideOpts = {
    initialSlide: 0,
    speed: 400,
  };

  constructor(
    private firebaseService: FirebaseService,
    private modalController: ModalController,
    private loading:LoadingProvider
  ) {}

  async ngOnInit() {
    this.loading.present();
    if(this.isAdmin){
      this.firebaseService.getAdminNews().then((response: Array<any>) => {
        response.forEach((element) => {
          this.news.push(element);
        });
        this.loading.dismiss();
      });
    }else{
      this.firebaseService.getNews().then((response: Array<any>) => {
        response.forEach((element) => {
          this.news.push(element);
        });
        this.loading.dismiss();
      });
    }
    await this.firebaseService.setUserNewsTimestamp();
  }

  async openImageLink(selectedNews){
    
    if(selectedNews.imageLink == undefined || selectedNews.imageLink == ""){
      return
    }
    if(selectedNews.imageLink != '' ){
      await Browser.open({
        presentationStyle:'popover',
        url:selectedNews.imageLink
      })
    }
  }

  async openLink(selectedNews){
    
    if(selectedNews.link == undefined || selectedNews.link == ""){
      return
    }
    if(selectedNews.link != '' ){
      await Browser.open({
        presentationStyle:'popover',
        url:selectedNews.link
      })
    }
  }

  closeModal() {
    this.modalController.dismiss();
  }
}
