import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { AngularFireAuth } from "@angular/fire/auth";
import { AuthService } from "./auth.service";
import { HttpService } from "./http.service";
import { Storage } from "@ionic/storage";
import * as CryptoJS from "crypto-js";
import { environment } from "./../environments/environment";
import { FirebaseService } from "./firebase.service";

const endpoint = environment.endpoint;


@Injectable({
  providedIn: 'root'
})
export class TinyService {

  constructor(
    private httpClient: HttpClient,
    public afAuth: AngularFireAuth,
    private authService: AuthService,
    private httpService: HttpService,
    private storage: Storage,
    private firebaseServices: FirebaseService
  ) { }

  tinyRegister(blingApi: string, storeName: string, catergory: string) {
    return new Promise((res, rej) => {

      const userEmail = localStorage.getItem('userEmail');
      const agency = localStorage.getItem("agency")
      const blingAPikey = blingApi
      const blingStoreName = storeName
      const blingCatergory = catergory

      this.httpService.requestBackendOrCloud(
        { method: 'POST', endpoint: `tiny/register?agency=${agency}&userEmail=${userEmail}&tinyAPikey=${blingAPikey}&tinyStoreName=${blingStoreName}&tinyCatergory=${blingCatergory}` },
        { method: 'POST', endpoint: `tinyFunctions-tinyRegister?agency=${agency}&userEmail=${userEmail}&tinyAPikey=${blingAPikey}&tinyStoreName=${blingStoreName}&tinyCatergory=${blingCatergory}` }
      )
      .toPromise()
      .then(response => {
        console.log(response)
        res(response)
      }).catch(error => {
        rej(error.toString());
      })
    })
  };

  checkIfStoreExist(apiKey: string) {
    return new Promise((res, rej) => {

      this.httpService.requestBackendOrCloud(
        {method: 'GET', endpoint: `tiny/store-info?token=${apiKey}`},
        {method: 'GET', endpoint: `tinyFunctions-getInfoABoutStore?token=${apiKey}`}
        )
      .toPromise()
      .then(response => {
        console.log(response)
        res(response)
      }).catch(error => {
        rej(error.toString());
      })
    });
  }

  getLastOrdersWithPeriod(agency:string, storeName:string, startDate:string, endDate:string, idSituation?:string){
    return new Promise((res, rej) => {

      this.httpService.requestBackendOrCloud(
        {method: 'GET', endpoint: `tiny/last-orders?agency=${agency}&storeName=${storeName}&startDate=${startDate}&endDate=${endDate}&idSituation=${idSituation}`},
        {method: 'GET', endpoint: `tinyFunctions-getLastOrders?agency=${agency}&storeName=${storeName}&startDate=${startDate}&endDate=${endDate}&idSituation=${idSituation}`}
      )
      .toPromise()
      .then(response => {
        res(response)
      }).catch(error => {
        rej(error.toString());
      })
    });
  }

  getLastOrdersWithPage(agency:string, storeName:string, page:number, idSituation?:string ){
    return new Promise((res, rej) => {

      this.httpService.requestBackendOrCloud(
        { method: 'GET', endpoint: `tiny/last-orders-with-page?agency=${agency}&storeName=${storeName}&idSituation=${idSituation}&page=${page}` },
        { method: 'GET', endpoint: `tinyFunctions-getLastOrdersWithPage?agency=${agency}&storeName=${storeName}&idSituation=${idSituation}&page=${page}` }
      )
      .toPromise()
      .then(response => {
        res(response)
      }).catch(error => {
        rej(error.toString());
      })
    });
  }

  getOrder(agency:string, storeName:string, orderId?:string){
    return new Promise((res, rej) => {

      this.httpService.requestBackendOrCloud(
        { method: 'GET', endpoint: `tiny/order?agency=${agency}&storeName=${storeName}&orderId=${orderId}` },
        { method: 'GET', endpoint: `tinyFunctions-getMyOrder?agency=${agency}&storeName=${storeName}&orderId=${orderId}` }
      )
      .toPromise()
      .then(response => {
        res(response)
      }).catch(error => {
        rej(error.toString());
      })
    });
  }

  getLastNFC(agency:string, storeName:string, startDate:string, endDate:string, idSituation?: string, typeNote?: string){
    return new Promise((res, rej) => {

      this.httpService.requestBackendOrCloud(
        { method: 'GET', endpoint: `tiny/last-nf?agency=${agency}&storeName=${storeName}&startDate=${startDate}&endDate=${endDate}&idSituation=${idSituation}&typeNote=${typeNote}` },
        { method: 'GET', endpoint: `tinyFunctions-getLastNotaFiscal?agency=${agency}&storeName=${storeName}&startDate=${startDate}&endDate=${endDate}&idSituation=${idSituation}&typeNote=${typeNote}` }
      )
      .toPromise()
      .then(response => {
        res(response)
      }).catch(error => {
        rej(error.toString());
      })
    });
  }

  updateOrderStatus(agency:string, storeName:string, idSituation: string, idOrder: string){
    return new Promise((res, rej) => {

      this.httpService.requestBackendOrCloud(
        { method: 'GET', endpoint: `tiny/update-order-status?agency=${agency}&storeName=${storeName}&ormato=json&id=${idOrder}&situacao=${idSituation}` },
        { method: 'GET', endpoint: `tinyFunctions-updateOrderStatus?agency=${agency}&storeName=${storeName}&ormato=json&id=${idOrder}&situacao=${idSituation}` }
      )
      .toPromise()
      .then(response => {
        res(response)
      }).catch(error => {
        rej(error.toString());
      })
    });
  }

  checkName(storeName: string) {
    return new Promise((res, rej) => {
      const agency = localStorage.getItem("agency")
      const tinyStoreName = storeName
  
      this.httpService.requestBackendOrCloud(
        {method: 'GET', endpoint: `tiny/check-name?agency=${agency}&tinyStoreName=${tinyStoreName}`},
        {method: 'GET', endpoint: `tinyFunctions-tinyCheckName?agency=${agency}&tinyStoreName=${tinyStoreName}`}
      )
      .toPromise()
      .then(response => {
        console.log(response.status, "olhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhHHH")
        res(response)
      }).catch(error => {
        rej(error.toString());
      })
    });
  }
}
