import { Component, OnInit, ViewChild, ElementRef, Output, EventEmitter, Input } from '@angular/core';

@Component({
  selector: 'app-image-picker',
  templateUrl: './image-picker.component.html',
  styleUrls: ['./image-picker.component.scss']
})
export class ImagePickerComponent implements OnInit {
  selectedImage: string;
  @ViewChild('filePicker') filePickerRef: ElementRef<HTMLInputElement>;
  @Output() imagePick = new EventEmitter<File>();
   
  constructor() { }

  ngOnInit() {


  }
  onPickImage() {
    this.filePickerRef.nativeElement.click();

  }

  onFileChosen(event:Event){
    const pickedFile = (event.target as HTMLInputElement).files[0];
    if(!pickedFile){
      return
    }
    const fileReader = new FileReader();
    fileReader.onload = () =>{
      
      const dataUrl = fileReader.result.toString();
      this.selectedImage = dataUrl;
      this.imagePick.emit(pickedFile);
    };
    fileReader.readAsDataURL(pickedFile);
  }

  cancelPicture(event:Event){
    this.selectedImage = '';
  }

}