import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { AngularFireAuth } from "@angular/fire/auth";
import { AuthService } from "./auth.service";
import { HttpService } from "./http.service";
import { Storage } from "@ionic/storage";
import * as CryptoJS from "crypto-js";
import { environment } from "./../environments/environment";
import { FirebaseService } from "./firebase.service";


@Injectable({
  providedIn: "root",
})
export class WoocommerceService {
  constructor(
    private httpClient: HttpClient,
    public afAuth: AngularFireAuth,
    private authService: AuthService,
    private httpService: HttpService,
    private storage: Storage,
    private firestoreService: FirebaseService,
  ) {}

  isValidCurrentUser(currentUser) {
    return (
      currentUser != undefined &&
      currentUser != null &&
      currentUser != "undefined"
    );
  }

  isValidToken(token) {
    return (
      token != "" && token != undefined && token != null && token != "undefined"
    );
  }

  isLoggedIn(): boolean {
    const user = JSON.parse(localStorage.getItem("user"));
    return user !== null;
  }

  async getOrderComparator(
    numberOfOrders: number = 10,
    //onlyCompleted = false,
    //storeName: String,
    before,
    after,
    offset: number = 0
  ) {
    return new Promise(async (res, rej) => {
      const agency = localStorage.getItem("agency");
      const storeName = localStorage.getItem("storeName");
      const directRequest = localStorage.getItem("storeDirectRequest") == "true";
      const woocommerceEndpoint = `orders/?per_page=${numberOfOrders}&offset=${offset}&status=any&before=${before}&after=${after}`;

      const backendEndpoint = `woocommerce/orders?num=${numberOfOrders}&offset=${offset}&status=any&storeName=${storeName}&agency=${agency}&after=${after}&before=${before}`;
      const firebaseEndpoint = `woocommerceOrders?num=${numberOfOrders}&offset=${offset}&status=any&storeName=${storeName}&agency=${agency}&after=${after}&before=${before}`;

      const cloudfnRequestEndpoint = directRequest ? await this.insertWcCredentials(woocommerceEndpoint) : firebaseEndpoint;
      const backendRequestEndpoint = directRequest ? await this.insertWcCredentials(woocommerceEndpoint) : backendEndpoint;

      return this.httpService.getFromBackendOrCloud(
        { endpoint: backendRequestEndpoint },
        { endpoint: cloudfnRequestEndpoint }
      )
        .toPromise()
        .then((response) => {
          res(response);
        })
        .catch((error) => {
          rej(error.toString());
        });
    });
  }

  async getAllLastOrders(
    numberOfOrders: number = 10,
    onlyCompleted = false,
    status: string,
    //status2: string,
    //storeName: String,
    offset: number = 0
  ) {
    return new Promise(async (res, rej) => {
      const storeName = localStorage.getItem("storeName");
      const agency = localStorage.getItem("agency");
      const directRequest = localStorage.getItem("storeDirectRequest") == "true";
      const woocommerceEndpoint = `orders/?per_page=${numberOfOrders}&offset=${offset}&status=${status}`;

      const backendEndpoint = `woocommerce/orders?storeName=${storeName}&num=${numberOfOrders}&offset=${offset}&agency=${agency}&status=${status}`;
      const firebaseEndpoint = `woocommerceOrders?storeName=${storeName}&num=${numberOfOrders}&offset=${offset}&agency=${agency}&status=${status}`;

      const backendRequestEndpoint = directRequest ? await this.insertWcCredentials(woocommerceEndpoint) : backendEndpoint;
      const cloudfnRequestEndpoint = directRequest ? await this.insertWcCredentials(woocommerceEndpoint) : firebaseEndpoint;

      return this.httpService.getFromBackendOrCloud(
        { endpoint: backendRequestEndpoint },
        { endpoint: cloudfnRequestEndpoint }
      )
        .toPromise()
        .then((response) => {
          res(response);
        })
        .catch((error) => {
          rej(error.toString());
        });
    });
  }

  async getLastOrders(
    numberOfOrders: number = 10,
    onlyCompleted = false,
    storeName: String,
    offset: number = 0
  ) {
    return new Promise(async (res, rej) => {
      const agency = localStorage.getItem("agency");
      const directRequest = localStorage.getItem("storeDirectRequest") == "true";
      const woocommerceEndpoint = `orders/?per_page=${numberOfOrders}&offset=${offset}&status=any`;

      const backendEndpoint = `woocommerce/orders?storeName=${storeName}&num=${numberOfOrders}&completed=${onlyCompleted}&offset=${offset}&agency=${agency}`;
      const firebaseEndpoint = `woocommerceOrders?storeName=${storeName}&num=${numberOfOrders}&completed=${onlyCompleted}&offset=${offset}&agency=${agency}`;

      const backendRequestEndpoint = directRequest ? await this.insertWcCredentials(woocommerceEndpoint) : backendEndpoint;
      const cloudfnRequestEndpoint = directRequest ? await this.insertWcCredentials(woocommerceEndpoint) : firebaseEndpoint;

      return this.httpService.getFromBackendOrCloud(
        { endpoint: backendRequestEndpoint },
        { endpoint: cloudfnRequestEndpoint }
      )
        .toPromise()
        .then((response) => {
          res(response);
        })
        .catch((error) => {
          rej(error.toString());
        });
    });
  }
  async getLastOrdersStatusFilter(
    numberOfOrders: number = 10,
    onlyCompleted = false,
    storeName: String,
    offset: number = 0,
    statusArray = ["any"],
    before: String = "undefined",
    after: String = "undefined",
    searchTerm: String = '',
  ) {

    const body = { statusArray };
    const agency = localStorage.getItem("agency");
    const directRequest = localStorage.getItem("storeDirectRequest") == "true";

    return directRequest
      ? this.paginateWoocommerceStatus(numberOfOrders, offset, statusArray, before, after, searchTerm)
      : this.httpService.postToBackendOrCloud(
        { endpoint: `woocommerce/orders-status-filter?storeName=${storeName}&num=${numberOfOrders}&completed=${onlyCompleted}&offset=${offset}&agency=${agency}&before=${before}&after=${after}&searchTerm=${searchTerm}`, body: body },
        { endpoint: `woocommerceOrdersStatusFilter?storeName=${storeName}&num=${numberOfOrders}&completed=${onlyCompleted}&offset=${offset}&agency=${agency}&before=${before}&after=${after}&searchTerm=${searchTerm}`, body: body }
      ).toPromise();

  }

  async paginateWoocommerceStatus(
    numberOfOrders: number = 10,
    offset: number = 0,
    statusArray = ["any"],
    before: String = "undefined",
    after: String = "undefined",
    searchTerm: String = '',
  ) {
    let status = statusArray;
    let params = before == "undefined" && after == "undefined" ? "" : `before=${before}&after=${after}`;

    let paramsStatus = status.length == 0 ? "status=any" : `status=${status.toString()}`;

    const woocommerceEndpoint = `orders/?per_page=${numberOfOrders}&offset=${offset}&search=${searchTerm}${params}&${paramsStatus}`;
    const requestEndpoint = await this.insertWcCredentials(woocommerceEndpoint);

    return this.httpService.get(requestEndpoint).toPromise();

  }


  public async setupWcDirectRequest(storeData) {
    const phrase = environment.phrase;
    const randomPass = storeData.randomPass;
    const criptoKey = randomPass + phrase;
    const decryptedWooKey = CryptoJS.AES.decrypt(storeData.encryptedKey, criptoKey);
    const decryptWooSecret = CryptoJS.AES.decrypt(storeData.encryptedSecret, criptoKey);
    const decryptKeyFormatUtf8 = CryptoJS.enc.Utf8.stringify(decryptedWooKey);
    const decryptSecretFormatUtf8 = CryptoJS.enc.Utf8.stringify(decryptWooSecret);
    localStorage.setItem("url", storeData.url);

    const key = await this.storage.set("key", decryptKeyFormatUtf8);
    const secret = await this.storage.set("secret", decryptSecretFormatUtf8);
    return {
      key,
      secret
    };
  }

  async insertWcCredentials(params) {
    const key = await this.storage.get("key");
    const secret = await this.storage.get("secret");
    const invalidKeys = key == null || secret == null;
    return invalidKeys ? await this.getKeysUrlPath(params) : params + `&consumer_key=${key}&consumer_secret=${secret}&queryStringAuth=true`;
  }
  async insertWcCredentialsURL(params) {
    const key = await this.storage.get("key");
    const secret = await this.storage.get("secret");
    const invalidKeys = key == null || secret == null;
    return invalidKeys ? await this.getKeysUrlPath(params) : params;
  }

  async getKeysUrlPath(params) {
    const storeName = localStorage.getItem("storeName");
    const agency = localStorage.getItem("agency");
    const store: any = await this.firestoreService.getStoreInfo(storeName, agency);
    const credentials = await this.setupWcDirectRequest(store.data());
    return params + `&consumer_key=${credentials.key}&consumer_secret=${credentials.secret}`;
  }

  async removeWcCredentials() {
    await this.storage.remove("key");
    await this.storage.remove("secret");
    await this.storage.remove("blingKey");
    await this.storage.remove("tinyApi");
  }

  async getSalesReport(minDate: String, maxDate: String, storeName: String, shouldForceProxy?: boolean, type?: String) {
    return new Promise(async (res, rej) => {
      const agency = localStorage.getItem("agency");
      let directRequest = localStorage.getItem("storeDirectRequest") == "true";
      if (typeof shouldForceProxy !== 'undefined' && shouldForceProxy) directRequest = false;
      const woocommerceEndpoint = `reports/sales?&date_min=${minDate}&date_max=${maxDate}`;

      const backendEndpoint = `store/sales-report-all-platforms?storeName=${storeName}&min=${minDate}&max=${maxDate}&agency=${agency}&type=${type}`;
      const firebaseEndpoint = `getSalesReportsFromAllPlatforms?storeName=${storeName}&min=${minDate}&max=${maxDate}&agency=${agency}&type=${type}`;

      const backendRequestEndpoint = directRequest ? await this.insertWcCredentials(woocommerceEndpoint) : backendEndpoint;
      const cloudfnRequestEndpoint = directRequest ? await this.insertWcCredentials(woocommerceEndpoint) : firebaseEndpoint;

      return this.httpService.getFromBackendOrCloud(
        { endpoint: backendRequestEndpoint },
        { endpoint: cloudfnRequestEndpoint }
      )
        .toPromise()
        .then((response) => {
          res(response);
        })
        .catch((error) => {
          //console.log(error.status);
          // console.log(error.error); // error message as string
          // console.log(error.headers);

          // console.log("deu errado.");
          console.log(JSON.stringify(error));

          rej(error);
        });
    });
  }

  async getTopSellersReport(minDate: String, maxDate: String, storeName: String) {
    return new Promise(async (res, rej) => {
      const agency = localStorage.getItem("agency");
      const directRequest = localStorage.getItem("storeDirectRequest") == "true";
      const woocommerceEndpoint = `reports/top_sellers?&date_min=${minDate}&date_max=${maxDate}`;

      const backendEndpoint = `woocommerce/top-sellers-report?storeName=${storeName}&minDate=${minDate}&maxDate=${maxDate}&agency=${agency}`;
      const firebaseEndpoint = `woocommerceTopSellersReport?storeName=${storeName}&min=${minDate}&max=${maxDate}&agency=${agency}`;

      const backendRequestEndpoint = directRequest ? await this.insertWcCredentials(woocommerceEndpoint) : backendEndpoint;
      const cloudfnRequestEndpoint = directRequest ? await this.insertWcCredentials(woocommerceEndpoint) : firebaseEndpoint;

      return this.httpService.getFromBackendOrCloud(
        { endpoint: backendRequestEndpoint },
        { endpoint: cloudfnRequestEndpoint }
      )
        .toPromise()
        .then((response) => {
          res(response);
        })
        .catch((error) => {
          rej(error.toString());
        });
    });
  }

  //verificar === result [] vazio
  getOrderSearch(searchTerm) {
    const agency = localStorage.getItem("agency");
    const storeName = localStorage.getItem("storeName");
    let requestEndpoint = `woocommerceOrderInformation?storeName=${storeName}&agency=${agency}&searchTerm=${searchTerm}&per_page=100`;
    return this.httpService.get(requestEndpoint);
  }

  async getOrderNotes(orderId) {
    return new Promise(async (res, rej) => {
      const agency = localStorage.getItem("agency");
      const storeName = localStorage.getItem("storeName");
      const directRequest = localStorage.getItem("storeDirectRequest") == "true";
      const woocommerceEndpoint = `orders/${orderId}/notes/?`;
      const firebaseEndpoint = `woocommerceOrderNotes?storeName=${storeName}&agency=${agency}&orderId=${orderId}`;
      const requestEndpoint = directRequest ? await this.insertWcCredentials(woocommerceEndpoint) : firebaseEndpoint;

      return this.httpService.getFromBackendOrCloud(
        { endpoint: `woocommerce/order-notes?storeName=${storeName}&agency=${agency}&orderId=${orderId}` },
        { endpoint: requestEndpoint })
        .toPromise()
        .then((response) => {
          res(response);
        })
        .catch((error) => {
          rej(error.toString());
        });
    });
  }

  async getOrder(orderId, storeName) {
    return new Promise(async (res, rej) => {
      const agency = localStorage.getItem("agency");
      const firebaseEndpoint = `woocommerceRetrieveOrder?storeName=${storeName}&agency=${agency}&orderId=${orderId}`;
      const requestEndpoint = firebaseEndpoint;
      return this.httpService.get(requestEndpoint).toPromise()
        .then((response) => {
          res(response);
        })
        .catch((error) => {
          rej(error.toString());
        });
    });
  }

  getStatusList() {
    return new Promise(async (res, rej) => {
      const agency = localStorage.getItem("agency");
      const storeName = localStorage.getItem("storeName");
      const directRequest = localStorage.getItem("storeDirectRequest") == "true";
      const woocommerceEndpoint = `reports/orders/totals/?`;

      const backendEndpoint = `woocommerce/status-list?storeName=${storeName}&agency=${agency}`;
      const firebaseEndpoint = `getStatusList?storeName=${storeName}&agency=${agency}`;

      const backendRequestEndpoint = directRequest ? await this.insertWcCredentials(woocommerceEndpoint) : backendEndpoint;
      const cloudfnRequestEndpoint = directRequest ? await this.insertWcCredentials(woocommerceEndpoint) : firebaseEndpoint;

      return this.httpService.getFromBackendOrCloud(
        { endpoint: backendRequestEndpoint },
        { endpoint: cloudfnRequestEndpoint }
      )
        .toPromise()
        .then((response) => {
          res(response);
        })
        .catch((error) => {
          // console.log("ERRORRRRR" + JSON.stringify(error));

          rej(error);
        });
    });
  }

  updateOrderStatus(orderId, status) {
    return new Promise(async (res, rej) => {
      const agency = localStorage.getItem("agency");
      const storeName = localStorage.getItem("storeName");
      const userEmail = localStorage.getItem("userEmail");
      const directRequest = localStorage.getItem("storeDirectRequest") == "true";
      const woocommerceEndpoint = `orders/${orderId}/?status=${status}`;

      const firebaseEndpoint = `updateOrderStatus?storeName=${storeName}&agency=${agency}&orderId=${orderId}&status=${status}&userEmail=${userEmail}`;
      const requestEndpoint = directRequest ? await this.insertWcCredentials(woocommerceEndpoint) : firebaseEndpoint;

      const backendEndpoint = `woocommerce/update-order-status?storeName=${storeName}&agency=${agency}&orderId=${orderId}&status=${status}&userEmail=${userEmail}`;
      const backendRequest = directRequest ? await this.insertWcCredentials(woocommerceEndpoint) : backendEndpoint;

      return this.httpService.requestBackendOrCloud(
        { method: 'POST', endpoint: backendRequest },
        { method: 'PUT', endpoint: requestEndpoint }
      )
        .toPromise()
        .then((response) => {
          res(response);
        })
        .catch((error) => {
          rej(error.toString());
        });
    });
  }

  getStores(agency: string) {
    return this.httpService.getFromBackendOrCloud(
      { endpoint: `store/get-stores?agency=${agency}` },
      { endpoint: `getStores?agency=${agency}` }
    );
  }

  testWoocommerceStore(
    url,
    version,
    woocommerceKey,
    woocommerceSecret,
    storeName,
    storeCategory
  ) {
    return new Promise((res, rej) => {
      const agency = localStorage.getItem("agency");
      const language = localStorage.getItem("language");
      const userEmail = localStorage.getItem("userEmail");

      this.httpService.requestBackendOrCloud(
        { method: 'POST', endpoint: `woocommerce/store-test?url=${url}&version=${version}&agency=${agency}&language=${language}&woocommerceKey=${woocommerceKey}&woocommerceSecret=${woocommerceSecret}&storeName=${storeName}&userEmail=${userEmail}&storeCategory=${storeCategory}` },
        { method: 'GET', endpoint: `woocommerceStoreTest?url=${url}&version=${version}&agency=${agency}&language=${language}&woocommerceKey=${woocommerceKey}&woocommerceSecret=${woocommerceSecret}&storeName=${storeName}&userEmail=${userEmail}&storeCategory=${storeCategory}` }
      )
        .subscribe((resp: any) => {
          if (resp.result) {
            if (resp.result == "success") {
              res(resp);
            } else {
              rej(resp);
            }
          } else {
            rej(resp);
          }
        });
        
      setTimeout(() => {
        rej({ result: "Function time out" });
      }, 40000);
    });
  }

  updateTrackingCode(orderId, trackingCode) {
    return new Promise((res, rej) => {
      const agency = localStorage.getItem("agency");
      const storeName = localStorage.getItem("storeName");
      const userEmail = localStorage.getItem("userEmail");

      let requestEndpoint = `updateTrackingCode?storeName=${storeName}&agency=${agency}&orderId=${orderId}&trackingCode=${trackingCode}&userEmail=${userEmail}`;
      this.httpService.get(requestEndpoint).subscribe((resp: any) => {
        console.log(resp);
        res(resp);
      });

      setTimeout(() => {
        rej({ result: "Function time out" });
      }, 40000);
    });
  }

  checkUrl(woocommerceUrl: string) {
    return new Promise((res, rej) => {
      let requestEndpoint = `woocoomerceCheckUrl?woocommerceUrl=${woocommerceUrl}`;
      this.httpService
        .get(requestEndpoint)
        .toPromise()
        .then((response) => {
          res(response.toString());
        })
        .catch((error) => {
          rej(error.toString());
        });
    });
  }
  //done

  async getCuopons() {
    return new Promise(async (res, rej) => {
      const agency = localStorage.getItem("agency");
      const storeName = localStorage.getItem("storeName");
      const directRequest = localStorage.getItem("storeDirectRequest") == "true";
      const woocommerceEndpoint = `coupons?per_page=30`;
      const firebaseEndpoint = `woocommerceFunctions-getAllCoupons?storeName=${storeName}&agency=${agency}`;;
      const requestEndpoint = directRequest ? await this.insertWcCredentials(woocommerceEndpoint) : firebaseEndpoint;

      this.httpService.getFromBackendOrCloud(
        { endpoint: `woocommerce-functions/get-all-coupons?storeName=${storeName}&agency=${agency}` },
        { endpoint: requestEndpoint }
      )
        .toPromise()
        .then((response) => {
          res(response);
        })
        .catch((error) => {
          rej(error.toString());
        });
    });
  }

  newOrderNotes(order, orderNote, customerNote) {
    return new Promise(async (res, rej) => {

      let storeName = localStorage.getItem("storeName");
      let agency = localStorage.getItem("agency");
      const directRequest = localStorage.getItem("storeDirectRequest") == "true";
      const woocommerceEndpoint = `orders/${order}/notes?note=${orderNote}&customer_note=${customerNote}`;
      const firebaseEndpoint = `woocommerceFunctions-createOrderNotes?storeName=${storeName}&agency=${agency}&order=${order}&orderNote=${orderNote}&customerNote=${customerNote}`;
      const requestEndpoint = directRequest ? await this.insertWcCredentials(woocommerceEndpoint) : firebaseEndpoint;

      return this.httpService.post(requestEndpoint).toPromise()
        .then((response) => {
          res(response);
        })
        .catch((error) => {
          rej(error.toString());
        });
    });
  }

  createCoupon(body) {
    return new Promise(async (res, rej) => {
      const storeName = localStorage.getItem("storeName");
      const agency = localStorage.getItem("agency");
      const directRequest = localStorage.getItem("storeDirectRequest") == "true";
      const woocommerceEndpoint = `coupons?`;
      const firebaseEndpoint = `woocommerceFunctions-createCoupon?storeName=${storeName}&agency=${agency}`;
      const requestEndpoint = directRequest ? await this.insertWcCredentials(woocommerceEndpoint) : firebaseEndpoint;


      this.httpService
        .post(requestEndpoint, body, true)
        .toPromise()
        .then((response) => {
          res(response);
        })
        .catch((error) => {
          rej(error);
        });
    });
  }

  deleteCoupon(id: number) {
    return new Promise(async (res, rej) => {
      const storeName = localStorage.getItem("storeName");
      const agency = localStorage.getItem("agency");
      const directRequest = localStorage.getItem("storeDirectRequest") == "true";
      const woocommerceEndpoint = `coupons/${id}/?force=true`;
      const firebaseEndpoint = `woocommerceFunctions-deleteCoupon?storeName=${storeName}&agency=${agency}&coupon=${id}`;
      const requestEndpoint = directRequest ? await this.insertWcCredentials(woocommerceEndpoint) : firebaseEndpoint;


      this.httpService
        .delete(requestEndpoint)
        .toPromise()
        .then((response) => {
          res(response);
        })
        .catch((error) => {
          rej(error);
        });
    });
  }

  updateCoupon(body, id: string) {
    return new Promise((res, rej) => {
      const storeName = localStorage.getItem("storeName");
      const agency = localStorage.getItem("agency");

      const requestEndpoint = `woocommerceFunctions-updateCoupon?storeName=${storeName}&agency=${agency}&id=${id}`;

      this.httpService
        .post(requestEndpoint, body, true)
        .toPromise()
        .then((response) => {
          res(response);
        })
        .catch((error) => {
          rej(error);
        });
    });
  }

  getOrdersBadge() {
    const storeName = localStorage.getItem("storeName");
    const agency = localStorage.getItem("agency");
    let requestEndpoint = `woocommerceFunctions-getOrdersBadge?storeName=${storeName}&agency=${agency}`;

    return this.httpService.get(requestEndpoint);
  }

  getProductPhoto(id: number) {
    return new Promise((res, rej) => {
      const storeName = localStorage.getItem("storeName");
      const agency = localStorage.getItem("agency");

      this.httpService.getFromBackendOrCloud(
        { endpoint: `woocommerce-functions/get-product-photo?storeName=${storeName}&agency=${agency}&id=${id}` },
        { endpoint: `woocommerceFunctions-getProductPhoto?storeName=${storeName}&agency=${agency}&id=${id}` }
      )
        .toPromise()
        .then((response) => {
          res(response);
        })
        .catch((error) => {
          rej(error);
        });
    });
  }

  getProducts(page) {
    return new Promise(async (res, rej) => {
      const agency = localStorage.getItem("agency");
      const storeName = localStorage.getItem("storeName");
      const directRequest = localStorage.getItem("storeDirectRequest") == "true";
      const woocommerceEndpoint = `products/?page=${page}&per_page=20`;

      const backendEndpoint = `woocommerce-functions/get-products?agency=${agency}&storeName=${storeName}&page=${page}`;
      const firebaseEndpoint = `woocommerceFunctions-getProducts?agency=${agency}&storeName=${storeName}&page=${page}`;

      const backendRequestEndpoint = directRequest ? await this.insertWcCredentials(woocommerceEndpoint) : backendEndpoint;
      const cloudfnRequestEndpoint = directRequest ? await this.insertWcCredentials(woocommerceEndpoint) : firebaseEndpoint;

      if (this.authService.checkAuthToken()) {
        this.authService.setAuthCredentials();
      }

      this.httpService
        .getFromBackendOrCloud(
          { endpoint: backendRequestEndpoint },
          { endpoint: cloudfnRequestEndpoint }
        )
        .subscribe((response) => {
          res(response);
        });

      (error) => {
        console.log(error);
        rej(error);
      };
    });
  }

  filterProducts(filterType: String, filter: String, page: number) {
    return new Promise(async (res, rej) => {
      const agency = localStorage.getItem("agency");
      const storeName = localStorage.getItem("storeName");
      const directRequest = localStorage.getItem("storeDirectRequest") == "true";
      const woocommerceEndpoint = `products/?page=${page}`;
      const firebaseEndpoint = `woocommerceFunctions-filterProduct?agency=${agency}&storeName=${storeName}&filterType=${filterType}&filter=${filter}&page=${page}`;

      const requestEndpoint = directRequest ? await this.insertWcCredentials(woocommerceEndpoint) : firebaseEndpoint;

      this.httpService.get(requestEndpoint).subscribe((response) => {
        res(response);
      }),
        (error) => {
          console.log(error);
          rej(error);
        };
    });
  }

  getProductCategories() {
    return new Promise(async (res, rej) => {
      const storeName = localStorage.getItem("storeName");
      const agency = localStorage.getItem("agency");

      const directRequest = localStorage.getItem("storeDirectRequest") == "true";
      const woocommerceEndpoint = `products/categories/?per_page=100`;

      const backendEndpoint = `woocommerce-functions/get-products-categories?storeName=${storeName}&agency=${agency}`;
      const firebaseEndpoint = `woocommerceFunctions-getProductCategories?storeName=${storeName}&agency=${agency}`;

      const backendRequestEndpoint = directRequest ? await this.insertWcCredentials(woocommerceEndpoint) : backendEndpoint;
      const cloudfnRequestEndpoint = directRequest ? await this.insertWcCredentials(woocommerceEndpoint) : firebaseEndpoint;


      this.httpService.getFromBackendOrCloud(
        { endpoint: backendRequestEndpoint },
        { endpoint: cloudfnRequestEndpoint }
      )
        .toPromise()
        .then((response) => {
          res(response);
        })
        .catch((error) => {
          rej(error);
        });
    });
  }

  createProductCategories(name, slug, parent) {
    return new Promise(async (res, rej) => {
      const storeName = localStorage.getItem("storeName");
      const agency = localStorage.getItem("agency");

      const directRequest = localStorage.getItem("storeDirectRequest") == "true";
      const woocommerceEndpoint = `products/categories/?name=${name}&slug=${slug}&parent=${parent}`;
      const firebaseEndpoint = `woocommerceFunctions-createProductCategories?storeName=${storeName}&agency=${agency}&name=${name}&slug=${slug}&parent=${parent}`;
      const requestEndpoint = directRequest ? await this.insertWcCredentials(woocommerceEndpoint) : firebaseEndpoint;


      this.httpService
        .post(requestEndpoint)
        .toPromise()
        .then((response) => {
          res(response);
        })
        .catch((error) => {
          rej(error);
        });
    });
  }

  getProductsTags() {
    return new Promise(async (res, rej) => {
      const storeName = localStorage.getItem("storeName");
      const agency = localStorage.getItem("agency");

      const directRequest = localStorage.getItem("storeDirectRequest") == "true";
      const woocommerceEndpoint = `products/tags/?per_page=100`;
      const firebaseEndpoint = `woocommerceFunctions-getProductsTags?storeName=${storeName}&agency=${agency}`;

      const requestEndpoint = directRequest ? await this.insertWcCredentials(woocommerceEndpoint) : firebaseEndpoint;

      this.httpService.getFromBackendOrCloud(
        { endpoint: `woocommerce-functions/get-products-tags?storeName=${storeName}&agency=${agency}` },
        { endpoint: requestEndpoint }
      )
        .toPromise()
        .then((response) => {
          res(response);
        })
        .catch((error) => {
          rej(error);
        });
    });
  }

  createProductsTags(name) {
    return new Promise(async (res, rej) => {
      const storeName = localStorage.getItem("storeName");
      const agency = localStorage.getItem("agency");
      const directRequest = localStorage.getItem("storeDirectRequest") == "true";
      const woocommerceEndpoint = `products/tags/name=${name}`;
      const firebaseEndpoint = `woocommerceFunctions-createProductTags?storeName=${storeName}&agency=${agency}&name=${name}`;

      const requestEndpoint = directRequest ? await this.insertWcCredentials(woocommerceEndpoint) : firebaseEndpoint;

      this.httpService
        .post(requestEndpoint)
        .toPromise()
        .then((response) => {
          res(response);
        })
        .catch((error) => {
          rej(error);
        });
    });
  }

  getTaxClasses() {
    return new Promise(async (res, rej) => {
      const storeName = localStorage.getItem("storeName");
      const agency = localStorage.getItem("agency");

      const directRequest = localStorage.getItem("storeDirectRequest") == "true";
      const woocommerceEndpoint = `taxes/classes/?`;
      const firebaseEndpoint = `woocommerceFunctions-getTaxClasses?storeName=${storeName}&agency=${agency}`;

      const requestEndpoint = directRequest ? await this.insertWcCredentials(woocommerceEndpoint) : firebaseEndpoint;

      this.httpService.getFromBackendOrCloud(
        { endpoint: `woocommerce-functions/get-tax-classes?storeName=${storeName}&agency=${agency}` },
        { endpoint: requestEndpoint }
      )
        .toPromise()
        .then((response) => {
          res(response);
        })
        .catch((error) => {
          rej(error);
        });
    });
  }

  updateProduct(id, body) {
    return new Promise(async (res, rej) => {
      const storeName = localStorage.getItem("storeName");
      const agency = localStorage.getItem("agency");
      const name = body.name;

      const directRequest = localStorage.getItem("storeDirectRequest") == "true";
      const woocommerceEndpoint = `products/${id}/?`;

      const firebaseEndpoint = `woocommerceFunctions-updateProduct?storeName=${storeName}&agency=${agency}&id=${id}&name=${name}`;
      const requestEndpoint = directRequest ? await this.insertWcCredentials(woocommerceEndpoint) : firebaseEndpoint;

      const backendEndpoint = `woocommerce/update-product?storeName=${storeName}&agency=${agency}&id=${id}&name=${name}`;
      const backendRequest = directRequest ? await this.insertWcCredentials(woocommerceEndpoint) : backendEndpoint;

      this.httpService.requestBackendOrCloud( 
        { method: 'POST', endpoint: backendRequest, body: body, isJsonHeader: true },
        { method: 'PUT', endpoint: requestEndpoint, body: body, isJsonHeader: true }
      )
        .toPromise()
        .then((response) => {
          res(response);
        })
        .catch((error) => {
          rej(error);
        });
    });
  }

  createProduct(body) {
    return new Promise(async (res, rej) => {
      const storeName = localStorage.getItem("storeName");
      const agency = localStorage.getItem("agency");
      const name = body.name;

      const directRequest = localStorage.getItem("storeDirectRequest") == "true";
      const woocommerceEndpoint = `products/?`;

      const firebaseEndpoint = `woocommerceFunctions-createProduct?storeName=${storeName}&agency=${agency}&name=${name}`;
      const cloudfnRequest = directRequest ? await this.insertWcCredentials(woocommerceEndpoint) : firebaseEndpoint;

      const backendEndpoint = `woocommerce-functions/create-product?storeName=${storeName}&agency=${agency}&name=${name}`
      const backendRequest = directRequest ? await this.insertWcCredentials(woocommerceEndpoint) : backendEndpoint;

      this.httpService
        .requestBackendOrCloud(
          { method: 'POST', endpoint: backendRequest, body, isJsonHeader: true },
          { method: 'POST', endpoint: cloudfnRequest, body, isJsonHeader: true }
        )
        .toPromise()
        .then((response) => {
          res(response);
        })
        .catch((error) => {
          rej(error);
        });
    });
  }

  getProductAttributes() {
    return new Promise((res, rej) => {
      const storeName = localStorage.getItem("storeName");
      const agency = localStorage.getItem("agency");

      let requestEndpoint = `woocommerceFunctions-getProductAttributes?storeName=${storeName}&agency=${agency}`;
      this.httpService.get(requestEndpoint).subscribe(
        (resp: any) => {
          res(resp);
        },
        (error) => {
          rej(error);
        }
      );
    });
  }
  createProductAttributes(name: string, slug: string) {
    return new Promise((res, rej) => {
      const storeName = localStorage.getItem("storeName");
      const agency = localStorage.getItem("agency");

      const body = {
        name: name,
        slug: slug,
        variation: true,
      };
      let requestEndpoint = `woocommerceFunctions-createProductAttribute?storeName=${storeName}&agency=${agency}`;
      this.httpService.post(requestEndpoint, body, true).subscribe(
        (resp: any) => {
          res(resp);
        },
        (error) => {
          rej(error);
        }
      );
    });
  }

  getProductAttributesTerms(id: string) {
    return new Promise((res, rej) => {
      const storeName = localStorage.getItem("storeName");
      const agency = localStorage.getItem("agency");

      let requestEndpoint = `woocommerceFunctions-getProductAttributeTerms?storeName=${storeName}&agency=${agency}&id=${id}`;
      this.httpService.get(requestEndpoint).subscribe(
        (resp: any) => {
          res(resp);
        },
        (error) => {
          rej(error);
        }
      );
    });
  }

  createProductAttributesTerms(id: string, name: string) {
    return new Promise((res, rej) => {
      const storeName = localStorage.getItem("storeName");
      const agency = localStorage.getItem("agency");

      const body = {
        name: name,
      };
      let requestEndpoint = `woocommerceFunctions-createProductAttributeTerm?storeName=${storeName}&agency=${agency}&id=${id}`;
      this.httpService.post(requestEndpoint, body, true).subscribe(
        (resp: any) => {
          res(resp);
        },
        (error) => {
          rej(error);
        }
      );
    });
  }

  createProductVariation(id: string, body: any) {
    return new Promise((res, rej) => {
      const storeName = localStorage.getItem("storeName");
      const agency = localStorage.getItem("agency");

      let requestEndpoint = `woocommerceFunctions-createProductVariation?storeName=${storeName}&agency=${agency}&id=${id}`;
      this.httpService.post(requestEndpoint, body, true).subscribe(
        (resp: any) => {
          res(resp);
        },
        (error) => {
          rej(error);
        }
      );
    });
  }


  getProductVariation(id: string) {
    return new Promise((res, rej) => {
      const storeName = localStorage.getItem("storeName");
      const agency = localStorage.getItem("agency");

      this.httpService.getFromBackendOrCloud(
        { endpoint: `woocommerce-functions/product-variation?storeName=${storeName}&agency=${agency}&id=${id}` },
        { endpoint: `woocommerceFunctions-getProductVariation?storeName=${storeName}&agency=${agency}&id=${id}` }
      )
        .subscribe(
          (resp: any) => {
            res(resp);
          },
          (error) => {
            rej(error);
          }
        );
    });
  }
}

