import { Injectable } from "@angular/core";
import { FirebaseX } from "@ionic-native/firebase-x/ngx";
import { Platform } from "@ionic/angular";
import { AngularFirestore } from "@angular/fire/firestore";
import { AngularFireAuth } from "@angular/fire/auth";
import { FirebaseService } from "./firebase.service";
import { Device } from "@ionic-native/device/ngx";

@Injectable()
export class PushService {
  constructor(
    private firebase: FirebaseX,
    private firestore: AngularFirestore,
    public afAuth: AngularFireAuth,
    private platform: Platform,
    private firebaseService: FirebaseService,
    private device: Device
  ) {}
  public token;

  async getToken(user) {
    console.log("PEDINDO TOKEN de NOTIFICAÇÃO");
    if (this.device.platform == "Android") {
      // token = await this.firebase.getToken();

      this.firebase.getToken().then(
        (tkn) => {
          console.log(`The token is ${tkn}`);
          this.token = tkn;
          this.saveToken(tkn, user)
            .then((res) => {
              console.log("token saved", res);
              localStorage.setItem("notificationToken", tkn);
            })
            .catch((err) => {
              console.log("TOKEN NOT SAVED");
            });
        },
        (error) => {
          console.log(`ERROR - Getting Token: ${error}`);
        }
      );
    }

    if (this.device.platform == "iOS") {
      await this.firebase
        .grantPermission()
        .then(async (res) => {
          console.log(
            "IOS - Notification Grant Permission Response:",
            JSON.stringify(res)
          );
        })
        .catch((error) => {
          console.error(
            "IOS - Notification Grant Permission Failed ->",
            JSON.stringify(error)
          );
        });

      this.firebase.getToken().then(
        (tkn) => {
          console.log(`The token is ${tkn}`);
          this.token = tkn;
          this.saveToken(tkn, user)
            .then((res) => {
              console.log("token saved", res);
              localStorage.setItem("notificationToken", tkn);
            })
            .catch((err) => {
              console.log("TOKEN NOT SAVED");
            });
        },
        (error) => {
          console.log(`ERROR - Getting Token: ${error}`);
        }
      );
    }
  }

  public async hasPermission() {
    return this.firebase.hasPermission();
  }

  // private saveToken(token, user) {
  //     console.log("TOKEN de NOTIFICAÇÃO: "+ this.token);

  //     if (!token) return;

  //   // const devicesRef = this.firestore.collection('userList');

  //   // const data = {
  //   //   token,
  //   //   userId: 'testUserId'
  //   // };
  //   const agency = localStorage.getItem('agency')

  //   console.log("REGISTRANDO TOKEN(PUSH) DO USUÁRIO");
  //   console.log(user.email)
  //   console.log(token);

  //   return this.firestore.doc(`${agency}UserList/${user.email}`).update({ token: token });

  //     // return devicesRef.doc(token).set(data);
  // }

  private saveToken(token, user) {
    console.log("TOKEN de NOTIFICAÇÃO: " + this.token);

    if (!token) return;

    // const devicesRef = this.firestore.collection('userList');

    // const data = {
    //   token,
    //   userId: 'testUserId'
    // };

    console.log("REGISTRANDO TOKEN(PUSH) DO USUÁRIO");
    console.log(user.email);
    console.log(token);

    return this.firebaseService.saveToken(token, user.email);
    // return devicesRef.doc(token).set(data);
  }

  onNotifications() {
    return this.firebase.onMessageReceived();
  }
}
