import { Component, OnInit } from "@angular/core";
import { ViewChild } from "@angular/core";
import { FormBuilder, Validators } from "@angular/forms";
import { FirebaseService } from "../../services/firebase.service";
import {
  Router,
  ActivatedRoute,
  RouterModule,
  Routes,
  NavigationEnd,
} from "@angular/router";
import { LoadingProvider } from "../../providers/loading";
import { AlertController } from "@ionic/angular";
import { Platform, NavController } from "@ionic/angular";
import { ModalController } from "@ionic/angular";
import { WoocommerceService } from "../../services/woocommerce.service";
import { TranslateService } from "@ngx-translate/core";

@Component({
  selector: "app-create-user-modal",
  templateUrl: "./create-user-modal.page.html",
  styleUrls: ["./create-user-modal.page.scss"],
})
export class CreateUserModalPage implements OnInit {
  clientForm = this.formBuilder.group({
    clientEmail: [
      "",
      [
        Validators.required,
        Validators.pattern(
          "^[A-Za-z0-9._%+-]+@[A-Za-z0-9._%+-]{2,}[.][A-Za-z]{2,}$"
        ),
      ],
    ],
    clientStore: ["", [Validators.required]],
    clientName: ["", [Validators.required]],
  });
  storesArray: any;

  constructor(
    private firestoreService: FirebaseService,
    router: Router,
    public loading: LoadingProvider,
    private navController: NavController,
    private modalController: ModalController,
    private woocommerceService: WoocommerceService,
    private formBuilder: FormBuilder,
    public alertCtrl: AlertController,
    private translate: TranslateService
  ) {
    this.loadStoresData();
  }

  ngOnInit() {}

  async closeModal() {
    const onClosedData: string = "Wrapped Up!";
    await this.modalController.dismiss(onClosedData);
  }

  async submitClient() {
    let email = this.clientForm.value.clientEmail;
    let name = this.clientForm.value.clientName;
    let store = this.clientForm.value.clientStore;
    const errorMessage = await this.alertCtrl.create({
      message: this.translate.instant("FAIELD_CREATE_USER"),
      header: "Erro",
      cssClass: "secondary",
      buttons: [{ text: "Ok", handler: (data) => {} }],
    });
    const successMessage = await this.alertCtrl.create({
      message: this.translate.instant("SUCCESS_CREATE_USER"),
      header: "Sucesso",
      cssClass: "secondary",
      buttons: [{ text: "Ok", handler: (data) => {} }],
    });

    this.loading.present().then(() => {
      this.firestoreService
        .createClient(name, store, email)
        .then(async (result) => {
          this.loading.dismiss();
          this.clientForm.reset();
          await successMessage.present();
        })
        .catch(async (err) => {
          this.loading.dismiss();
          this.clientForm.reset();
          await errorMessage.present();
        });
    });
  }

  loadStoresData() {
    const agency = localStorage.getItem("agency");
    this.loading.present().then(() => {
      this.woocommerceService.getStores(agency).subscribe((result: any) => {
        this.storesArray = result;
        // this.loadForm = true
        this.loading.dismiss();
      });
    });
  }
}
