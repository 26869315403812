import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { PremiumType } from "../app/models/premiumType";
import { ModalController } from "@ionic/angular";
import { environment } from "../environments/environment";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import * as firebase from "firebase/app";
import {
  IAPQueryCallback,
  IAPProduct,
  IAPError,
  InAppPurchase2,
} from "@ionic-native/in-app-purchase-2/ngx";
import { Platform } from "@ionic/angular";
import { AlertController } from "@ionic/angular";
import { Device } from "@ionic-native/device/ngx";
import { LoadingProvider } from "../providers/loading";
import { AuthService } from "./auth.service";
import { TranslateService } from "@ngx-translate/core";
import { HttpService } from "./http.service";
import { PremiumPage } from "src/app/premium/premium.page";

@Injectable({
  providedIn: "root",
})
export class PremiumService {
  constructor(
    public inAppPurchase: InAppPurchase2,
    public platform: Platform,
    private device: Device,
    public loading: LoadingProvider,
    public translate: TranslateService,
    public alertCtrl: AlertController,
    public authService: AuthService,
    private httpService: HttpService,
    public modalController: ModalController,
  ) {
    // if (!environment.production) {
    //   this.showAlert("NOT Production - Clearing Transactions");
    //   inAppPurchase.autoFinishTransactions = true;
    // }

    if (this.platform.is('capacitor')) {
      inAppPurchase.validator = `https://validator.fovea.cc/v1/validate?appName=app.dashcommerce&apiKey=e7f5a749-d821-4e3f-8b38-62a44d059362`;
    }
  }

  public static INAPP_PRODUCTS_IDS = [
    "app.dashcommerce.subscription.single.month",
    "app.dashcommerce.subscription.single.year",
    "app.dashcommerce.subscription.agency.month",
    "app.dashcommerce.subscription.agency.year",
    "app.dashcommerce.subscription.enterprise.month",
    "app.dashcommerce.subscription.enterprise.year",
  ];

  public static singlePlanAmountOfStores = 1;
  public static agencyPlanAmountOfStores = 5;
  public static enterprisePlanAmountOfStores = 25;
  public static customPlanAmountOfStores = 0;
  public static products = [];
  public static instance = false;
  public static isPremium: boolean = true;
  public static isSelectedStorePremium: boolean = true;
  public static productId;
  public static finishedPremiumVerification = false;
  public static inProgress = false;
  public static onSuccess = false;

  public finishedCallback;

  isValidCurrentUser(currentUser) {
    return (
      currentUser != undefined &&
      currentUser != null &&
      currentUser != "undefined"
    );
  }

  setFinishedCallback(callback) {
    this.finishedCallback = callback;
  }

  getProductId() {
    return PremiumService.productId;
  }

  isValidToken(token) {
    return (
      token != "" && token != undefined && token != null && token != "undefined"
    );
  }

  isLoggedIn(): boolean {
    const user = JSON.parse(localStorage.getItem("user"));
    return user !== null;
  }

  allowRegistration(storesAmount) {
    if (storesAmount === 0) {
      return true;
    } else {
      const premiumType = PremiumService.productId;
      const subscriptionLimits = {
        "app.dashcommerce.subscription.single.month":
          PremiumService.singlePlanAmountOfStores,
        "app.dashcommerce.subscription.single.year":
          PremiumService.singlePlanAmountOfStores,
        "app.dashcommerce.subscription.agency.month":
          PremiumService.agencyPlanAmountOfStores,
        "app.dashcommerce.subscription.agency.year":
          PremiumService.agencyPlanAmountOfStores,
        "app.dashcommerce.subscription.enterprise.month":
          PremiumService.enterprisePlanAmountOfStores,
        "app.dashcommerce.subscription.enterprise.year":
          PremiumService.enterprisePlanAmountOfStores,
        "app.dashcommerce.custom": PremiumService.customPlanAmountOfStores,
      };
      return storesAmount < subscriptionLimits[premiumType];
    }
  }

  capitalize(string) {
    return string ? string.charAt(0).toUpperCase() + string.slice(1) : "";
  }

  getPremiumName() {
    // if (PremiumService.isPremium) {
    //   if (!PremiumService.productId.split(".")[3]) {
    //     return "Custom";
    //   } else {
    //     return this.capitalize(PremiumService.productId.split(".")[3]);
    //   }
    // } else {
    //   return "";
    // }
    return "";
  }

  isUserPremium(modalController: ModalController, premiumPage) {
    if (PremiumService.isPremium == true) {
      return true;
    } else {
      console.log(" ABRIU MODAL");
      this.openPremiumPage(modalController, premiumPage);
      return false;
    }
  }

  async openPremiumPage(modalController: ModalController, premiumPage) {
    const modal = await modalController.create({
      component: premiumPage,
    });
    return await modal.present();
  }

  async setPremiumUser(product, platform) {
    console.log(
      "[Set Premium] Calling set premium: " +
      product.id +
      " - Expiration date: " +
      product.expires_date
    );
    return new Promise(async (res, rej) => {
      const agency = localStorage.getItem("agency");
      const params = `?agency=${agency}`;
      let url = `premiumFunctions-setPremiumUser` + params;
      const reqBody = {
        product,
        platform,
      };
      this.httpService.post(url, reqBody, true).subscribe(
        (result) => {
          res(JSON.stringify(result));
        },
        (error) => {
          rej("Error " + JSON.stringify(error));
        }
      );
    });
  }

  async verifyPremium() {
    if (this.authService.isLoggedIn()) {
      const premiumResult: any = await this.backendIsUserPremium();
      PremiumService.finishedPremiumVerification = true;
      if (premiumResult.result === "user premium") {
        if (premiumResult.productId === "app.dashcommerce.custom") {
          PremiumService.customPlanAmountOfStores =
            premiumResult.receipt.amountOfStores;
          this.premiumMode(premiumResult.receipt);
        } else {
          this.premiumMode(premiumResult.receipt);
          console.log("USER IS PREMIUM BY BACKEND");
        }
      } else {
        this.guestMode();
        console.log("USER IS NOT PREMIUM BY BACKEND");
      }
    }
  }

  getPlatform() {
    if (!this.platform.is('capacitor') || this.device.platform === null) {
      return 'web';
    }
    else {
      return this.device.platform === "Android" ? "android" : "ios";
    }
  }

  async backendIsUserPremium() {
    return new Promise(async (res, rej) => {
      const platform = this.getPlatform();
      const agency = localStorage.getItem("agency");
      const params = `?agency=${agency}&platform=${platform}`;

      this.httpService.getFromBackendOrCloud(
        { endpoint: `premium/is-premium` + params },
        { endpoint: `premiumFunctions-isUserPremium` + params }
      )
        .subscribe(
          (result) => {
            res(result);
          },
          (error) => {
            rej("Error " + JSON.stringify(error));
          }
        );
    });
  }

  async cancelPremium(product, platform) {
    if (PremiumService.isPremium) {
      console.log(
        "[Cancel Premium] Calling cancel premium: " +
        product.id +
        " - Expiration date: " +
        product.expires_date
      );
      return new Promise(async (res, rej) => {
        const agency = localStorage.getItem("agency");
        let url = `premiumFunctions-cancelPremium?agency=${agency}`;
        const reqBody = {
          product,
          platform,
        };
        this.httpService.post(url, reqBody, true).subscribe(
          (result) => {
            res(JSON.stringify(result));
          },
          (error) => {
            rej("Error " + JSON.stringify(error));
          }
        );
      });
    } else {
      console.log(
        "[Cancel Premium] Tried to cancel premium, but user is not premium: " +
        product.id +
        " - Expiration date: " +
        product.expires_date
      );
    }
  }

  public setUpStore() {
    this.verifyPremium();
    if (PremiumService.instance) return;

    // console.log("[InAppPurchase] Initializing store setup...");
    if (this.platform.is("cordova")) {
      console.log("[InAppPurchase] Mobile detected...");

      try {
        this.inAppPurchase.verbosity = this.inAppPurchase.DEBUG;

        console.log("[InAppPurchase] Registering products...");

        let count = 0;
        PremiumService.INAPP_PRODUCTS_IDS.forEach((productId) => {
          this.inAppPurchase.register({
            id: productId,
            type: this.inAppPurchase.PAID_SUBSCRIPTION,
          });
          console.log("Product: " + productId + " [Registered]");
          count++;
        });

        console.log("[InAppPurchase] Setting up listeners...");
        this.initProductListeners();

        console.log(
          "[InAppPurchase] Loading informations about the registered products and restoring purchases..."
        );
        this.inAppPurchase.refresh();

        PremiumService.instance = true;
      } catch (error) {
        console.log("Error setting up store: " + JSON.stringify(error));
        // alert('error ocurred')
        // alert(error)
      }
    } else {
      console.log("[InAppPurchase] Not mobile, not setting up inapppurchase...");
    }
  }

  public refresh() {
    this.showLoader();
    let refresh = this.inAppPurchase.refresh();
    refresh.finished(() => {
      // this.showAlert("Purchases Restored");
      this.hideLoader();
      this.finishedCallback();
    });
    refresh.failed(() => {
      this.hideLoader();
    });
  }

  public redeem() {
    let redeem = this.inAppPurchase.redeem();
  }

  private hideLoader() {
    // TODO : Hide loader spinner
    this.loading.dismiss();
  }

  private showLoader() {
    // TODO : Show loader spinner
    this.loading.present();
  }

  private initProductListeners() {
    PremiumService.INAPP_PRODUCTS_IDS.forEach((productId) => {
      JSON.stringify("PRODUCT RETRIEVED: " + productId);
      // JSON.stringify(this.products[productId]);

      this.inAppPurchase.when(productId).approved(this.onSubscriptionApproved);

      this.inAppPurchase
        .when(productId)
        .cancelled(this.onSubscriptionCancelled);

      this.inAppPurchase.when(productId).error(this.onSubscriptionError);

      this.inAppPurchase.when(productId).updated(this.onSubscriptionUpdated);

      this.inAppPurchase.when(productId).expired(this.onSubscriptionExpired);

      this.inAppPurchase.when(productId).finished(this.onSubscriptionFinished);

      this.inAppPurchase
        .when(productId)
        .initiated(this.onSubscriptionInitiated);

      this.inAppPurchase.when(productId).invalid(this.onSubscriptionInvalid);

      this.inAppPurchase.when(productId).loaded(this.onSubscriptionLoaded);

      this.inAppPurchase.when(productId).owned(this.onSubscriptionOwned);

      this.inAppPurchase.when(productId).refunded(this.onSubscriptionRefunded);

      this.inAppPurchase
        .when(productId)
        .registered(this.onSubscriptionRegistered);

      this.inAppPurchase
        .when(productId)
        .requested(this.onSubscriptionRequested);

      this.inAppPurchase
        .when(productId)
        .unverified(this.onSubscriptionUnverified);

      this.inAppPurchase.when(productId).valid(this.onSubscriptionValid);

      this.inAppPurchase.when(productId).verified(this.onSubscriptionVerified);

      //const product = this.inAppPurchase.get(productId)

      this.refreshProduct(productId);
    });
  }

  async setupPremiumMode(product) {
    if (this.isLoggedIn()) {
      try {
        const platform = this.device.platform === "Android" ? "android" : "ios";
        if (this.needConversion(product)) {
          await this.setPremiumUser(product, platform);
          this.premiumMode(product);
          this.endProgress(true);
        }
      } catch (error) {
        this.endProgress(false);
      }
    }
  }

  needConversion(product) {
    if (!PremiumService.isPremium) {
      return true;
    } else {
      return product.id != PremiumService.productId;
    }
  }

  async setPremiumStore(isStorePremium) {
    PremiumService.isSelectedStorePremium = isStorePremium;
  }

  isSelectedStorePremium() {
    return PremiumService.isSelectedStorePremium;
  }

  isReceiptExpired(product) {
    if (product.expiryDate) {
      const today = new Date();
      const expiryDate = new Date(product.expiryDate);
      return today > expiryDate;
    } else {
      return false;
    }
  }

  isAndroidCancelledReceipt(product) {
    return (
      !product.owned &&
      product.state === "valid" &&
      product.canPurchase === true &&
      product.transaction == null &&
      !product.expiryDate
    );
  }

  onSubscriptionApproved: IAPQueryCallback = async function (product) {
    console.log("onSubscriptionApproved", product.id);
    console.log("isReceiptExpired: " + this.isReceiptExpired(product));
    console.log("PremiumService.productId: " + PremiumService.productId);
    product.verify();
    // if(!this.isReceiptExpired(product)){
    //   product.verify();
    // }
    // else {
    //   const platform = this.device.platform === 'Android' ? 'android': 'ios'
    //   if(product.id === PremiumService.productId || PremiumService.productId === undefined){
    //     product.verify();
    //     // await this.cancelPremium(product,platform)
    //     // this.guestMode();
    //   }
    // }
  }.bind(this);
  onSubscriptionCancelled: IAPQueryCallback = async function (
    product: IAPProduct
  ) {
    console.log("onSubscriptionCancelled", product);
    this.endProgress(false);
    this.showCanceledPurchaseMessage();
    const platform = this.device.platform === "Android" ? "android" : "ios";
    product.canPurchase = true;
    //this.inAppPurchase.ready(true);
    //ver se o que foi cancelado é o que ele ja possui ou outro
    const interval = setInterval(async () => {
      if (PremiumService.finishedPremiumVerification) {
        clearInterval(interval);
        if (product.id === PremiumService.productId) {
          await this.cancelPremium(product, platform);
          this.guestMode();
        }
      }
    }, 500);
  }.bind(this);
  onSubscriptionError: IAPQueryCallback = function (error: IAPError) {
    console.log("onSubscriptionError", error);
    // this.inAppPurchase.ready(true);
    this.showUnexpectedErrorMessage();
  }.bind(this);
  onSubscriptionUpdated: IAPQueryCallback = async function (
    product: IAPProduct
  ) {
    const platform = this.device.platform === "Android" ? "android" : "ios";
    if (this.device.platform === "Android") {
      const interval = setInterval(async () => {
        if (PremiumService.finishedPremiumVerification) {
          clearInterval(interval);
          if (product.id === PremiumService.productId) {
            if (
              this.isAndroidCancelledReceipt(product) &&
              PremiumService.isPremium
            ) {
              // this.showAlert("CANCELING " + product.id);
              await this.cancelPremium(product, platform);
              this.guestMode();
            }
            // this.showAlert(JSON.stringify(product))
          }
        }
      }, 500);
    }
  }.bind(this);

  onSubscriptionExpired: IAPQueryCallback = async function (product) {
    console.log("onSubscriptionExpired", JSON.stringify(product.id));
    this.endProgress(false);

    //this.showAlert('onSubscriptionExpired ' + JSON.stringify(product));
    this.inAppPurchase.ready(true);
    //product.finish();
    //product.trigger('canceled');
    //product.trigger('expired');

    product.canPurchase = true;
    // product.transaction = "";
    product.isExpired = true;
    product.expired = true;
    // product.expiryDate = "";
    const platform = this.device.platform === "Android" ? "android" : "ios";
    if (product.id === PremiumService.productId) {
      await this.cancelPremium(product, platform);
      this.guestMode();
    }
    //cancel premium on backend
  }.bind(this);

  onSubscriptionFinished: IAPQueryCallback = function (product: IAPProduct) {
    console.log("Subscription Finished", product);
  }.bind(this);

  onSubscriptionInitiated: IAPQueryCallback = function (product: IAPProduct) {
    console.log("onSubscriptionInitiated", product.id);
  }.bind(this);

  onSubscriptionInvalid: IAPQueryCallback = function (product: IAPProduct) {
    this.endProgress(false);
    console.log("onSubscriptionInvalid", product.id);
  }.bind(this);

  onSubscriptionLoaded: IAPQueryCallback = function (product: IAPProduct) {
    console.log("onSubscriptionLoaded", product.id);
  }.bind(this);

  onSubscriptionOwned: IAPQueryCallback = function (product: IAPProduct) {
    console.log("onSubscriptionOwned", product.id);

    if (product.valid && product.owned) {
      const platform = this.device.platform === "Android" ? "android" : "ios";
      console.log(
        "Activating premium... Subscription bought: " +
        JSON.stringify(product.id)
      );
      if (!this.isReceiptExpired(product)) {
        this.inAppPurchase.ready(true);
        this.setupPremiumMode(product);
      }
    }
    console.log("onSubscriptionOwned", product);
  }.bind(this);

  onSubscriptionRefunded: IAPQueryCallback = function (product: IAPProduct) {
    console.log("onSubscriptionRefunded", product.id);
    this.inAppPurchase.ready(true);
  }.bind(this);

  onSubscriptionRegistered: IAPQueryCallback = function (product: IAPProduct) {
    console.log("onSubscriptionRegistered", product.id);
  }.bind(this);

  onSubscriptionRequested: IAPQueryCallback = function (product: IAPProduct) {
    console.log("onSubscriptionRequested", product.id);
    this.inAppPurchase.ready(true);
  }.bind(this);

  onSubscriptionUnverified: IAPQueryCallback = function (product: IAPProduct) {
    console.log("onSubscriptionUnverified", product.id);
    this.guestMode();
    // this.hideLoader();
    this.showAlert("PRODUCT UNVERIFIED");
  }.bind(this);

  onSubscriptionValid: IAPQueryCallback = function (product) {
    console.log("onSubscriptionValid", product.id);
    this.inAppPurchase.ready(true);
    //product.canPurchase = true;
    //product.transaction = "";
  }.bind(this);

  onSubscriptionVerified: IAPQueryCallback = function (product: IAPProduct) {
    //this.showAlert('VERIFIED ' + JSON.stringify(product));
    console.log("onSubscriptionVerified", product.id);

    product.finish();
  }.bind(this);

  private refreshProduct(productId) {
    console.log("refreshProduct", JSON.stringify(productId));

    if (!PremiumService.products.find((x) => x.id == productId)) {
      PremiumService.products.push(this.getProduct(productId));
    } else {
      PremiumService.products[productId] = this.getProduct(productId);
    }
    // console.log('productList', JSON.stringify(this.products));
  }

  private getProduct(productId) {
    let product = this.inAppPurchase.get(productId);
    console.log("gettingProduct: ", JSON.stringify(product));
    return product;
  }

  private getProductFetched(productId) {
    return PremiumService.products[productId];
  }

  public inProgress() {
    console.log("[Premium] In progress...");
    PremiumService.inProgress = true;
    PremiumService.onSuccess = false;
  }

  public endProgress(success) {
    console.log("[Premium] On success...");
    PremiumService.inProgress = false;
    if (success) {
      PremiumService.onSuccess = true;
      this.showSuccessSlide();
      this.showAlert(this.translate.instant("ENJOY_PREMIUM"));
    }
  }

  private showSuccessSlide() {
    PremiumService.inProgress = false;
    PremiumService.onSuccess = true;
  }

  public order(creditID) {
    this.inProgress();
    return this.inAppPurchase.order(creditID);
  }

  public validate(product, callback) {
    this.inAppPurchase.validator = function (product: IAPProduct, callback) {
      //Verify the transaction data
      const transactionType: PremiumType = this.platform.is("ios")
        ? PremiumType.IOS
        : PremiumType.ANDROID;
      // const transactionReceipt: string = this.platform.is('ios') ? product.transaction.appStoreReceipt : product.transaction.receipt;
      console.log("VALIDANDO O RECIBO");
      this.processValidation(product, callback, { status: "success" });
      //TODO ARRUMAR ISSO
      // this.premiumService.validateReceipt(transactionType, transactionReceipt)
      //   .subscribe(response => {
      //     this.processValidation(product, callback, response);
      //   }, err => {
      //     this.validationError(callback);
      //   });
      console.log("inAppPurchase.validator", JSON.stringify(product));
    }.bind(this);
  }

  public manageSubscriptions() {
    this.inAppPurchase.manageSubscriptions();
  }

  public premiumMode(product) {
    if (product.id) {
      PremiumService.isPremium = true;
      PremiumService.productId = product.id;
    }
  }

  public guestMode() {
    console.log("Guest Mode = Turning off premium features");
    //if(PremiumService.isPremium)this.showAlert('GUEST MODE ACTIVE ' + JSON.stringify( PremiumService.productId));
    //PremiumService.isPremium = false;
    PremiumService.productId = undefined;
  }

  public showUnexpectedErrorMessage() {
    // TODO : Show error dialog
    this.endProgress(false);
    this.showAlert("Purchase Unexpected Error");
  }

  public showPurchaseErrorMessage() {
    // TODO : Show error dialog
    this.showAlert("Purchase Error");
  }

  public showCanceledPurchaseMessage() {
    // TODO : Show error dialog
    this.showAlert(this.translate.instant("PURCHASE_CANCELLED"));
  }

  public showSuccessPurchaseMessage() {
    // TODO : Show success dialog
    this.showAlert("Purchase Success");
  }

  public async showAlert(message) {
    let alert = await this.alertCtrl.create({
      message: message,
      cssClass: "secondary",
      buttons: [
        {
          text: "Ok",
          handler: (data) => {},
        },
      ],
    });

    await alert.present();
  }

  public isPremiumActive() {
    if (this.isSelectedStorePremium()) {
      return true;
    } else {
      return this.isUserPremium(this.modalController, PremiumPage);
    }
  }
}
