import { Component, Input, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { TranslateService } from "@ngx-translate/core";
import { BlingService } from 'src/services/bling.service';
import { Storage } from "@ionic/storage";
import { TinyService } from 'src/services/tiny.service';


interface ModalData {
  orderNumber: string;
  situation: string;
  totalOrder: string;
  itensOders: string;
  obsOrder: string;
  dataOrder: string;
  invoiceOrder: string;
  orderUserName: string;
  orderUserEmail: string;
  orderInstallments: string
  paymentMethod: string;
  orderDiscount: string;
  identityNumber: string;
  orderUserUf: string;
  orderIntegration: string,
  shippingCost: string
  deliveryAddress: any
  billingAddress: string
  userPhone: string
  deliveryService: string
  trackingCode: string
}

@Component({
  selector: 'app-report-list-modal',
  templateUrl: './report-list-modal.page.html',
  styleUrls: ['./report-list-modal.page.scss'],
})
export class ReportListModalPage implements OnInit {
 
 
  public platformData = {}
  public titleTotal = 'Total: R$'
  public emailLink;
  public phoneNumber;
  public whatsAppLink;
  public smsLink;
  public callLink;
  public dialCode;
  public orderNoteSituation;
  public orderNFC;
  public selectedStatus
  public updateOrderTest;
  public orderNumber
  public statusChanged
  public orderTiny
  public orderBling

  public orderData: ModalData = {
    orderNumber: null,
    situation: null,
    totalOrder: null,
    itensOders: null,
    obsOrder: null,
    dataOrder: null,
    invoiceOrder: null,
    orderUserName: null,
    orderUserEmail: null,
    orderInstallments: null,
    paymentMethod: null,
    orderDiscount: null,
    identityNumber: null,
    orderUserUf: null,
    orderIntegration: null,
    shippingCost: null,
    deliveryAddress: null,
    billingAddress: null,
    userPhone: null,
    deliveryService:null,
    trackingCode: null
  }

  @Input() order
  platformDataSituation = {};



  constructor(
    public translate: TranslateService,
    private modalController: ModalController,
    public blingService: BlingService,
    public tinyService: TinyService,
    private storage: Storage,
  ) { }

  ngOnInit() {
    this.getNoteSituation()
    this.getUserContactInfoLinks()
    this.setDataPlatform()

    console.log(this.order, "IUSAHDIUAHD")
  }



  async getTinyValues() {
    let storeName = localStorage.getItem("storeName")
    let agency = localStorage.getItem("agency")
    this.orderTiny = await this.tinyService.getOrder(agency, storeName , this.order.pedido.id)
    console.log(this.orderTiny?.result, "UHSIFHGSOYDGFYUSD")
  }

  getFirstData() {
    return this.platformDataSituation = {
      "situation": {
        "bling": this.order.pedido.situacao,
        "tiny": this.order.pedido.situacao
      },
      "orderNumber": {
        "bling": this.order.pedido.numero,
        "tiny": this.order.pedido.numero
      },
      "orderTotal": {
        "bling": this.order.pedido.totalvenda,
        "tiny": this.order.pedido.valor
      },
    }
  }


  async getNFCfromOrder(serie) {
    let storeName = localStorage.getItem("storeName");
    let agency = localStorage.getItem("agency");
    let number = this.order.pedido.nota.numero 

    const data: any = await this.blingService.getNFCfromOrder(storeName,agency , number, serie)
    this.orderNFC = data.result
  }


  getPlatformeData() {

    return this.platformData = {
      "orderItens": {
        "bling": this.order.pedido.itens,
        "tiny": this.orderTiny?.result.pedido.itens
      },
      "orderObs": {
        "bling": this.order.pedido.observacoes,
        "tiny": this.orderTiny?.result.pedido.obs
      },
      "orderData": {
        "bling": this.order.pedido.data,
        "tiny": this.orderTiny?.result.pedido.data_pedido
      },
      "orderInvoice": {
        "bling": this.order.pedido.nota,
        "tiny": this.orderTiny?.result.pedido.data_pedido
      },
      "orderUserName": {
        "bling": this.order.pedido.cliente?.nome,
        "tiny": this.orderTiny?.result.pedido.cliente?.nome
      },
      "orderUserEmail": {
        "bling": this.order.pedido.cliente?.email,
        "tiny": this.orderTiny?.result.pedido.cliente?.email
      },
      "orderInstallments": {
        "bling": this.order.pedido.parcelas?.length,
        "tiny": this.orderTiny?.result.pedido.parcelas?.length
      },
      "paymentMethod": {
        "bling": this.order.pedido?.parcelas?.[0].parcela.forma_pagamento.descricao,
        "tiny": this.orderTiny?.result.pedido.forma_pagamento
      },
      "orderDiscount": {
        "bling": this.order.pedido.desconto,
        "tiny": this.orderTiny?.result.pedido.valor_desconto
      },
      "identityNumber": {
        "bling": this.order.pedido.cliente?.cnpj,
        "tiny": this.orderTiny?.result.pedido.cliente?.cpf_cnpj
      },
      "orderUserUf": {
        "bling": this.order.pedido.cliente?.uf,
        "tiny": this.orderTiny?.result.pedido.cliente?.uf
      },
      "orderIntegration": {
        "bling": this.order.pedido.tipoIntegracao,
        "tiny": this.orderTiny?.result.pedido.ecommerce?.nomeEcommerce
      },
      "shippingCost": {
        "bling": this.order.pedido.valorfrete,
        "tiny": this.orderTiny?.result.pedido.valor_frete
      },
      "deliveryAddress": {
        "bling": this.order.pedido.transporte?.enderecoEntrega,
        "tiny":  this.orderTiny?.result.pedido?.cliente
      },
      "billingAddress": {
        "bling": this.order.pedido.cliente?.endereco,
        "tiny":  this.orderTiny?.result.pedido?.cliente?.endereco
      },
      "userPhone": {
        "bling": this.order.pedido.cliente?.celular,
        "tiny":  this.orderTiny?.result.pedido?.cliente?.fone
      },
      "deliveryService": {
        "bling": this.order.pedido.transporte?.servico_correios,
        "tiny":  this.orderTiny?.result.pedido?.nome_transportador
      },
      "trackingCode": {
        "bling": this.order.pedido.codigosRastreamento?.codigoRastreamento,
        "tiny":  this.orderTiny?.result.pedido?.codigo_rastreamento
      },
    }
  }

  //this.order.pedido.parcelas[0].parcela.forma_pagamento.descricao

  async setDataPlatform() {
    this.orderData.situation = this.getFirstData()["situation"][localStorage.getItem("storeType")]
    this.orderData.orderNumber = this.getFirstData()["orderNumber"][localStorage.getItem("storeType")]
    this.orderData.totalOrder = this.getFirstData()["orderTotal"][localStorage.getItem("storeType")]

    if (localStorage.getItem("storeType") == "tiny") await this.getTinyValues()

    this.orderData.itensOders = this.getPlatformeData()["orderItens"][localStorage.getItem("storeType")]
    this.orderData.obsOrder = this.getPlatformeData()["orderObs"][localStorage.getItem("storeType")]
    this.orderData.dataOrder = this.getPlatformeData()["orderData"][localStorage.getItem("storeType")]
    this.orderData.orderUserName = this.getPlatformeData()["orderUserName"][localStorage.getItem("storeType")]
    this.orderData.orderUserEmail = this.getPlatformeData()["orderUserEmail"][localStorage.getItem("storeType")]

    // ## Verifica se existe parcelamento ou nao, setando sempre com o valor 1 caso nao tenha parcelamento
    let orderInstallmentsValue = this.getPlatformeData()["orderInstallments"][localStorage.getItem("storeType")] == 0 ? 1 : this.getPlatformeData()["orderInstallments"][localStorage.getItem("storeType")]
    let deliveryAddressValue = this.getPlatformeData()["deliveryAddress"][localStorage.getItem("storeType")]
    

    // ## Verifica se o objeto possui endereco, caso esteja vazio, ele seta como undefined. Isso ajuda para controlar a variavel no html se possui algum objeto no this.orderData.deliveryAddress
    deliveryAddressValue?.endereco != ""  || undefined ? deliveryAddressValue : deliveryAddressValue = undefined

    this.orderData.deliveryAddress = deliveryAddressValue
    this.orderData.orderInstallments = orderInstallmentsValue
    this.orderData.paymentMethod = this.getPlatformeData()["paymentMethod"][localStorage.getItem("storeType")]
    this.orderData.orderDiscount = this.getPlatformeData()["orderDiscount"][localStorage.getItem("storeType")]
    this.orderData.identityNumber = this.getPlatformeData()["identityNumber"][localStorage.getItem("storeType")]
    this.orderData.orderUserUf = this.getPlatformeData()["orderUserUf"][localStorage.getItem("storeType")]
    this.orderData.orderIntegration = this.getPlatformeData()["orderIntegration"][localStorage.getItem("storeType")]
    this.orderData.shippingCost = this.getPlatformeData()["shippingCost"][localStorage.getItem("storeType")]
    this.orderData.billingAddress = this.getPlatformeData()["billingAddress"][localStorage.getItem("storeType")]
    this.orderData.userPhone = this.getPlatformeData()["userPhone"][localStorage.getItem("storeType")]
    this.orderData.deliveryService = this.getPlatformeData()["deliveryService"][localStorage.getItem("storeType")]
    this.orderData.trackingCode = this.getPlatformeData()["trackingCode"][localStorage.getItem("storeType")]
    




    // console.log(this.orderData.deliveryAddress, "ushdiuasgiyodauiysdgiuaysgdiuaygdu")
  }

    getUserContactInfoLinks(){
      let clientName = this.orderData.orderUserName
      this.dialCode = localStorage.getItem("userCountryNumber");
      this.phoneNumber = this.orderData.userPhone;
      this.emailLink = `mailto:${this.orderData.orderUserEmail}`;
      this.callLink = `tel://${this.dialCode}${this.phoneNumber?.replace(
          /[\(\)\.\s-]+/g,
          ""
        )}`;
      this.smsLink = `sms://${this.dialCode}${this.phoneNumber?.replace(
          /[\(\)\.\s-]+/g,
          ""
        )}`;
        this.whatsAppLink =
        `https://api.whatsapp.com/send?phone=${this.dialCode}${this.phoneNumber}&text=Hey,${clientName}`.replace(
          /\s/g,
          ""
        );
    }

    async handleEvent(event: string) {
      this.statusChanged = event
    }

    // async getNFCfromOrder(serie) {

    //   let number = this.order.pedido.nota.numero 

    //   const key = await this.storage.get("blingKey")
    //   const data: any = await this.blingService.getNFCfromOrder(key, number, serie)
    //   this.orderNFC = data.result
    // }

    getNoteSituation(){

      switch(this.order.pedido.nota?.situacao){
        case "1":
          this.orderNoteSituation = "Pendente"
        break
        case "2":
          this.orderNoteSituation = "Emitida"
        break
        case "3":
          this.orderNoteSituation = "Cancelada"
        break
        case "4":
          this.orderNoteSituation = "Enviada - Aguardando Recibo"
        break
        case "5":
          this.orderNoteSituation = "Rejeitada"
        break
        case "6":
          this.orderNoteSituation = "Autorizada"
        break
        case "7":
          this.orderNoteSituation = "Emitida DANFE"
        break
        case "8":
          this.orderNoteSituation = "Registrada"
        break
        case "9":
          this.orderNoteSituation = "Enviada - Aguardando Protocolo"
        break
        case "10":
          this.orderNoteSituation = "Denegada"
        break
        case "11":
          this.orderNoteSituation = "Consultar Situação"
        break
        case "12":
          this.orderNoteSituation = "Bloqueada"
        break
      }
    }

  getFormattedItemAmount(str){
    return Number(str).toFixed();
  }

  getFormattedItemValue(str){
    return Number(str).toFixed(2);
  }

  public getUserCurrency() {
    if (localStorage.getItem("currency") === null) return "$";
    else return localStorage.getItem("currency");
  }

  protected getUserCurrencyLocale() {
    return this.translate.instant("LOCALE_UNICODE");
  }

  async closeModal() {
    await this.modalController.dismiss({status: this.statusChanged, order: this.order.pedido.numero});
  }
}
