import { NgModule } from '@angular/core';
import { PreloadAllModules, NoPreloading, RouterModule, Routes } from '@angular/router';

const routes: Routes = [
  // { path: '', loadChildren: './login/login.module#LoginPageModule' },
  { path: 'login', loadChildren: './login/login.module#LoginPageModule' },
  { path: 'dashboard', loadChildren: './tabs/tabs.module#TabsPageModule' },
  { path: 'report-modal', loadChildren: './report-modal/report-modal.module#ReportModalPageModule' },
  { path: 'agency', loadChildren: './agency/agency.module#AgencyPageModule' },
  { path: 'store', loadChildren: './store/store.module#StorePageModule' },
  { path: 'messages-modal', loadChildren: './messages-modal/messages-modal.module#MessagesModalPageModule' },
  { path: 'assign-store', loadChildren: './assign-store/assign-store.module#AssignStorePageModule' },
  { path: 'create-user-modal', loadChildren: './create-user-modal/create-user-modal.module#CreateUserModalPageModule' },
  { path: 'edit-profile-modal', loadChildren: './edit-profile-modal/edit-profile-modal.module#EditProfileModalPageModule' },
  { path: 'woocommerce-register', loadChildren: './woocommerce-register/woocommerce-register.module#WoocommerceRegisterPageModule' },
  { path: 'response-create-store', loadChildren: './response-create-store/response-create-store.module#ResponseCreateStorePageModule' },
  { path: 'fail-register-page', loadChildren: './fail-register-page/fail-register-page.module#FailRegisterPagePageModule' },
  { path: 'choose-plataform', loadChildren: './choose-plataform/choose-plataform.module#ChoosePlataformPageModule' },
  { path: 'bling-register', loadChildren: './bling-register/bling-register.module#BlingRegisterPageModule' },
  { path: 'analytics', loadChildren: './analytics/analytics.module#AnalyticsPageModule' },
  { path: 'associate-store-modal', loadChildren: './associate-store-modal/associate-store-modal.module#AssociateStoreModalPageModule' },
  { path: 'create-product', loadChildren: './create-product/create-product.module#CreateProductPageModule' },
  { path: 'list-product', loadChildren: './list-product/list-product.module#ListProductPageModule' },
  { path: 'manage-store', loadChildren: './manage-store/manage-store.module#ManageStorePageModule' },
  { path: 'list-cupons', loadChildren: './list-cupons/list-cupons.module#ListCuponsPageModule' },
  { path: 'notification-fix-modal', loadChildren: './notification-fix-modal/notification-fix-modal.module#NotificationFixModalPageModule' },
  { path: 'premium', loadChildren: './premium/premium.module#PremiumPageModule' },
  { path: 'variable-product', loadChildren: './variable-product/variable-product.module#VariableProductPageModule' },   
  { path: 'analytics-modal', loadChildren: './analytics-association-modal/analytics-association-modal.module#AnalyticsAssociationModalPageModule' },   
  { path: 'panels', loadChildren: './bling/panels/panels.module#PanelsPageModule' },
  { path: 'response-create-bling', loadChildren: './response-create-store-bling/response-create-store-bling.module#ResponseCreateStoreBlingPageModule' },
  { path: 'report-list-modal', loadChildren: "./report-list-modal/report-list-modal.module#ReportListModalPageModule"},
  { path: 'bling-list-orders', loadChildren: './bling-list-orders/bling-list-orders.module#BlingListOrdersPageModule'},
  { path: 'tiny-register', loadChildren:"./tiny-register/tiny-register.module#TinyRegisterPageModule"},
  { path: 'tiny-panels', loadChildren: "./tiny/panels/panels.module#PanelsPageModule"},
  { path: 'response-create-tiny', loadChildren: './response-create-store-tiny/response-create-store-tiny.module#ResponseCreateStoreTinyPageModule' },
  { path: 'tiny-list-orders', loadChildren: './tiny/tiny-list-orders/tiny-list-orders.module#TinyListOrdersPageModule'}
  

];
@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {}
