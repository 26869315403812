import { Component, OnInit } from "@angular/core";
import { ModalController } from "@ionic/angular";
import * as moment from "moment";
import { WoocommerceService } from "../../services/woocommerce.service";
import { CallNumber } from "@ionic-native/call-number/ngx";
import { AlertController } from "@ionic/angular";
import { TranslateService } from "@ngx-translate/core";
import { FirebaseService } from "src/services/firebase.service";
import { LoadingProvider } from "../../providers/loading";
import { Router } from "@angular/router";
import { Platform } from "@ionic/angular";
import { TrackingCodeModalPage } from "../tracking-code-modal/tracking-code-modal.page";
import { TrackingCodeModalComponent } from "../components/tracking-code-modal/tracking-code-modal.component";
import { FirebaseAnalyticsService } from "../../services/firebase-analytics.service";
import { Plugins } from "@capacitor/core";
import { PremiumPage } from "src/app/premium/premium.page";

const { Browser } = Plugins;

import { error } from "protractor";
import { PremiumService } from "src/services/premium.service";

@Component({
  selector: "app-report-modal",
  templateUrl: "./report-modal.page.html",
  styleUrls: ["./report-modal.page.scss"],
})
export class ReportModalPage {
  storeName: string;
  dayOfWeek: string;
  dayOfWeekFormatToTexts: string;
  salesReport: Object;
  bestSellers: Object;
  salesIndicators: Object;
  noSales: boolean;
  component: any;
  order: any;
  orderNotes = [];
  isTab2: boolean = false;
  header: string = "";
  whatsAppLink = "";
  paymentMethod;
  firebaseUser: any;
  userEmail: string;
  dialCode: string;
  mapsLink: string;
  smsLink: string;
  callLink: string;
  emailLink: string;
  statusList: any;
  originalStatus: any;
  trackingCode: any;
  public router: Router;
  private newOrderNote: string;
  private isCustomerNote: boolean = false;
  private typeOrderNote = this.translate.instant("TYPE_ORDER_NOTES");
  onClosedData: string = "Wrapped Up!";
  selectedDay: any;
  selectedStatus: any;

  constructor(
    router: Router,
    private modalController: ModalController,
    private woocommerceService: WoocommerceService,
    private callNumber: CallNumber,
    public platform: Platform,
    public alertController: AlertController,
    private translate: TranslateService,
    private firebaseService: FirebaseService,
    private loading: LoadingProvider,
    private trackingCodeModalController: ModalController,
    private analyticsService: FirebaseAnalyticsService,
    private premiumService: PremiumService
  ) {
    this.router = router;
  }

  dayOfWeekTranslate(day: string) {
    if (this.translate.currentLang === "en") {
      if (day.match("seg")) return " last monday";
      if (day.match("terç")) return "last tuesday";
      if (day.match("quar")) return "last wednesday";
      if (day.match("quin")) return "last thursday";
      if (day.match("sex")) return "last friday";
      if (day.match("sáb")) return "last saturday";
      if (day.match("dom")) return "last sunday";
    } else {
      return day;
    }
  }
  headerTranslate(day: string) {
    if (this.translate.currentLang === "en") {
      if (day.match("seg")) return " Monday report";
      if (day.match("terç")) return "Tuesday report";
      if (day.match("quar")) return "Wednesday report";
      if (day.match("quin")) return "Thursday report";
      if (day.match("sex")) return "Friday report";
      if (day.match("sáb")) return "Saturday report";
      if (day.match("dom")) return "Sunday report";
    } else {
      return day;
    }
  }

  setStatusBarColor(status) {
    const statusBar = status.toString().trim();
    if (statusBar == "pending")
      document.documentElement.style.setProperty(`--color1`, "#ffd31a30");
    if (statusBar == "cancelled")
      document.documentElement.style.setProperty(`--color1`, "#ff000059");
    if (statusBar == "failed")
      document.documentElement.style.setProperty(`--color1`, "#ff000059");
    if (statusBar == "processing")
      document.documentElement.style.setProperty(`--color1`, "#6bf1784f");
    if (statusBar == "completed")
      document.documentElement.style.setProperty(`--color1`, "#6bf1784f");
    if (statusBar == "on-hold")
      document.documentElement.style.setProperty(`--color1`, "#ffd31a30");
    if (statusBar == "transporte")
      document.documentElement.style.setProperty(`--color1`, "#ffd31a30");
    if (statusBar == "refunded")
      document.documentElement.style.setProperty(`--color1`, "#ffd31a30");
  }

  async ionViewDidEnter() {
    document.documentElement.style.setProperty(`--color1`, "#ffd31a30");

    this.woocommerceService
      .getStatusList()
      .then((res: any) => {
        this.processStatusList(res);
      })
      .catch((error) => {
        this.statusList = [];
        console.error(
          "Falha ao recuperar lista de status" + JSON.stringify(error)
        );
      });



    this.userEmail = localStorage.getItem("userEmail");
    this.salesReport = null;
    this.bestSellers = null;
    this.salesIndicators = null;

    this.dialCode = localStorage.getItem("userCountryNumber");
    // if (this.firebaseUser == undefined) {
    //   this.firebaseUser = await this.firebaseService.getProfileInfo();
    // }


    //this.dialCode = "+55";


    //let userNumber = this.dialCode.split(" ");
    //this.dialCode = userNumber[0].toString().substr(0);

    //if (this.dialCode.indexOf("+") == -1) this.dialCode = "55"; //Default value for old users

    if (this.component === "Tab4Page") {
      this.analyticsService.trackScreen("Insights - Report Modal");
      let day = undefined;
      if (this.selectedDay == "Today") {
        day = moment();
      } else {
        day = moment().subtract(1, "day");
      }

      this.dayOfWeek = this.weekDay(day.weekday());

      if (day.weekday() == 0 || day.weekday() == 6) {
        this.dayOfWeekFormatToTexts =
          this.dayOfWeekTranslate(this.dayOfWeek) +
          " " +
          this.translate.instant("PASSADO");
      } else {
        this.dayOfWeekFormatToTexts =
          this.dayOfWeekTranslate(this.dayOfWeek) +
          " " +
          this.translate.instant("PASSADA");
      }

      this.getSalesReport(day.format("YYYY-MM-DD"), day.format("YYYY-MM-DD"));
      this.getBestSellers(day.format("YYYY-MM-DD"), day.format("YYYY-MM-DD"));
      this.header =
        this.translate.instant("REPORT_OF") +
        " " +
        this.headerTranslate(this.dayOfWeek);
    }
    if (this.component === "Tab2Page") {

      this.analyticsService.trackScreen("Orders - Order Detail Modal");
      this.trackingCode = this.order.correios_tracking_code;
      this.isTab2 = true;
      this.header = this.translate.instant("ORDER") + " #" + this.order.number;

      this.getOrderNotes(this.order.id);

      let phoneNumber: string = this.getCellPhone();
      let whatsappNumber = phoneNumber;

      const messageText = `Olá ${this.getClientName()} `;
      this.whatsAppLink =
        `https://api.whatsapp.com/send?phone=${this.dialCode}${whatsappNumber}&text=Hey`.replace(
          /\s/g,
          ""
        );
      // this.whatsAppLink = this.whatsAppLink.replace(/\s/g, ""); //removing whitespaces
      this.paymentMethod = this.order.payment_method;
      this.mapsLink = `https://www.google.com.br/maps/?api=1&query=${this.order.shipping.postcode}%20${this.order.shipping.address_1}%20${this.order.shipping.address_2}`;
      this.smsLink = `sms://${this.dialCode}${phoneNumber.replace(
        /[\(\)\.\s-]+/g,
        ""
      )}`;
      this.callLink = `tel://${this.dialCode}${phoneNumber.replace(
        /[\(\)\.\s-]+/g,
        ""
      )}`;
      this.emailLink = `mailto:${this.order.billing.email}`;
      if (this.paymentMethod == "cod")
        this.paymentMethod = this.translate.instant("COD");
    }
  }

  async closeModal() {
    this.orderNotes = [];
    await this.modalController.dismiss(this.onClosedData);
  }

  protected processStatusList(list) {

    this.statusList = list;
    if (this.order) {
      this.originalStatus = this.order.status;
      const object = this.statusList.find(
        (status) => status.slug == this.order.status
      );
      const index = this.statusList.indexOf(object);
      this.selectedStatus = this.statusList[index].name;

      this.setStatusBarColor(this.originalStatus);
    }
  }

  protected getSalesReport(minDate, maxDate) {
    this.woocommerceService
      .getSalesReport(minDate, maxDate, this.storeName)
      .then(
        (salesReport) => {
          let yesterdayDate = moment(minDate);
          let lastWeek = yesterdayDate
            .subtract(7, "day")
            .format("YYYY-MM-DD")
            .toString();

          this.woocommerceService
            .getSalesReport(lastWeek, lastWeek, this.storeName)
            .then((lastSalesReport) => {
              let indicators: { [k: string]: any } = {};
              indicators.total_orders =
                salesReport[0].total_orders - lastSalesReport[0].total_orders;
              indicators.total_sales =
                salesReport[0].total_sales - lastSalesReport[0].total_sales;

              this.salesIndicators = indicators;
              this.salesReport = salesReport[0];
            });
        },
      );
  }

  protected getBestSellers(minDate, maxDate) {
    this.woocommerceService
      .getTopSellersReport(minDate, maxDate, this.storeName)
      .then(
        (topSellersReport) => {
          this.bestSellers = topSellersReport;

          if (Object.keys(this.bestSellers).length == 0) {
            this.noSales = true;
          } else {
            this.noSales = false;
          }
        }
      );
  }

  protected getOrderNotes(orderId) {
    this.orderNotes = undefined;
    this.woocommerceService.getOrderNotes(orderId).then(
      (notes:any) => {
        this.orderNotes = notes;
      }
    );
  }

  protected weekDay(day: number) {
    switch (day) {
      case 0:
        return "domingo";
      case 1:
        return "segunda";
      case 2:
        return "terça";
      case 3:
        return "quarta";
      case 4:
        return "quinta";
      case 5:
        return "sexta";
      case 6:
        return "sábado";
    }
  }

  getClientName() {
    // console.log(JSON.stringify(this.order))
    return this.order.billing.first_name + " " + this.order.billing.last_name;
  }

  getCellPhone() {
    return this.isValidPhone()
      ? this.formatPhone(this.order.billing.cellphone, this.order.billing.phone)
      : this.translate.instant("NOT_REGISTERED");
  }

  formatPhone(phone1: string, phone2: string): string {
    if (phone1 == undefined) phone1 = "";
    return phone1 != ""
      ? "(" +
          phone1.split(" ")[0].replace(/[\(\)\.\s-]+/g, "") +
          ")" +
          " " +
          phone1.split(" ").slice(1).join(" ")
      : phone2;
  }

  isValidPhone() {
    return this.verifyPhone1() || this.verifyPhone2();
  }

  verifyPhone1() {
    return this.order.billing.phone != "";
  }

  verifyPhone2() {
    return this.order.billing.cellphone != "";
  }

  callPhoneNumber() {
    if (this.isValidPhone()) {
      this.callNumber
        .callNumber(this.getCellPhone().replace(/[\(\)\.\s-]+/g, ""), true)
        .then((res) => {})
        .catch(async (err) => {
          const errorAlert = await this.alertController.create({
            message: "",
            header: this.translate.instant("FAILED_CALL_NUMBER"),
            cssClass: "secondary",
            buttons: [
              {
                text: "Ok",
                handler: (data) => {},
              },
            ],
          });
          await errorAlert.present();
        });
    }
  }

  async addTrackingCode() {
    const modal = await this.trackingCodeModalController.create({
      component: TrackingCodeModalComponent,
      cssClass: "trackingCodeModal",
      componentProps: { orderId: this.order.id },
    });

    modal.onDidDismiss().then((data) => {
      if (data.data !== undefined) {
        this.trackingCode = data.data;
      }
    });

    await modal.present();

    // const alert = await this.alertController.create({
    //   header: 'Insert tracking code',
    //   inputs: [
    //     {
    //       name: 'trackingCode',
    //       value: this.trackingCode,
    //       type: 'text',

    //     }
    //   ],
    //   buttons: [
    //     {
    //       text: this.translate.instant('CANCEL'),
    //       role: 'cancel',
    //       handler: data => {
    //       }
    //     },
    //     {
    //       text: this.translate.instant('CONFIRM'),
    //       handler: data => {
    //         this.loading.present();
    //         this.woocommerceService.updateTrackingCode(this.order.id,data.trackingCode).then(() =>{
    //           this.trackingCode = data.trackingCode;
    //           this.loading.dismiss();

    //         }).catch(erro =>{
    //           this.loading.dismiss();
    //         });
    //       }
    //     }
    //   ]
    // });
    // await alert.present()
  }

  async externalIntegration(integration) {
    let plus = "+";
    if (this.dialCode.includes("+")) plus = "";
    else plus = "+";

    let value;
    let buttonName;
    let type;

    switch (integration) {
      case "whatsapp": {
        value = (
          plus +
          this.dialCode +
          this.getCellPhone().replace(/\D/g, "")
        ).replace(/\s/g, "");
        buttonName = "WhatsApp";
        type = "tel";
        break;
      }
      case "sms": {
        value = (
          plus +
          this.dialCode +
          this.getCellPhone().replace(/\D/g, "")
        ).replace(/\s/g, "");
        buttonName = "SMS";
        type = "tel";
        break;
      }
      case "maps": {
        value = `${this.order.shipping.address_1}, ${this.order.shipping.number}, ${this.order.shipping.postcode}`;
        buttonName = this.translate.instant("MAPS");
        type = "text";
        break;
      }
      case "call": {
        value = (
          plus +
          this.dialCode +
          this.getCellPhone().replace(/\D/g, "")
        ).replace(/\s/g, "");
        buttonName = this.translate.instant("CALL");
        type = "tel";
        break;
      }
      default: {
        value = (
          plus +
          this.dialCode +
          this.getCellPhone().replace(/\D/g, "")
        ).replace(/\s/g, "");
        buttonName = "SMS";
        type = "tel";
        break;
      }
    }

    const alert = await this.alertController.create({
      inputs: [
        {
          name: "link",
          value: value,
          placeholder: value,

          type: type,
        },
      ],
      buttons: [
        {
          text: this.translate.instant("CANCEL"),
          role: "cancel",
          handler: (data) => {
          },
        },
        {
          text: buttonName,
          handler: (data) => {
            if (integration == "whatsapp")
              this.openPage(
                `https://api.whatsapp.com/send?phone=${data.link}&text=Hey`
              );
            // window.open(`https://api.whatsapp.com/send?phone=${data.link}&text=Hey`);
            else if (integration == "sms") this.openPage(`sms://${data.link}`);
            else if (integration == "maps"){
              this.openMaps(data.link);
            }
            else if (integration == "call") this.openPage(`tel://${data.link}`);
            else this.openPage(`sms://${data.link}`);
          },
        },
      ],
    });
    await alert.present();
  }

  openMaps(location){
    window.location.href = 'https://www.google.com/maps/search/?api=1&query=' + location;
  }

  openPage(page) {
    window.location.href = page;
  }

  public getUserCurrency() {
    if (localStorage.getItem("currency") === null) return "$";
    else return localStorage.getItem("currency");
  }

  protected getUserCurrencyLocale() {
    return this.translate.instant("LOCALE_UNICODE");
  }

  protected extractUserCurrency(order) {
    let currency = order["currency"];
    localStorage.setItem("currency", currency);
  }

  private getOrderStatus() {
    if (this.statusList != undefined)
      return () => {
        let result = this.statusList.filter((status) => {
          return status.slug === this.order.status;
        });
        if (result[0] != undefined) return result[0].name;
        else return [];
      };
    else return [];
  }

  public openLink(html) {
    let parser = new DOMParser();
    let temp = parser.parseFromString(html, "text/html").documentElement;
    let links = temp.getElementsByTagName("a"),
      hrefs = [];
    for (var i = 0; i < links.length; i++) {
      hrefs.push(links[i].href);
    }

    for (var i = 0; i < hrefs.length; i++) {
      this.openPageExternally(hrefs[i]);
    }
  }

  async openPageExternally(page) {
    if (this.platform.is("capacitor")) {
      await Browser.open({
        presentationStyle: "popover",
        url: page,
      });
    } else {
      window.open(page, "_blank", "location=yes");
    }
  }

  isUserPremium() {
    return this.premiumService.isUserPremium(this.modalController, PremiumPage);
  }

  isSelectedStorePremium() {
    return this.premiumService.isSelectedStorePremium();
  }

  private isPremiumActive() {
    if (this.isSelectedStorePremium()) {
      return true;
    } else {
      return this.isUserPremium();
    }
  }

  async changeStatus(selectedObject) {
    const loadBox = this.loading;
    const confirmationModal = await this.alertController.create({
      message: this.translate.instant("CHANGE_ORDER_STATUS"),
      header: this.translate.instant("SURE_QUESTION"),
      backdropDismiss: false,
      cssClass: "secondary",
      buttons: [
        {
          text: this.translate.instant("CANCEL"),
          handler: (data) => {
            this.cancelStatusChange();
          },
        },
        {
          text: this.translate.instant("CONFIRM"),
          handler: (data) => {
            loadBox.present().then(() => {
              this.woocommerceService
                .updateOrderStatus(this.order.id, selectedObject.slug)
                .then((res) => {
                  this.setStatusBarColor(selectedObject.slug);
                  this.order.status = selectedObject.slug;
                  this.originalStatus = selectedObject.slug;
                  this.onClosedData = "changed";
                  this.getOrderNotes(this.order.id);
                  loadBox.dismiss();
                })
                .catch((err) => {
                  alert(JSON.stringify(err));
                  loadBox.dismiss();
                });
            });
          },
        },
      ],
    });
    confirmationModal.present();
  }

  cancelStatusChange() {
    const object = this.statusList.find(
      (status) => status.slug == this.originalStatus
    );
    const index = this.statusList.indexOf(object);
    setTimeout(() => {
      this.selectedStatus = this.statusList[index].name;
    }, 50);
  }

  private async updateOrderStatus() {
    const selectedObject = this.statusList.find(
      (status) => status.name == this.selectedStatus
    );
    if (selectedObject.slug !== this.originalStatus) {
      if (this.isPremiumActive()) {
        this.changeStatus(selectedObject);
      } else {
        this.cancelStatusChange();
      }
    }
  }

  private async addOrderNote() {
    if (this.isPremiumActive()) {
      this.loading.present();
      this.woocommerceService
        .newOrderNotes(this.order.id, this.newOrderNote, this.isCustomerNote)
        .then(async (response) => {
          this.loading.dismiss();
          const errorAlert = await this.alertController.create({
            message: "",
            backdropDismiss: false,
            header: this.translate.instant("ORDER_NOTE_CREATED"),
            cssClass: "secondary",
            buttons: [
              {
                text: "Ok",
                handler: (data) => {
                  this.cleanOrderNotes();
                },
              },
            ],
          });
          await errorAlert.present();
        })
        .catch(async (error) => {
          this.loading.dismiss();
          const errorAlert = await this.alertController.create({
            message: "",
            header: this.translate.instant("ERROR_ORDER_NOTE"),
            cssClass: "secondary",
            buttons: [
              {
                text: "Ok",
                handler: (data) => {},
              },
            ],
          });
          await errorAlert.present();
        });
    }
  }

  private cleanOrderNotes() {
    this.newOrderNote = "";
    this.isCustomerNote = false;
    this.orderNotes = undefined;
    this.getOrderNotes(this.order.id);
  }

  getAdress1(order) {
    const noShipping =
      order.shipping.address_1 === "" || !order.shipping.address_1;
    const billingAdress = order.billing.address_1
      ? order.billing.address_1
      : "";
    return noShipping ? billingAdress : order.shipping.address_1;
  }

  getAdress2(order) {
    const noShipping =
      order.shipping.address_2 === "" || !order.shipping.address_2;
    const billingAdress = order.billing.address_2
      ? order.billing.address_2
      : "";
    return noShipping ? billingAdress : order.shipping.address_2;
  }

  getAdressNeighborhood(order) {
    const noShipping =
      order.shipping.neighborhood === "" || !order.shipping.neighborhood;
    const billingNeighborhood = order.billing.neighborhood
      ? order.billing.neighborhood
      : "";
    return noShipping ? billingNeighborhood : order.shipping.neighborhood;
  }

  getAdressCity(order) {
    const noShipping = order.shipping.city === "" || !order.shipping.city;
    const billingCity = order.billing.city ? order.billing.city : "";
    return noShipping ? billingCity : order.shipping.city;
  }

  getAdressState(order) {
    const noShipping = order.shipping.state === "" || !order.shipping.state;
    const billingState = order.billing.state ? order.billing.state : "";
    return noShipping ? billingState : order.shipping.state;
  }

  getAddress1Type(order) {
    const noShipping =
      order.shipping.address_1 === "" || !order.shipping.address_1;
    const noBilling =
      order.billing.address_1 === "" || !order.billing.address_1;
    const shipping = this.translate.instant("SHIPPING_ADDRESS");
    const billing = this.translate.instant("BILLING_ADDRESS");
    return noShipping ? (noBilling ? "" : `(${billing})`) : `(${shipping})`;
  }

  getAddress2Type(order) {
    const noShipping =
      order.shipping.address_2 === "" || !order.shipping.address_2;
    const noBilling =
      order.billing.address_2 === "" || !order.billing.address_2;
    const shipping = this.translate.instant("SHIPPING_ADDRESS");
    const billing = this.translate.instant("BILLING_ADDRESS");
    return noShipping ? (noBilling ? "" : `(${billing})`) : `(${shipping})`;
  }
}
