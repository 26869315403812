import { Component, OnInit } from '@angular/core';
import { AlertController, ModalController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { FirebaseService } from 'src/services/firebase.service';

@Component({
  selector: 'app-notification-fix-modal',
  templateUrl: './notification-fix-modal.page.html',
  styleUrls: ['./notification-fix-modal.page.scss'],
})
export class NotificationFixModalPage implements OnInit {
  stepText: string;
  loading: boolean = false;
  step1Finished: boolean = false;
  step2Finished: boolean = false;
  step3Finished: boolean = false;
  stepMessage = {
    "1" : '',
    "2": '',
    "3": '',
  }
  step1Error: boolean = false;
  step2Error: boolean = false;
  step3Error: boolean = false;
  storeName: string = '';

  constructor(public modalController: ModalController,
              public firebaseService: FirebaseService,
              public translate: TranslateService,
              public alertCtrl: AlertController,
              ) { }

  ngOnInit() {
    this.loading = true
    this.storeName = localStorage.getItem('storeName')
    this.stepText = this.translate.instant('CHECKING_FLAGS')
    this.firebaseService.fixNotificationFlags().then((result:any) => {
      this.step1Finished = true
      
      if(result.info === "Notification flags are ok"){
        const message = this.translate.instant('FLAGS_OK')
        this.setStepMessage(message,'1')
      }
      if(result.info === "Fixed Status flag"){
        const message = this.translate.instant('FIXED_STATUS_FLAGS')
        this.setStepMessage(message,'1')
      }
      if(result.info === "Fixed Order flag"){
        const message = this.translate.instant('FIXED_ORDER_FLAGS')
        this.setStepMessage(message,'1')
      }
      if(result.info === "Fixed Order and Status flags"){
        const message = this.translate.instant('FIXED_FLAGS')
        this.setStepMessage(message,'1')
      }
      if(result.info === "user created on store doc with flags"){
        const message = this.translate.instant('FIXED_FLAGS')
        this.setStepMessage(message,'1')
      }
      this.fixWebhook('order')
    })
    .catch(err => {
      const message = 'Unknown error'
      this.setStepMessage(message,'1')
      this.step1Error = true
      this.step1Finished = true
      this.fixWebhook('order')
    })
  }


  setStepMessage(message,step){
    this.stepText = message
    this.stepMessage[step] =  message
  }


  async closeModal(){
    const onClosedData: string = "Wrapped Up!";
    await this.modalController.dismiss(onClosedData);
  }

  fixWebhook(type){
    const orderMessage = this.translate.instant('CHECKING_ORDER_WEBHOOK')
    const statusMessage = this.translate.instant('CHECKING_STATUS_WEBHOOK')
    this.stepText = type === 'order' ? orderMessage : statusMessage
    this.firebaseService.fixWebhook(type).then((result:any) => {
      const statusWebhookCreated = this.translate.instant('STATUS_WEBHOOK_CREATED')
      const orderWebhookCreated = this.translate.instant('ORDER_WEBHOOK_CREATED')
      const webhookOk = this.translate.instant('WEBHOOK_OK')
      const webhookActivated = this.translate.instant('WEBHOOK_ACTIVATED')
      const statusChange = this.translate.instant('STATUS_CHANGE')
      const newOrder = this.translate.instant('NEW_ORDER')
      const step = type === 'order' ? '2' : '3'

      if(result.info.match("webhook created with existing token")){
        const message = type === 'order' ? orderWebhookCreated : statusWebhookCreated
        this.setStepMessage(message,step)
      }
      if(result.info === "Can not create webhook"){
        const message = this.translate.instant('CANT_CREATE_WEBHOOK') + ' (' + type + ')'

        if(type === 'order'){
          this.step2Error = true 
        }
        else {
          this.step3Error = true
        }
        
        this.setStepMessage(message,step)
      }
      if(result.info === "Webhook already exists and store has valid token"){

        const message = type === 'order' ? newOrder + ' - ' + webhookOk : statusChange + ' - ' + webhookOk
        this.setStepMessage(message,step)
      }
      if(result.info === "Webhook activated"){
        const message = type === 'order' ? newOrder + ' - ' + webhookActivated :  statusChange + ' - ' + webhookActivated
        this.setStepMessage(message,step)
      }
      if(type === 'order'){
        this.fixWebhook('status')
        this.step2Finished = true
      }
      else{
        this.step3Finished = true
        this.stepText = this.translate.instant('FINISHED')
        this.loading = false
      }
    })
    .catch(err => {
      const message = this.translate.instant('UNKNOWN_ERROR')
      if(type === 'order'){
        this.fixWebhook('status')
        this.step2Error = true 
        this.step2Finished = true
        this.setStepMessage(message,'2')
      }
      else{
        this.step3Finished = true
        this.step3Error = true 
        this.loading = false
        this.setStepMessage(message,'3')
        this.stepText = this.translate.instant('FINISHED')
      }
    })
  }

  noStepErrors(){
    return !this.step1Error && !this.step2Error && !this.step3Error
  }

  async sendTestNotification(){

    setTimeout( async () => {
      this.firebaseService.sendTestNotification().then(response => {
      })
    }, 3000);

    const alert = await this.alertCtrl.create({
      message: this.translate.instant('NOTIFICATION_SENT'),
      animated: true,
      mode: 'ios',
      buttons: [
          {
              text: 'OK',
              handler: () => {

              }
          },

      ]
    });
    await alert.present();
    this.closeModal()

  }

}
