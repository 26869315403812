import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { BlingService } from 'src/services/bling.service';
import { Storage } from "@ionic/storage";
import { TranslateService } from '@ngx-translate/core';
import { AlertController } from '@ionic/angular';
import { LoadingProvider } from 'src/providers/loading';
import { TinyService } from 'src/services/tiny.service';

@Component({
  selector: 'app-status-orders',
  templateUrl: './status-orders.component.html',
  styleUrls: ['./status-orders.component.scss'],
})


export class StatusOrdersComponent implements OnInit {


  selectedStatus = "";
  @Output() onEvent = new EventEmitter();
  @Input() value
  @Input() orderNumber;
  //@Input() getLastOrders
  public statusList
  constructor(
    public blingService: BlingService,
    public tinyService: TinyService,
    private storage: Storage,
    public translate: TranslateService,
    public alertController: AlertController,
    private loading: LoadingProvider,

  ) { }

  ngOnInit() {

    this.selectedStatus = this.value
    //this.getLastOrders()
    this.setStatus()
  }


  setStatus() {
    if (localStorage.getItem("storeType") == "tiny") {
      return this.statusList = [
        "Em aberto",
        "Aprovado",
        "Faturado",
        "Preparando envio",
        "Enviado",
        "Pronto para envio",
        "Entregue",
        "Não entregue",
        "Cancelado",
      ]
    }
    else {
      return this.statusList = [
        "Atendido",
        "Cancelado",
        "Em aberto",
        "Em andamento",
        "Em digitação",
        "Venda Agenciada",
        "Verificado"
      ]
    }
  }

  async updateStatusSelectorOptionsFn() {
    if (this.selectedStatus != this.value) {

      if (localStorage.getItem("storeType") == "tiny") {
        switch (this.selectedStatus) {
          case "Em aberto":
            return this.openMessage("aberto");
          case "Aprovado":
            return this.openMessage("aprovado");
          case "Preparando envio":
            return this.openMessage("preparando_envio");
          case "Faturado":
            return this.openMessage("faturado");
          case "Pronto para envio":
            return this.openMessage("pronto_envio");
          case "Enviado":
            return this.openMessage("enviado");
          case "Entregue":
            return this.openMessage("entregue");
          case "Não entregue":
            return this.openMessage("nao_entregue");
          case "Cancelado":
            return this.openMessage("cancelado");
        }
      }
      else {
        switch (this.selectedStatus) {
          case "Atendido":
            return this.openMessage(9);
          // this.selectedStatus = "Atendido"
          case "Em aberto":
            return this.openMessage(6);
          case "Cancelado":
            return this.openMessage(12);
          case "Em andamento":
            return this.openMessage(15);
          case "Venda Agenciada":
            return this.openMessage(18);
          case "Em digitação":
            return this.openMessage(21);
          //this.selectedStatus = "Em digitação"
          case "Verificado":
            return this.openMessage(24);
        }
      }


    }
  }

  async openMessage(idStatus) {
    const loadBox = this.loading;
    const confirmationModal = await this.alertController.create({
      message: this.translate.instant("CHANGE_ORDER_STATUS"),
      header: this.translate.instant("SURE_QUESTION"),
      backdropDismiss: false,
      cssClass: "secondary",
      buttons: [
        {
          text: this.translate.instant("CANCEL"),
          handler: (data) => {
            this.cancelStatusChange()
          }
        },
        {
          text: this.translate.instant("CONFIRM"),
          handler: (data) => {
            loadBox.present().then(async () => {
              localStorage.getItem("storeType") == "bling" ? await this.updateOrderStatus(idStatus) : await this.updateOrderStatusTiny(idStatus)
              this.onEvent.emit(true)
              loadBox.dismiss();
            });
          },
        },
      ],
    });
    confirmationModal.present();
  }

  cancelStatusChange() {
    setTimeout(() => {
      return this.selectedStatus = this.value
    }, 100)
  }


  async updateOrderStatusTiny(idSituation) {
    const key = await this.storage.get("tinyApi")
    let storeName = localStorage.getItem("storeName")
    let agency = localStorage.getItem("agency")
    await this.tinyService.updateOrderStatus(agency, storeName ,idSituation, this.orderNumber.id)
  }

  async updateOrderStatus(idSituation) {
    let storeName = localStorage.getItem("storeName")
    let agency = localStorage.getItem("agency")
    let responseApi: any = await this.blingService.updateOrderSituation(storeName, agency, this.orderNumber.numero, idSituation)
  }
}
