import { environment } from '../environments/environment';
import { AuthService } from './auth.service';
import { HttpClient } from '@angular/common/http';
import { Capacitor } from '@capacitor/core';
import { HTTP } from '@awesome-cordova-plugins/http/ngx';
import { Injectable } from '@angular/core';
import { Observable, defer, from } from 'rxjs';


@Injectable({
  providedIn: 'root'
})
export class BackendService {
  constructor(
    private authService: AuthService,
    private httpClient: HttpClient,
    private httpNative: HTTP,
  ) {}

  private buildWoocommerceStoreApiUrl() {
    const url = localStorage.getItem("url");
    let version = localStorage.getItem("version");
    if (version == null || version == undefined) version = "wc/v3";
    const storeApiUrl = url + "/wp-json/wc/" + version.split("/")[1] + "/";
    return storeApiUrl;
  }

  private getRootEndPointForBackend(requestEndpoint) {
    if (localStorage.getItem("storeDirectRequest") == "true" && this.isDirectRequestUrl(requestEndpoint)) {
      return this.buildWoocommerceStoreApiUrl();
    }
    else {
      return environment.backend;
    }
  }

  isDirectRequestUrl(requestEndpoint: any) {
    const woocommercePaths = [
      "reports/sales?",
      "reports/top_sellers?",
      "orders/",
      "orders/notes",
      "orders/status",
      "orders/status",
      "products/",
      "products/categories/",
      "products/tags/",
      "taxes/classes/",
      "coupons"
    ];
    let pathFound = woocommercePaths.find(element => requestEndpoint.includes(element));
    return pathFound != null;
  }

  private removeUrlParams(url: string): string {
    const urlObject = new URL(url);
    urlObject.search = '';
    return urlObject.toString();
  }

  private parseUrlParams(url: string): object {
    const [path, queryString] = url.split('?');
    const params = {};

    if (queryString) {
      queryString.split('&').forEach(param => {
        const [key, value] = param.split('=');
        params[key] = value;
      });
    }

    return params;
  }

  get(requestEndpoint: string, isJsonHeader?: boolean): Observable<any> {
    return defer(() => from(
      new Promise(async (resolve, reject) => {
        const endpoint = this.getRootEndPointForBackend(requestEndpoint);
        let response: Observable<any>;

        if (this.authService.checkAuthToken() || this.authService.getAcessToken() == "") {
          this.authService.setAuthCredentials().then(() => {
            if (Capacitor.getPlatform() == 'web') {
              const header = this.authService.buildHeader("getHttpOptions bearer");
              response = this.httpClient.get(endpoint + requestEndpoint, header);
            }
            else {
              const header = this.authService.buildHeaderNative("getHttpOptions bearer");
              let responseData = this.httpNative.get(this.removeUrlParams(endpoint + requestEndpoint), this.parseUrlParams(endpoint + requestEndpoint), header).then(resp => JSON.parse(resp.data));

              var observableFromPromise = from(responseData);
              response = observableFromPromise;
            }
          });
        }
        else {
          if (Capacitor.getPlatform() == 'web') {
            const header = this.authService.buildHeader("getHttpOptions bearer");
            response = this.httpClient.get(endpoint + requestEndpoint, header);
          }
          else {
            const header = this.authService.buildHeaderNative("getHttpOptions bearer");
            let clearEndpoint = this.removeUrlParams(endpoint + requestEndpoint);
            let parsedParams = this.parseUrlParams(endpoint + requestEndpoint);

            parsedParams = JSON.parse(JSON.stringify(parsedParams));
            let responseData = this.httpNative.get(clearEndpoint, parsedParams, header).then(resp => JSON.parse(resp.data));

            var observableFromPromise = from(responseData);
            response = observableFromPromise;
          }
        }

        response.toPromise()
          .then((res) => resolve(res))
          .catch((err) => reject(err));
      })
    ));
  };

  post(requestEndpoint: string, body: any, isJsonHeader?: boolean): Observable<any> {
    return defer(() => from(
      new Promise(async (resolve, reject) => {
        const endpoint = this.getRootEndPointForBackend(requestEndpoint);
        let response: Observable<any>;

        if (this.authService.checkAuthToken() || this.authService.getAcessToken() == "") {
          this.authService.setAuthCredentials();
        }
        const header = isJsonHeader
          ? this.authService.buildHeader("jsonPostHttpOptions bearer")
          : this.authService.buildHeader("postHttpOptions bearer");

        response = this.httpClient.post(endpoint + requestEndpoint, body, header);

        response.toPromise()
        .then((res) => resolve(res))
        .catch((err) => reject(err));
      })
    ));
  };

  put(requestEndpoint: string, body: any, isJsonHeader?: boolean): Observable<any> {
    return defer(() => from(
      new Promise(async (resolve, reject) => {
        const endpoint = this.getRootEndPointForBackend(requestEndpoint);
        let response: Observable<any>;

        if (this.authService.checkAuthToken() || this.authService.getAcessToken() == "") {
          this.authService.setAuthCredentials();
        }
        const header = isJsonHeader
          ? this.authService.buildHeader("jsonPostHttpOptions bearer")
          : this.authService.buildHeader("postHttpOptions bearer");

        response = this.httpClient.put(endpoint + requestEndpoint, body, header);

        response.toPromise()
        .then((res) => resolve(res))
        .catch((err) => reject(err));
      })
    ));
  }

  delete(requestEndpoint: string, body: any, isJsonHeader?: boolean): Observable<any> {
    return defer(() => from(
      new Promise(async (resolve, reject) => {
        const endpoint = this.getRootEndPointForBackend(requestEndpoint);
        let response: Observable<any>;

        if (this.authService.checkAuthToken() || this.authService.getAcessToken() == "") {
          this.authService.setAuthCredentials();
        }

        response = this.httpClient.delete(endpoint + requestEndpoint, body);

        response.toPromise()
          .then((res) => resolve(res))
          .catch((err) => reject(err));
      })
    ));
  }
}
