import { Injectable } from "@angular/core";
import * as firebase from "firebase/app";
import { AngularFireAuth } from "@angular/fire/auth";
import AuthProvider = firebase.auth.AuthProvider;
import { FirebaseService } from "./firebase.service";
import { GooglePlus } from "@ionic-native/google-plus/ngx";
import { NavController, Platform } from "@ionic/angular";
import { User } from "../app/models/user.interface";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Storage } from "@ionic/storage";
import { HTTP } from "@awesome-cordova-plugins/http/ngx";

@Injectable({
  providedIn: "root",
})
export class AuthService {
  private user: firebase.User;
  private static acessToken: string = "";
  private static expireTime: number = 0;

  constructor(
    public afAuth: AngularFireAuth,
    private gplus: GooglePlus,
    private platform: Platform,
    private navController: NavController,
    private storage: Storage
  ) {
    afAuth.authState.subscribe((user) => {
      this.user = user;
    });
  }

  isLoggedIn(): boolean {
    const user = JSON.parse(localStorage.getItem("user"));
    return user !== null;
  }

  getUser(): User {
    const user = JSON.parse(localStorage.getItem("user"));
    localStorage.setItem("userEmail", user.email);
    return user;
  }

  emailSignIn(email, password) {
    return new Promise<firebase.auth.UserCredential>((res, rej) => {
      firebase
        .auth()
        .signInWithEmailAndPassword(email, password)
        .then((result) => {
          console.log(result);
          const user = result.user;
          user.getIdTokenResult().then((res) => {
            localStorage.setItem("accessToken", res.token);
            localStorage.setItem("expireTime", res.claims.exp);
          });
          this.setAuthCredentialsInLogin(result.user);
          localStorage.setItem("user", JSON.stringify(result.user));
          res(result);
        })
        .catch(function (error) {
          // Handle Errors here.
          var errorCode = error.code;
          var errorMessage = error.message;
          rej(errorCode);
        });
    });
  }

  async removeWcCredentials() {
    await this.storage.remove("key");
    await this.storage.remove("secret");
    await this.storage.remove("blingKey");
    await this.storage.remove("tinyApi");
  }

  async doLogout() {
    //localStorage.removeItem('user');
    let language = localStorage.getItem("language");
    window.localStorage.clear();
    localStorage.setItem("language", language);
    await this.removeWcCredentials();
    return new Promise<void>((resolve, reject) => {
      this.afAuth.auth
        .signOut()
        .then(() => {
          //INVESTIGAR: as vezes nao reconhece o metodo abaixo :
          // this.firebaseService.unsubscribeOnLogOut();
          resolve();
        })
        .catch((error) => {
          console.log(error);
          console.log("ERRO NO LOGOUT");
          reject();
        });
    });
  }

  forgotPassword(emailAddress) {
    return new Promise<void>((resolve, reject) => {
      firebase
        .auth()
        .sendPasswordResetEmail(emailAddress)
        .then((result) => {
          // Email sent.
          console.log(result);
          resolve();
        })
        .catch((error) => {
          console.log(error);
          console.log("ERRO NO LOGOUT");
          reject();
        });
    });
  }

  setAcessToken(acessToken: string) {
    AuthService.acessToken = acessToken;
    //console.log("accessToken: "+acessToken);
    localStorage.setItem("accessToken", acessToken);
  }

  getAcessToken() {
    return localStorage.getItem("accessToken") ? localStorage.getItem("accessToken") : undefined;
  }

  setExpireTime(expireTime) {
    AuthService.expireTime = expireTime;
    localStorage.setItem("expireTime", expireTime);
  }

  getExpireTimeInSeconds() {
    return localStorage.getItem("expireTime") ? Number(localStorage.getItem("expireTime")) : undefined;
  }

  checkAuthToken() {
    //Transform in epoch
    let currentTime = Number(Date.now() / 1000);
    let expireTime = this.getExpireTimeInSeconds();

    if (!expireTime) {
      return false;
    }
    else {
      return expireTime <= currentTime;
    }
  }

  setAuthCredentialsInLogin(user: firebase.User) {
    user.getIdTokenResult().then((response) => {
      this.setAcessToken(response.token);
      this.setExpireTime(response.claims.exp);
    });
  }

  setLocalStorageTokens(response) {
    this.setAcessToken(response.token);
    this.setExpireTime(response.claims.exp);
    localStorage.setItem("accessToken", response.token);
    localStorage.setItem("expireTime", response.claims.exp);
  }

  getNewTokenWithCurrentUser() {
    return new Promise<void>(async (res, rej) => {
      if (this.isLoggedIn()) {
        const firstCurrentUser = firebase.auth().currentUser;
        let count = 0;
        if (firstCurrentUser == undefined || firstCurrentUser == null) {
          const loop = setInterval(async () => {
            console.log('dentro do interval = ' + count);
            count++;
            if (count === 20) {
              console.log("Entrei no count 20");
              this.navController.navigateRoot("/login");
              this.doLogout();
              clearInterval(loop);
            }
            const currentUser = firebase.auth().currentUser;
            if (currentUser) {
              console.log("Entrei no currentUser");

              //encerra loop e pega token
              clearInterval(loop);
              currentUser.getIdTokenResult(true).then((response) => {
                console.log("TOKEN RENOVADO");
                this.setLocalStorageTokens(response);
                res();
              });
            }
          }, 200);

        } else {
          firstCurrentUser.getIdTokenResult(true).then((response) => {
            this.setLocalStorageTokens(response);
            res();
          });
        }
      }
      else {
        console.log('NAO ESTA LOGADO');
        await this.navController.navigateRoot("/login");
        this.doLogout();
      }
    });
  }

  async setAuthCredentials() {
    return new Promise<void>(async (res, rej) => {
      if (this.checkAuthToken() || !this.getAcessToken()) {
        //expirou token do localStorage ou token nao existe no localStorage
        try {
          await this.getNewTokenWithCurrentUser();
          res();
        } catch (error) {
          console.log('ERRO AO SETAR CREDENCIAIS');
          rej();
        }
      }
      else {
        // se existe token valido no localStorage resolve a promise e pode rodar as requisições normalmente
        res();
      }
    });
  }

  buildHeader(headerType: string) {
    const idToken = this.getAcessToken();
    switch (headerType) {
      case "postHttpOptions":
        return {
          headers: new HttpHeaders({
            "Content-Type": "text/plain",
            Authorization: idToken,
          }),
        };
      case "postHttpOptions bearer":
        return {
          headers: new HttpHeaders({
            "Content-Type": "text/plain",
            Authorization: `Bearer ` + idToken,
          }),
        };
      case "jsonPostHttpOptions":
        return {
          headers: new HttpHeaders({
            "Content-Type": "application/json",
            Authorization: idToken,
          }),
        };
      case "jsonPostHttpOptions bearer":
        return {
          headers: new HttpHeaders({
            "Content-Type": "application/json",
            Authorization: `Bearer ` + idToken,
          }),
        };
      case "getHttpOptions":
        return {
          headers: new HttpHeaders({
            "Content-Type": "application/x-www-form-urlencoded",
            Authorization: idToken,
          }),
        };
      case "getHttpOptions bearer":
        return {
          headers: new HttpHeaders({
            "Content-Type": "application/x-www-form-urlencoded",
            Authorization: `Bearer ` + idToken,
          }),
        };
      case "getHttpOptionsNative":
        return {
          headers: new HttpHeaders({
            "Content-Type": "application/x-www-form-urlencoded",
            Authorization: idToken,
          }),
        };
      case "getHttpOptionsNative bearer":
        return {
          headers: new HttpHeaders({
            "Content-Type": "application/x-www-form-urlencoded",
            Authorization: `Bearer ` + idToken,
          }),
        };
    }
  }

  buildHeaderNative(headerType) {
    const idToken = this.getAcessToken();
    switch (headerType) {
      case "getHttpOptions":
        return {
          "Content-Type": "application/x-www-form-urlencoded",
          Authorization: idToken,
        };
    }
  }


}



