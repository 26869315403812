import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { Routes, RouterModule } from '@angular/router';

import { IonicModule } from '@ionic/angular';

import { PremiumPage } from './premium.page';
import { TranslateModule } from '@ngx-translate/core';
import {LottieModule} from 'ngx-lottie';
import player from 'lottie-web';
export function playerFactory() {
  return player;
}
const routes: Routes = [
  {
    path: 'premium',
    component: PremiumPage
  }
];

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    IonicModule,
    LottieModule.forRoot({ player: playerFactory }),
    RouterModule.forChild(routes),
    TranslateModule.forChild()
  ],
  declarations: [PremiumPage],
  exports: [
    PremiumPage
  ]
})
export class PremiumPageModule {}
