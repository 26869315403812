import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { Routes, RouterModule } from '@angular/router';

import { IonicModule } from '@ionic/angular';

import { AssociateStoreModalPage } from './associate-store-modal.page';
import { TranslateModule } from '@ngx-translate/core';
import {LottieModule} from 'ngx-lottie';
import player from 'lottie-web';
export function playerFactory() {
  return player;
}
const routes: Routes = [
  {
    path: 'associateStore',
    component: AssociateStoreModalPage
  }
];

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    IonicModule,
    ReactiveFormsModule,
    LottieModule.forRoot({ player: playerFactory }),
    RouterModule.forChild(routes),
    TranslateModule.forChild(),
  ],
  declarations: [AssociateStoreModalPage]
})
export class AssociateStoreModalPageModule {}
