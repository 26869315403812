import { Component, OnInit } from "@angular/core";
import { FormBuilder, Validators } from "@angular/forms";
import { FirebaseService } from "../../services/firebase.service";
import { WoocommerceService } from "../../services/woocommerce.service";
import {
  Router,
  ActivatedRoute,
  RouterModule,
  Routes,
  NavigationEnd,
} from "@angular/router";
import { LoadingProvider } from "../../providers/loading";
import { AlertController, ModalController, NavParams } from "@ionic/angular";
import { environment } from "../../environments/environment";
import { Platform } from "@ionic/angular";
import { TranslateService } from "@ngx-translate/core";
import { BarcodeScanner } from "@ionic-native/barcode-scanner/ngx";
import { FirebaseAnalyticsService } from "../../services/firebase-analytics.service";
import { Plugins } from "@capacitor/core";

const { Browser } = Plugins;

const registerStoreLogo = environment.registerStoreLogo;

@Component({
  selector: "app-store",
  templateUrl: "./store.page.html",
  styleUrls: ["./store.page.scss"],
})
export class StorePage implements OnInit {
  public router: Router;
  public registerStoreLogo = registerStoreLogo;
  barcodeData: string = "";
  type: string;
  editWoocommerceApi: string;
  editWoocommerceSecret: string;
  constructor(
    private formBuilder: FormBuilder,
    private firestoreService: FirebaseService,
    router: Router,
    public loading: LoadingProvider,
    public alertCtrl: AlertController,
    public platform: Platform,
    private woocommerceService: WoocommerceService,
    public translate: TranslateService,
    public barcodeScanner: BarcodeScanner,
    private modalController: ModalController,
    private navParams: NavParams,
    private analyticsService: FirebaseAnalyticsService
  ) {
    this.router = router;
    if (this.navParams.data) {
      this.type = this.navParams.data.type;
    }
  }
  //todo: criar pattern para url
  registrationForm = this.formBuilder.group({
    storeName: ["", [Validators.required]],
    version: [""],
    storeCategory: ["", [Validators.required]],
    url: [
      "https://",
      [
        Validators.required,
        Validators.pattern(
          "(https?://)?([\\da-z.-]+)\\.([a-z.]{2,16})[/\\w .-]*/?"
        ),
      ],
    ],
    woocommerceSecret: ["", [Validators.required, Validators.minLength(40)]],
    woocommerceApi: ["", [Validators.required, Validators.minLength(40)]],
  });
  v1: any;
  v2: any;
  v3: any;

  categoryList = [
    this.translate.instant("JEWELERY"),
    this.translate.instant("PETSHOP"),
    this.translate.instant("FOOD"),
    this.translate.instant("FASHION"),
    this.translate.instant("AUTOMOTIVE"),
    this.translate.instant("FURNITURE"),
    this.translate.instant("PERFUMERY"),
    this.translate.instant("TOYS"),
    this.translate.instant("COURSES"),
    this.translate.instant("DIGITAL_PRODUCTS"),
    this.translate.instant("OTHERS"),
  ];

  ionViewDidEnter() {
    this.analyticsService.trackScreen(
      "New Store Register - WooCommerce (Old Method)"
    );
  }

  ngOnInit() {
    let user = localStorage.getItem("user");

    this.firestoreService.checkIfStoreExists("teste").then((result) => {
    });
    this.verifyStoreApi("TESTE", "TESTE", "TESTE", "TESTE", "TESTE", "TESTE")
      .then((result) => {
      })
      .catch((err) => {
      });
    this.firestoreService
      .updateClientStore("test@test.com", "test", "test", "test")
      .then((result) => {
      });
    const agency = localStorage.getItem("agency");
    const storeName = localStorage.getItem("storeName");
    this.loading.present().then(() => {
      if (this.isEdit()) {
        this.firestoreService
          .getStoreInfo(storeName, agency)
          .then((store: any) => {

            this.loading.dismiss();

            this.editWoocommerceApi = store.data().woocommerceApi;
            this.editWoocommerceSecret = store.data().woocommerceSecret;

            if (store.exists) {
              this.registrationForm.setValue({
                storeName: store.data().storeName,
                version: this.returnVersion(store.data().version),
                storeCategory: store.data().storeCategory,
                url: store.data().url,
                woocommerceSecret: this.createSecret(
                  store.data().woocommerceSecret
                ),
                woocommerceApi: this.createSecret(store.data().woocommerceApi),
              });
              this.registrationForm.markAsDirty();
              this.registrationForm.updateValueAndValidity();
              this.registrationForm.markAsTouched();
              this.loading.dismiss();
            } else {
              this.loading.dismiss();
            }
          })
          .catch((err) => {
            this.loading.dismiss();
          });
      } else {
        if (localStorage.getItem("tempStore")) {
          const tempStore = JSON.parse(localStorage.getItem("tempStore"));
          this.registrationForm.setValue({
            storeName: tempStore.storeName,
            version: tempStore.version,
            storeCategory: tempStore.category,
            url: "https://",
            woocommerceSecret: "",
            woocommerceApi: "",
          });
        } else {
          this.registrationForm.setValue({
            storeName: "",
            version: "",
            storeCategory: "",
            url: "https://",
            woocommerceSecret: "",
            woocommerceApi: "",
          });
        }
        this.loading.dismiss();
      }
    });

    let userEmail = JSON.parse(user).email;
  }

  isEdit() {
    return this.type === "edit";
  }

  returnVersion(version) {
    if (version.includes("v1")) {
      return "2.6.x to 2.9.x (API v1)";
    } else if (version.includes("v2")) {
      return "3.0.x to 3.4.x (API v2)";
    } else if (version.includes("v3")) {
      return "3.5.x or + (API v3)";
    }
  }

  isRegularUser() {
    return FirebaseService.isAdmin != true;
  }

  hasStore() {
    return localStorage.getItem("storeName") != "";
  }

  backToTabs() {
    this.router.navigateByUrl("dashboard/tabs/tab1").then(() => {
      this.router.initialNavigation();
    });
  }

  async closeModal() {
    const onClosedData: string = "Wrapped Up!";
    await this.modalController.dismiss(onClosedData);
  }

  async openWoocommercePage() {
    let page = "https://docs.woocommerce.com/document/woocommerce-rest-api/";
    if (this.platform.is("capacitor")) {
      await Browser.open({
        presentationStyle: "popover",
        url: page,
      });
    } else {
      window.open(page, "_blank", "location=yes");
    }
  }

  async submit() {
    if (this.registrationForm.valid) {
      const successAlert = await this.alertCtrl.create({
        message: this.translate.instant("CREATE_STORE"),
        header: this.translate.instant("REGISTER_STORE"),
        buttons: [
          {
            text: "Ok",
            handler: (data) => {
              this.closeModal();
            },
          },
        ],
      });

      const errorAlert = await this.alertCtrl.create({
        message: this.translate.instant("FAILED_CREATE_STORE"),
        header: this.translate.instant("REGISTER_STORE"),
        buttons: [
          {
            text: "Ok",
            handler: (data) => {
            },
          },
        ],
      });

      this.loading.present();
      let version = "wc/v3";

      if (this.registrationForm.value.version.includes("v1")) {
        version = "wc/v1";
      } else if (this.registrationForm.value.version.includes("v2")) {
        version = "wc/v2";
      } else if (this.registrationForm.value.version.includes("v3")) {
        version = "wc/v3";
      }


      let url = this.registrationForm.value.url;

      if (url.substr(url.length - 1) != "/") {
        url = url + "/";
      }
      const storeRef = this.firestoreService.checkIfStoreExists(
        this.registrationForm.value.storeName.trim()
      );
      storeRef.then(
        (docSnapshot: any) => {
          if (docSnapshot.exists == "true") {
            //Loja já cadastrada
            //APRESENTAR ALERT
            // errorAlert.present();
            this.showErrorAlert(
              "Error",
              "There is already a store registered with that name. Try changing the store name."
            );
            this.loading.dismiss();
            return;
          } else {
            //Loja não cadastrada
            this.createStore(successAlert, errorAlert, url, version);
          }
        },
        (error) => {
          this.createStore(successAlert, errorAlert, url, version);
        }
      );
    } else {
      if (this.registrationForm.value.storeName == "")
        this.showFormErrorAlert(
          this.translate.instant("ERROR"),
          this.translate.instant("STORE_NAME")
        );
      else if (!this.registrationForm.get("storeCategory").valid)
        this.showFormErrorAlert(
          this.translate.instant("ERROR"),
          this.translate.instant("CATEGORY")
        );
      else if (!this.registrationForm.get("url").valid)
        this.showFormErrorAlert(
          this.translate.instant("ERROR"),
          "Url: " + "" + this.translate.instant("HTTP")
        );
      else if (!this.registrationForm.get("version").valid)
        this.showFormErrorAlert(
          this.translate.instant("ERROR"),
          this.translate.instant("WOOCOMMERCE_VERSION")
        );
      else if (!this.registrationForm.get("woocommerceApi").valid)
        this.showFormErrorAlert(
          this.translate.instant("ERROR"),
          "Woocommerce Api"
        );
      else if (!this.registrationForm.get("woocommerceSecret").valid)
        this.showFormErrorAlert(
          this.translate.instant("ERROR"),
          "Woocommerce Secret"
        );
    }
  }

  createStore(successAlert, errorAlert, url, version) {
    this.verifyStoreApi(
      this.registrationForm.value.storeName.trim(),
      this.registrationForm.value.woocommerceApi.trim(),
      this.registrationForm.value.woocommerceSecret.trim(),
      this.registrationForm.value.storeCategory.trim(),
      url.trim(),
      version.trim()
    )
      .then((resp) => {
        localStorage.setItem(
          "storeName",
          this.registrationForm.value.storeName.trim()
        );
        let user = localStorage.getItem("user");
        let userEmail = JSON.parse(user).email;
        this.firestoreService
          .updateClientStore(
            userEmail,
            this.registrationForm.value.storeName,
            this.registrationForm.value.woocommerceApi,
            this.registrationForm.value.woocommerceSecret
          )
          .then(() => {
            localStorage.removeItem("tempStore");
            this.router.navigateByUrl("dashboard/tabs/tab1").then(() => {
              this.router.initialNavigation();
              successAlert.present();
              this.loading.dismiss();
              this.router.navigateByUrl("dashboard/tabs/tab1").then(() => {
                this.router.initialNavigation();
              });
            });
          })
          .catch((err) => {
            errorAlert.present();
            this.loading.dismiss();
          });
      })
      .catch((err) => {
        if (err != null) {

          if (err.info != undefined) {

            if (
              typeof err.info.includes === "function" &&
              err.info.includes("retrieve")
            ) {

              this.showErrorAlert(
                "Store connection failed",
                this.translate.instant("CHECK_URL_PROTOCOL") + err.info
              );
            } else {
              this.showErrorAlert(
                "Store connection failed",
                this.translate.instant("API_KEY_NOT_WRITABLE")
              );
            }
          } else {
            this.showErrorAlert(
              "Store connection failed",
              this.translate.instant("API_KEY_NOT_WRITABLE")
            );
          }
        } else {
          this.showErrorAlert(
            "Store connection failed",
            this.translate.instant("API_KEY_NOT_WRITABLE")
          );
        }

        const store = {
          storeName: this.registrationForm.value.storeName,
          category: this.registrationForm.value.storeCategory,
          version: version,
        };

        localStorage.setItem("tempStore", JSON.stringify(store));

        this.loading.dismiss();
      });
  }

  verifyStoreApi(
    storeName,
    woocommerceKey,
    woocommerceSecret,
    storeCategory,
    url,
    version
  ) {
    return this.woocommerceService.testWoocommerceStore(
      url,
      version,
      woocommerceKey,
      woocommerceSecret,
      storeName,
      storeCategory
    );
  }

  async editStore() {
    if (this.registrationForm.valid) {
      const successAlert = await this.alertCtrl.create({
        message: this.translate.instant("CREATE_STORE"),
        header: this.translate.instant("REGISTER_STORE"),
        buttons: [
          {
            text: "Ok",
            handler: (data) => {
              this.closeModal();
            },
          },
        ],
      });

      const errorAlert = await this.alertCtrl.create({
        message: this.translate.instant("FAILED_CREATE_STORE"),
        header: this.translate.instant("REGISTER_STORE"),
        buttons: [
          {
            text: "Ok",
            handler: (data) => {
            },
          },
        ],
      });
      this.loading.present();

      let version = "wc/v3";

      if (this.registrationForm.value.version.includes("v1")) {
        version = "wc/v1";
      } else if (this.registrationForm.value.version.includes("v2")) {
        version = "wc/v2";
      } else if (this.registrationForm.value.version.includes("v3")) {
        version = "wc/v3";
      }


      let url = this.registrationForm.value.url;

      if (url.substr(url.length - 1) != "/") {
        url = url + "/";
      }
      this.updateStore(successAlert, errorAlert);
    } else {
      if (this.registrationForm.value.storeName == "")
        this.showFormErrorAlert(
          this.translate.instant("ERROR"),
          this.translate.instant("STORE_NAME")
        );
      if (!this.registrationForm.get("storeCategory").valid)
        this.showFormErrorAlert(
          this.translate.instant("ERROR"),
          this.translate.instant("CATEGORY")
        );
      if (!this.registrationForm.get("url").valid)
        this.showFormErrorAlert(
          this.translate.instant("ERROR"),
          "Url: " + "" + this.translate.instant("HTTP")
        );
      if (!this.registrationForm.get("woocommerceApi").valid)
        this.showFormErrorAlert(
          this.translate.instant("ERROR"),
          "Woocommerce Api"
        );
      if (!this.registrationForm.get("woocommerceSecret").valid)
        this.showFormErrorAlert(
          this.translate.instant("ERROR"),
          "Woocommerce Secret"
        );
    }
  }

  async updateStore(successAlert, errorAlert) {
    let agency = localStorage.getItem("agency");
    let newApiKey = "";
    let newApiSecret = "";

    if (!this.registrationForm.value.woocommerceApi.includes("*")) {
      newApiKey = this.registrationForm.value.woocommerceApi;
    }
    if (!this.registrationForm.value.woocommerceSecret.includes("*")) {
      newApiSecret = this.registrationForm.value.woocommerceSecret;
    }

    this.firestoreService
      .updateStore(
        agency,
        this.registrationForm.value.storeName,
        this.registrationForm.value.storeCategory,
        this.registrationForm.value.url,
        newApiKey,
        newApiSecret
      )
      .then((response) => {
        this.router.navigateByUrl("dashboard/tabs/tab1").then(() => {
          this.router.initialNavigation();
          successAlert.present();
          this.loading.dismiss();
          this.router.navigateByUrl("dashboard/tabs/tab1").then(() => {
            this.router.initialNavigation();
          });
        });
      })
      .catch((err) => {
        errorAlert.present();
        this.loading.dismiss();
      });
  }

  async showErrorAlert(header, msg) {
    const alert = await this.alertCtrl.create({
      message: msg,
      header: header,
      buttons: [
        {
          text: "Ok",
          handler: (data) => {
          },
        },
      ],
    });

    alert.present();
  }

  async showFormErrorAlert(header, msg) {
    const alert = await this.alertCtrl.create({
      message: this.translate.instant("VERIFY") + " " + msg,
      header: header,
      buttons: [
        {
          text: "Ok",
          handler: (data) => {
          },
        },
      ],
    });

    alert.present();
  }

  openScanner() {
    this.barcodeScanner
      .scan()
      .then((barcodeData) => {
        this.barcodeData = barcodeData.text;
        if (this.barcodeData != "" && this.barcodeData != undefined) {
          const consumerKey = this.barcodeData.split("|")[0];
          const consumerSecret = this.barcodeData.split("|")[1];
          this.registrationForm.setValue({
            storeName: this.registrationForm.value.storeName,
            version: this.registrationForm.value.version,
            storeCategory: this.registrationForm.value.storeCategory,
            url: this.registrationForm.value.url,
            woocommerceSecret: consumerSecret,
            woocommerceApi: consumerKey,
          });
          let alertString =
            "consumerKey:" +
            consumerKey +
            " " +
            "consumerSecret:" +
            consumerSecret;
        }
      })
      .catch((err) => {
        alert("This option is available only in our App");
      });
  }

  createSecret(key: string) {
    let secretKey = "";
    for (let i = 0; i < key.length; i++) {
      secretKey = secretKey + "*";
    }
    return secretKey;
  }
}
