import { TargetServerService } from './target-server.service';
import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { AuthService } from "./auth.service";
import { environment } from "src/environments/environment";
import { Observable, of } from "rxjs";
import { Capacitor } from "@capacitor/core";
import { HTTP } from '@awesome-cordova-plugins/http/ngx';
import { defer, from } from 'rxjs';
import { join } from "path";
import { catchError } from "rxjs/operators";
import { BackendService } from './backend.service';

@Injectable({
  providedIn: "root",
})
export class HttpService {

  constructor(
    private authService: AuthService,
    private httpClient: HttpClient,
    private httpNative: HTTP,
    private backendService: BackendService
  ) {}

  public prioritizeBackend: boolean = false;
  public insightsAreAvailable: boolean = false;

  buildWoocommerceStoreApiUrl() {
    const url = localStorage.getItem("url");
    let version = localStorage.getItem("version");
    if (version == null || version == undefined) version = "wc/v3";
    const storeApiUrl = url + "/wp-json/wc/" + version.split("/")[1] + "/";
    return storeApiUrl;
  }

  getRootEndPoint(requestEndpoint) {
    const storeDirectRequest = localStorage.getItem("storeDirectRequest") == "true";
    if (storeDirectRequest && this.isDirectRequestUrl(requestEndpoint)) {
      return this.buildWoocommerceStoreApiUrl();
    } else {
      return environment.endpoint;
    }
  }

  isDirectRequestUrl(requestEndpoint: any) {
    const woocommercePaths = [
      "reports/sales?",
      "reports/top_sellers?",
      "orders/",
      "orders/notes",
      "orders/status",
      "orders/status",
      "products/",
      "products/categories/",
      "products/tags/",
      "taxes/classes/",
      "coupons"
    ];
    let pathFound = woocommercePaths.find(element => requestEndpoint.includes(element));
    return pathFound != null;
  }

  requestBackendOrCloud(
    backendArgs: { method: 'GET' | 'POST' | 'DELETE' | 'PUT', endpoint: string; body?: any; isJsonHeader?: boolean; },
    cloudfnArgs: { method: 'GET' | 'POST' | 'PUT', endpoint: string; body?: any; isJsonHeader?: boolean; },
    jumpToCloudfn?: boolean
  ) {
    // console.log(`Reaching backend or cloud:\nbackend: ${backendArgs.endpoint}\ncloudfn: ${cloudfnArgs.endpoint}\njumpToCloudfn: ${jumpToCloudfn}`);

    let reachBackend: () => Observable<any>;

    switch (backendArgs.method) {
      case 'GET':
        reachBackend = () => this.backendService.get(backendArgs.endpoint, backendArgs.isJsonHeader);
        break;

      case 'POST':
        reachBackend = () => this.backendService.post(backendArgs.endpoint, backendArgs.body, backendArgs.isJsonHeader);
        break;

      case 'PUT':
        reachBackend = () => this.backendService.put(backendArgs.endpoint, backendArgs.body, backendArgs.isJsonHeader);
        break;

      case 'DELETE':
        reachBackend = () => this.backendService.delete(backendArgs.endpoint, backendArgs.body, backendArgs.isJsonHeader);
        break;
      
      default:
        reachBackend = () => {
          console.error(`Tried to ${backendArgs.method} backend at '${backendArgs.endpoint}', but method ${backendArgs.method} is not implemented.`);
          return new Observable();
        }
    }

    let reachCloudfn: () => Observable<any>;

    switch (cloudfnArgs.method) {
      case 'GET':
        reachCloudfn = () => this.get(cloudfnArgs.endpoint, cloudfnArgs.isJsonHeader);
        break;

      case 'POST':
        reachCloudfn = () => this.post(cloudfnArgs.endpoint, cloudfnArgs.body, cloudfnArgs.isJsonHeader);
        break;
      
      case 'PUT':
        reachCloudfn = () => this.put(cloudfnArgs.endpoint, cloudfnArgs.body, cloudfnArgs.isJsonHeader);
        break;
      
      default:
        reachCloudfn = () => {
        console.error(`Tried to ${cloudfnArgs.method} cloudfn at '${cloudfnArgs.endpoint}', but method ${cloudfnArgs.method} is not implemented.`);
        return new Observable();
      }
    }

    if (this.prioritizeBackend && !jumpToCloudfn) {
      // console.log('Backend prioritized');

      return defer(() => from(
        new Promise(async (resolve, reject) => {
          await reachBackend().toPromise()
            .then((backendResponse) => {
              resolve(backendResponse);
            })
            .catch(async (backendError) => {
              await reachCloudfn().toPromise()
                .then((cloudfnResponse) => {
                  console.warn('USED CLOUD FUNCTIONS FALLBACK (next two lines are backend error and cloud response, respectively)');
                  // console.log(backendError);
                  // console.log(cloudfnResponse);

                  resolve(cloudfnResponse);
                })
                .catch((cloudfnError) => {
                  console.error('DOUBLE ERROR FOR BACKEND AND CLOUD FUNCTIONS (next two lines are backend and cloud errors, respectively)');
                  console.log(backendError);
                  console.log(cloudfnError);

                  localStorage.setItem("storeDirectRequest", "false")
                  // const storeDirectRequest = localStorage.getItem("storeDirectRequest") == "true";

                  reject(cloudfnError);
                });
            });

          // console.log(`Responded: ${backendArgs.endpoint}`)
        })
      ));
    }
    else {
      return reachCloudfn();
    }
  }

  get(requestEndpoint: string, isJsonHeader?: boolean): Observable<any> {
    const endpoint = this.getRootEndPoint(requestEndpoint);
    if (
      this.authService.checkAuthToken() ||
      this.authService.getAcessToken() == ""
    ) {
      this.authService.setAuthCredentials().then(() => {
        if (Capacitor.getPlatform() == 'web') {
          const header = this.authService.buildHeader("getHttpOptions");
          return this.httpClient.get(endpoint + requestEndpoint, header);
        }
        else {
          // console.log("NATIVO RECONHECIDO1");
          const header = this.authService.buildHeaderNative("getHttpOptions");
          let responseData = this.httpNative.get(this.removeUrlParams(endpoint + requestEndpoint), this.parseUrlParams(endpoint + requestEndpoint), header).then(resp => JSON.parse(resp.data));

          var observableFromPromise = from(responseData);
          return observableFromPromise;

          // return defer(() => from(this.httpNative.get(this.removeUrlParams(endpoint + requestEndpoint),this.parseUrlParams(endpoint + requestEndpoint), header)));
        }
      });
    } else {
      if (Capacitor.getPlatform() == 'web') {
        const header = this.authService.buildHeader("getHttpOptions");
        return this.httpClient.get(endpoint + requestEndpoint, header);
      }
      else {
        // console.log("NATIVO RECONHECIDO2")
        const header = this.authService.buildHeaderNative("getHttpOptions");
        let clearEndpoint = this.removeUrlParams(endpoint + requestEndpoint);
        let parsedParams = this.parseUrlParams(endpoint + requestEndpoint);
        // console.log("CLEAR ENDPOINT: " + clearEndpoint);
        // console.log("PARSED PARAMS: " + JSON.stringify(parsedParams));
        // console.log("HEADER: " + JSON.stringify(header));

        parsedParams = JSON.parse(JSON.stringify(parsedParams));
        let responseData = this.httpNative.get(clearEndpoint, parsedParams, header).then(resp => JSON.parse(resp.data));

        var observableFromPromise = from(responseData);
        return observableFromPromise;
        // console.log("endpoint: " + clearEndpoint)
        // console.log("parsedParams: " + JSON.stringify(parsedParams))
        // console.log("header: " + JSON.stringify(header));

        // return defer(() => from(this.httpNative.get(clearEndpoint,parsedParams, header).then((res) => {
        //   console.log("Deu CERTO: "+ JSON.stringify(res)); 
        //   (res);
        // })
        //   .catch((error) => {
        //     console.log( "deu erro " + JSON.stringify(error)); 
        //     return error;
        //   })));   
        //   const observable =  defer(() => from(this.httpNative.get(this.removeUrlParams(endpoint + requestEndpoint),this.parseUrlParams(endpoint + requestEndpoint), header)));
        //   observable.pipe(
        //     catchError(error => {
        //       console.error("ERRO RECEBIDO: " + JSON.stringify(error));
        //       return of(null);
        //     })
        //   )
        //   .subscribe(data => {
        //     console.log("DADO RECEBIDO: "+ JSON.stringify(data.data))

        //     let dado = this.removeBackslashes(JSON.stringify(data.data));
        //     console.log("DADO Ajustado: "+ dado);

        //     return dado;

        // });

        //   return observable;


      }
    }
  }

  getFromBackendOrCloud(
    backendArgs: { endpoint: string; isJsonHeader?: boolean; },
    cloudfnArgs: { endpoint: string; isJsonHeader?: boolean; },
    jumpToCloudfn?: boolean
  ): Observable<any> {
    return this.requestBackendOrCloud(
      { method: 'GET', ...backendArgs },
      { method: 'GET', ...cloudfnArgs },
      jumpToCloudfn
    );
  }

  post(
    requestEndpoint: string,
    body?: any,
    isJsonHeader?: boolean
  ): Observable<any> {

    const endpoint = this.getRootEndPoint(requestEndpoint);

    if (
      this.authService.checkAuthToken() ||
      this.authService.getAcessToken() == ""
    ) {
      this.authService.setAuthCredentials();
    }
    const header = isJsonHeader
      ? this.authService.buildHeader("jsonPostHttpOptions")
      : this.authService.buildHeader("postHttpOptions");
    return this.httpClient.post(endpoint + requestEndpoint, body, header);
  }

  postToBackendOrCloud(
    backendArgs: { endpoint: string; body?: any; isJsonHeader?: boolean; },
    cloudfnArgs: { endpoint: string; body?: any; isJsonHeader?: boolean; },
    jumpToCloudfn?: boolean
  ): Observable<any> {
    return this.requestBackendOrCloud(
      { method: 'POST', ...backendArgs },
      { method: 'POST', ...cloudfnArgs }
    );
  }

  put(
    requestEndpoint: string,
    body?: any,
    isJsonHeader?: boolean
  ): Observable<any> {

    const endpoint = this.getRootEndPoint(requestEndpoint);

    if (
      this.authService.checkAuthToken() ||
      this.authService.getAcessToken() == ""
    ) {
      this.authService.setAuthCredentials();
    }
    const header = isJsonHeader
      ? this.authService.buildHeader("jsonPostHttpOptions")
      : this.authService.buildHeader("getHttpOptions");
    return this.httpClient.put(endpoint + requestEndpoint, body, header);
  }

  delete(
    requestEndpoint: string,
    body?: any,
    isJsonHeader?: boolean
  ): Observable<any> {

    const endpoint = this.getRootEndPoint(requestEndpoint);

    if (
      this.authService.checkAuthToken() ||
      this.authService.getAcessToken() == ""
    ) {
      this.authService.setAuthCredentials();
    }
    const header = isJsonHeader
      ? this.authService.buildHeader("jsonPostHttpOptions")
      : this.authService.buildHeader("getHttpOptions");
    return this.httpClient.delete(endpoint + requestEndpoint, header);
  }

  parseUrlParams(url: string): object {
    const [path, queryString] = url.split('?');
    const params = {};

    if (queryString) {
      queryString.split('&').forEach(param => {
        const [key, value] = param.split('=');
        params[key] = value;
      });
    }

    return params;
  }

  removeBackslashes(str) {
    return str.replace(/\\/g, "");
  }

  removeUrlParams(url: string): string {
    const urlObject = new URL(url);
    urlObject.search = '';
    return urlObject.toString();
  }
}
