import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { Routes, RouterModule } from '@angular/router';

import { IonicModule } from '@ionic/angular';
import { TranslateModule } from '@ngx-translate/core';
import { ReportModalPage } from './report-modal.page';
import { TrackingCodeModalComponent } from '../components/tracking-code-modal/tracking-code-modal.component'
import { ItensOrderCardComponent } from '../components/itens-order-card/itens-order-card.component';
import  {  NgxProgressiveImgLoaderModule  }  from  'ngx-progressive-img-loader';
import { LottieModule } from 'ngx-lottie';
import player from 'lottie-web';

// Note we need a separate function as it's required
// by the AOT compiler.
export function playerFactory() {
  return player;
}

const routes: Routes = [
  {
    path: 'report',
    component: ReportModalPage
  }
];

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    IonicModule,
    LottieModule.forRoot({ player: playerFactory }),
    NgxProgressiveImgLoaderModule,
    RouterModule.forChild(routes),
    TranslateModule.forChild()
  ],
  entryComponents: [TrackingCodeModalComponent,ItensOrderCardComponent],
  declarations: [ReportModalPage,TrackingCodeModalComponent,ItensOrderCardComponent],
  exports: [
    ReportModalPage
  ]
})
export class ReportModalPageModule {}
