import {Injectable} from "@angular/core";
import "rxjs/add/operator/map";
import { LoadingController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';

@Injectable()
export class LoadingProvider {

  // private loading: any;
  //
  //
  // constructor(public loadingCtrl: LoadingController) {
  //   this.loading =  this.loadingCtrl.create({
  //       message: 'Carregando',
  //       spinner: 'dots'
  //   });
  // }
  //
  //   async present(message?){
  //   if (!message)
  //     message = 'Carregando';
  //   this.loading = await this.loadingCtrl.create({
  //       message: message,
  //       spinner: 'dots',
  //   });
  //       await this.loading.present();
  // }
  //
  //   async dismiss(){
  //   return await this.loading.dismiss();
  // }

    isLoading = false;

    constructor(public loadingController: LoadingController, private translate: TranslateService) { }

    async present() {
        this.isLoading = true;
        return await this.loadingController.create({
            message: this.translate.instant('LOADING'),
                  spinner: 'dots',
                  mode: 'ios',
                  cssClass: 'loader-style',
                  translucent: true
            // duration: 5000,
        }).then(a => {
            a.present().then(() => {
                console.log('presented');
                if (!this.isLoading) {
                    a.dismiss().then(() => console.log('abort presenting'));
                }
            });
        });
    }

    async presentWithMessage(message) {
        this.isLoading = true;
        return await this.loadingController.create({
            message: message,
                  spinner: 'dots',
                  mode: 'ios',
                  cssClass: 'loader-style',
                  translucent: true
            // duration: 5000,
        }).then(a => {
            a.present().then(() => {
                console.log('presented');
                if (!this.isLoading) {
                    a.dismiss().then(() => console.log('abort presenting'));
                }
            });
        });
    }

    async dismiss() {
        this.isLoading = false;
        return await this.loadingController.dismiss().then(() => console.log('dismissed'));
    }
}
