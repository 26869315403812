import {
  Component,
  ElementRef,
  EventEmitter,
  OnInit,
  Output,
  ViewChild,
} from "@angular/core";
import { FormBuilder, Validators, FormControl } from "@angular/forms";
import { ModalController, AlertController } from "@ionic/angular";
import { FirebaseService } from "../../services/firebase.service";
import { LoadingProvider } from "../../providers/loading";
import { countries } from "../../assets/countries.js";
import { FirebaseAnalyticsService } from "../../services/firebase-analytics.service";

@Component({
  selector: "app-edit-profile-modal",
  templateUrl: "./edit-profile-modal.page.html",
  styleUrls: ["./edit-profile-modal.page.scss"],
})
export class EditProfileModalPage implements OnInit {
  @ViewChild("filePicker") filePickerRef: ElementRef<HTMLInputElement>;
  @Output() profilePictureEvent = new EventEmitter();

  public defaultDialCode = "+55";
  public userEmail: string;
  public userName: string;
  public userNumber: string;
  public userCountry: string = "";
  public userRole: string = "";
  public countriesList: Array<Object> = countries;
  public selectedImage: string;
  public imageUrl: string;
  public pickedFile: File;
  public profilePicture: any = localStorage.getItem("profilePicture");
  public changePicture: boolean = false;

  firebaseUser: any;

  constructor(
    private formBuilder: FormBuilder,
    public modalController: ModalController,
    private analyticsService: FirebaseAnalyticsService,
    private firebaseService: FirebaseService,
    public loading: LoadingProvider,
    public alertCtrl: AlertController
  ) {}

  ionViewDidEnter() {
    this.analyticsService.trackScreen("Profile - Edit Profile Modal");
  }

  async ngOnInit() {
    let userEmail = localStorage.getItem("userEmail");
    if (this.profilePicture != null) {
      this.imageUrl = this.profilePicture;
    }
    this.loading.present();

    this.firebaseService.getProfileInfo().then((response) => {
      this.userName = response["_fieldsProto"]["userName"]["stringValue"];
      this.userEmail = response["_fieldsProto"]["userEmail"]["stringValue"];
      this.userNumber = response["_fieldsProto"]["userNumber"]["stringValue"];

      if (this.profilePicture == null) {
        this.profilePicture = response["_fieldsProto"].hasOwnProperty(
          "profilePicture"
        )
          ? response["_fieldsProto"]["profilePicture"]["stringValue"]
          : "";
        if (this.profilePicture == "") {
          this.imageUrl = "";
        }
      }

      this.userCountry = response["_fieldsProto"].hasOwnProperty("userCountry")
        ? response["_fieldsProto"]["userCountry"]["stringValue"]
        : "";

      this.loading.dismiss();
    });
  }

  async closeModal() {
    const onClosedData: string = "Wrapped Up!";
    await this.modalController.dismiss({
      changePicture: this.changePicture,
      imageUrl: this.imageUrl,
    });
  }

  getNumber(event) {
  }

  hasError(event) {
  }

  telInputObject(event) {
  }

  onCountryChange(event) {
    this.defaultDialCode = "+" + event.dialCode;
  }

  getDefaultLanguage() {
    return localStorage.getItem("language") == "ptBR" ? "br" : "us";
  }

  onPickImage() {
    this.filePickerRef.nativeElement.click();
  }

  onFileChosen(event: Event) {
    this.pickedFile = (event.target as HTMLInputElement).files[0];

    if (!this.pickedFile) {
      return;
    }
    const fileReader = new FileReader();
    fileReader.onload = () => {
      const dataUrl = fileReader.result.toString();
      this.selectedImage = dataUrl;
    };
    fileReader.readAsDataURL(this.pickedFile);
  }

  editProfile() {
    const agency = localStorage.getItem("agency");
    this.loading.present();

    if (this.pickedFile != undefined) {
      this.firebaseService
        .uploadImage(this.pickedFile,"profile")
        .then((response) => {
          this.imageUrl = response["imageUrl"];
          this.changePicture = true;
          localStorage.setItem("profilePicture", this.imageUrl);
          this.firebaseService
            .updateProfile(
              this.userEmail,
              agency,
              this.userName,
              this.userNumber,
              this.imageUrl,
              this.userCountry,
              this.userRole
            )
            .then(async (response) => {
              localStorage.setItem("userName", this.userName);
              const sucessAlert = await this.alertCtrl.create({
                subHeader: "Perfil editado com sucesso !",
                buttons: [
                  {
                    text: "Ok",
                    handler: () => {
                      this.closeModal();
                    },
                  },
                ],
              });
              this.loading.dismiss();
              await sucessAlert.present();
            })
            .catch(async (error) => {
              const errorAlert = await this.alertCtrl.create({
                subHeader:
                  "Oops, something went wrong with your profile update, try again later",
                buttons: ["Ok"],
              });
              this.loading.dismiss();
              await errorAlert.present();
            });
        })
        .catch(async (error) => {
          this.loading.dismiss();
          const errorAlert = await this.alertCtrl.create({
            subHeader:
              "Oops, something went wrong with your iamge upload, try again later",
            buttons: ["Ok"],
          });

          this.loading.dismiss();
          await errorAlert.present();
        });
    } else {
      this.firebaseService
        .updateProfile(
          this.userEmail,
          agency,
          this.userName,
          this.userNumber,
          this.imageUrl,
          this.userCountry,
          this.userRole
        )
        .then(async (response) => {
          localStorage.setItem("userName", this.userName);
          this.loading.dismiss();
          const sucessAlert = await this.alertCtrl.create({
            subHeader: "Perfil editado com sucesso !",
            buttons: [
              {
                text: "Ok",
                handler: () => {
                  this.closeModal();
                },
              },
            ],
          });
          this.loading.dismiss();
          await sucessAlert.present();
        })
        .catch(async (error) => {
          const errorAlert = await this.alertCtrl.create({
            subHeader:
              "Oops, something went wrong with your profile update, try again later",
            buttons: ["Ok"],
          });
          this.loading.dismiss();
          await errorAlert.present();
        });
    }
  }
}
