import { Component, Input, OnInit } from "@angular/core";
import { FormBuilder, Validators } from "@angular/forms";
import { AlertController, ModalController } from "@ionic/angular";
import { TranslateService } from "@ngx-translate/core";
import { LoadingProvider } from "src/providers/loading";
import { FirebaseService } from "src/services/firebase.service";
import { FirebaseAnalyticsService } from "../../services/firebase-analytics.service";
import { LottieModule } from "ngx-lottie";
import player from "lottie-web";
import { AppComponent } from "../app.component";
export function playerFactory() {
  return player;
}

@Component({
  selector: "app-associate-store-modal",
  templateUrl: "./associate-store-modal.page.html",
  styleUrls: ["./associate-store-modal.page.scss"],
})
export class AssociateStoreModalPage {
  @Input() storeName: string;
  @Input() isList: boolean;

  private selectedPermissionsList = [];
  public permissionsList = AppComponent.permissionsList;
  storeList =  [];
  load = true;
  loadStoreUsers = true;
  storeUsers = [];
  adminStoreName: string = "";
  adminAssociation: boolean = false;
  public lottieSlideAddUser: any;
  public lottieSlideUsers: any;
  public lottieSlideSearching: any;

  constructor(
    private modalController: ModalController,
    public formBuilder: FormBuilder,
    private firestoreService: FirebaseService,
    public loading: LoadingProvider,
    public alertCtrl: AlertController,
    public translate: TranslateService,
    private analyticsService: FirebaseAnalyticsService
  ) {
    LottieModule.forRoot({ player: playerFactory });
    this.lottieSlideAddUser = {
      path: "../../assets/animations/add-user.json",
      autoplay: true,
      loop: true,
    };
    this.lottieSlideUsers = {
      path: "../../assets/animations/users.json",
      autoplay: true,
      loop: false,
    };

    this.lottieSlideSearching = {
      path: "../../assets/animations/searching-users.json",
      autoplay: true,
      loop: true,
    };
    if (this.storeName != "" && this.storeName != undefined) {
      this.getStoreUserList();
    } else {
      if (this.isAdmin()) {
        this.adminStoreName = localStorage.getItem("storeName");
        this.adminAssociation = true;
        this.load = false;
      } else {
        this.getStores();
      }
    }
  }

  associateStoreForm = this.formBuilder.group({
    storeName: ["", [Validators.required]],
    userEmail: [
      "",
      [
        Validators.required,
        Validators.pattern(
          "^[A-Za-z0-9._%+-]+@[A-Za-z0-9._%+-]{2,}[.][A-Za-z]{2,}$"
        ),
      ],
    ],
  });

  associateStoreRetryCount = 0;
  getStoresRetryCount = 0;
  getStoreUsersRetryCount = 0;

  isAdmin() {
    if (FirebaseService.isAdmin) {
      return true;
    } else return false;
  }

  ionViewDidEnter() {

    this.analyticsService.trackScreen("Associate Store");

    this.getStoreUserList();
    this.adminStoreName = this.storeName;
    this.adminAssociation = true;
    this.load = false;
    // if(this.isList == true){
    //   this.getStoreUserList()

    // }else{
    //   this.adminStoreName = this.storeName
    //   this.adminAssociation = true
    //   this.load = false;

    //   // if(this.isAdmin()){
    //   //   this.adminStoreName = localStorage.getItem('storeName')
    //   //   this.adminAssociation = true
    //   //   this.load = false
    //   //   this.loading.dismiss();
    //   // }else {
    //   //   this.getStores()
    //   //   this.loading.dismiss();
    //   // }
    // }
  }

  getStoreUserList() {
    this.loadStoreUsers = true;
    // this.loading.present();
    this.firestoreService
      .getStoreUserList(this.storeName)
      .then((res: any) => {
        let users;
        this.storeUsers = [];
        if (res["result"]){
          res["result"].forEach(user => {
            let storeUser:any = {};
            
            storeUser.userEmail = user['_fieldsProto'].userEmail.stringValue;
            storeUser.permission = [];
            if (user["_fieldsProto"]) {
              if (user["_fieldsProto"].permission){
                if (user["_fieldsProto"].permission.valueType == "stringValue"){
                  storeUser.permission.push(this.translate.instant(user["_fieldsProto"].permission.stringValue));
                }
                else if (user["_fieldsProto"].permission.valueType == "arrayValue"){
                  user["_fieldsProto"].permission.arrayValue.values.forEach(element => {
              
                      storeUser.permission.push("\t" + this.translate.instant(element.stringValue));
                      //storeUser.permission.push("\n");
                    
                  });  
                }
              } else {
                storeUser.permission.push(this.translate.instant("DEPRECATED_PERMISSION"));
              }
            }
            this.storeUsers.push(storeUser);
          });
        } 
        
        
        //this.storeUsers = res.result;
        this.loadStoreUsers = false;
        
        // this.loading.dismiss();
      })
      .catch(async (err) => {
        console.error(err);
        if (this.getStoreUsersRetryCount < 3) {
          this.getStoreUsersRetryCount++;
          console.error("Retry Count: "+this.getStoreUsersRetryCount);

          this.getStoreUserList();
        }
      });
  }

  async closeModal() {
    await this.modalController.dismiss();
  }

  showAddUserScreen() {
    this.isList = false;
  }

  goBackToList() {
    this.isList = true;
  }

  getStores() {
    const agency = localStorage.getItem("agency");
    this.firestoreService
      .getUserStores(agency)
      .then((result: any) => {
        this.load = false;
        if (result.res != "empty collection") {
          this.storeList = result;
        }
      })
      .catch(async (err) => {
        if (this.getStoresRetryCount < 3) {
          this.getStoresRetryCount++;

          this.getStores();
        }
      });
  }

  getStoreName() {
    if (this.adminAssociation) {
      this.associateStoreForm.setValue({
        storeName: this.adminStoreName,
        userEmail: this.associateStoreForm.value.userEmail,
      });
      return this.adminStoreName;
    } else {
      return this.associateStoreForm.value.storeName;
    }
  }

  submitForm() {
    const storeName = this.getStoreName();
    if (!this.associateStoreForm.valid) {
      if (!this.associateStoreForm.get("storeName").valid) {
        this.presentInvalidFieldAlert(
          this.translate.instant("INVALID_STORENAME")
        );
      } else {
        this.presentInvalidFieldAlert(
          this.translate.instant("INVALID_USEREMAIL")
        );
      }
    } else {

      if(this.selectedPermissionsList.length == 0){
        this.presentInvalidFieldAlert(
          this.translate.instant("EMPTY_PERMISSIONS")
        );
      } else{
        const load = this.loading;
        const userEmail = this.associateStoreForm.value.userEmail;
        load.present();
        this.firestoreService
          .associateStore(this.associateStoreForm.value.userEmail, storeName, this.selectedPermissionsList)
          .then((response: any) => {
            load.dismiss();
            if (
              response.result ==
              "the user already exists, and the store association was successful"
            ) {
              //ja registrado
              this.presentSuccessAlert(
                this.translate.instant("USER_ASSOCIATED") + ": " + userEmail
              );
              this.isList = true;
              this.getStoreUserList();
            } else {
              //novo usuario
              if (response.result == "User not storeowner") {
                this.presentErrorAlert(this.translate.instant("NOT_STOREOWNER"));
              } else {
                this.presentSuccessAlert(
                  this.translate.instant("NEW_USER_ASSOCIATED") + ": " + userEmail
                );
                this.isList = true;
                this.getStoreUserList();
              }
            }
          })
          .catch(async (error) => {
            load.dismiss();
            if (this.associateStoreRetryCount < 3) {
              this.associateStoreRetryCount++;
  
              this.submitForm();
            }
          });
      }
      
    }
  }

  async presentSuccessAlert(message) {
    const alert = await this.alertCtrl.create({
      header: this.translate.instant("SUCCESS_UPDATE_STORE"),
      message: message,
      animated: true,
      mode: "ios",
      buttons: [
        {
          text: "OK",
          handler: () => {
          },
        },
      ],
    });
    await alert.present();
  }

  async presentErrorAlert(message) {
    const alert = await this.alertCtrl.create({
      header: "Error",
      message: message,
      animated: true,
      mode: "ios",
      buttons: [
        {
          text: "OK",
          handler: () => {
          },
        },
      ],
    });
    await alert.present();
  }

  async presentInvalidFieldAlert(message) {
    const alert = await this.alertCtrl.create({
      header: this.translate.instant("INVALID_FIELD"),
      message: message,
      animated: true,
      mode: "ios",
      buttons: [
        {
          text: "OK",
          handler: () => {
          },
        },
      ],
    });
    await alert.present();
  }

  async disassociateUser(userEmail) {
    const load = this.loading;

    const alert = await this.alertCtrl.create({
      header: this.translate.instant("SURE_QUESTION"),
      message:
        this.translate.instant("CANCEL_DISASSOCIATE") + " - " + this.storeName,
      animated: true,
      mode: "ios",
      buttons: [
        {
          text: this.translate.instant("CANCEL"),
          handler: () => {},
        },
        {
          text: this.translate.instant("CONFIRM"),
          handler: () => {
            load.present();
            this.firestoreService
              .disassociateStore(userEmail, this.storeName)
              .then(async (response: any) => {
                load.dismiss();

                if (response.result == "User not storeowner") {
                  this.presentErrorAlert(
                    this.translate.instant("NOT_STOREOWNER")
                  );
                } else {
                  this.presentSuccessAlert(
                    this.translate.instant("DISASSOCIATE_SUCCESS")
                  );
                  this.isList = true;
                  this.getStoreUserList();
                }
              })
              .catch((err) => {
                load.dismiss();
              });
          },
        },
      ],
    });
    await alert.present();
  }



  async setSelectedPermissionsList(event) {
    


          // Create an object to describe the data in the query string
      var query = new URLSearchParams();

      // Add the languages
      this.selectedPermissionsList.forEach(function (permission) {
          query.append("permission", permission);
      });

    // Explicitly convert to a string

    let params = new URLSearchParams(query.toString());


     // "foo"

    // let permissionListQueryParam = new URLSearchParams(this.selectedPermissionsList.toString()).toString();



    // const permissionBooleanArray = [];
    // const count = 0;


  }

}
