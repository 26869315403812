import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../environments/environment';
import { AngularFireAuth } from '@angular/fire/auth';
import * as firebase from 'firebase/app';
import { HttpService } from './http.service';
import { Storage } from "@ionic/storage";
import { FirebaseService } from './firebase.service';

const endpoint = environment.endpoint;

@Injectable({
  providedIn: 'root'
})
export class BlingService {
  constructor(
    private httpClient: HttpClient,
    public afAuth: AngularFireAuth,
    private httpService: HttpService, 
    private firebaseServices: FirebaseService,
    private storage: Storage,
  ) {}

  httpOptions = {
    headers: new HttpHeaders({

    })
  };

  async getDoneOrders(storeName, agency, startDate, endDate, idSituation, page: number = 0) {
    //content-type': 'application/json
    return new Promise((res, rej) => {
      this.httpService.getFromBackendOrCloud(
        { endpoint: `bling/orders?storeName=${storeName}&agency=${agency}&beginDate=${startDate}&endDate=${endDate}&idSituacao=${idSituation}` },
        { endpoint: `blingFunctions-blingGetOrders?storeName=${storeName}&agency=${agency}&beginDate=${startDate}&endDate=${endDate}&idSituacao=${idSituation}` }
      )
        .toPromise()
        .then((response: any) => {
          res(response);
        }).catch(error => {
          rej(error);
        });
    });
  }

  async updateOrderSituation(storeName, agency, orderNumber, idSituation) {
    //content-type': 'application/json
    return new Promise((res, rej) => {
      this.httpService.getFromBackendOrCloud(
        { endpoint: `bling/update-order?storeName=${storeName}&agency=${agency}&orderNumber=${orderNumber}&idSituation=${idSituation}` },
        { endpoint: `blingFunctions-blingUpdateOrder?storeName=${storeName}&agency=${agency}&orderNumber=${orderNumber}&idSituation=${idSituation}` }
      )
        .toPromise()
        .then((response: any) => {
          res(response);
        }).catch(error => {
          rej(error);
        });
    });
  }

  async getAllOrders(key, startDate, endDate) {
    //content-type': 'application/json
    return new Promise((res, rej) => {
      this.httpService.getFromBackendOrCloud(
        { endpoint: `bling/all-orders?apiKey=${key}&beginDate=${startDate}&endDate=${endDate}` },
        { endpoint: `blingFunctions-blingGetAllOrders?apiKey=${key}&beginDate=${startDate}&endDate=${endDate}` },
        true
      )
        .toPromise()
        .then((response: any) => {
          res(response);
        }).catch(error => {
          rej(error);
        });
    });
  }

  async getLastOrders(storeName, agency, startDate, endDate) {
    //content-type': 'application/json
    return new Promise((res, rej) => {
      this.httpService.getFromBackendOrCloud(
        { endpoint: `bling/last-orders?storeName=${storeName}&agency=${agency}&beginDate=${startDate}&endDate=${endDate}` },
        { endpoint: `blingFunctions-blingGetLastOrders?storeName=${storeName}&agency=${agency}&beginDate=${startDate}&endDate=${endDate}` }
      )
        .toPromise()
        .then((response: any) => {

          res(response);
        }).catch(error => {
          rej(error);
        });
    });
  }

  async getNFCfromOrder(storeName, agency, number, serie) {
    //content-type': 'application/json
    return new Promise((res, rej) => {
      const baseUrl = `blingFunctions-blingGetNFCfromOrder?storeName=${storeName}&agency=${agency}&number=${number}&serie=${serie}`;
      let requestEndpoint = baseUrl;
      this.httpService.get(requestEndpoint).toPromise().then((response: any) => {
        //console.log("==-=-=-NOTA DO PEDIDO=-=-=-=-", response);
        res(response);
      }).catch(error => {
        rej(error);
      });
    });
  }

  async getDoneNFC(storeName, agency, situation, tipo, startDate, endDate) {
    //content-type': 'application/json
    return new Promise((res, rej) => {
      this.httpService.getFromBackendOrCloud(
        { endpoint: `bling/nfc?&storeName=${storeName}&agency=${agency}&beginDate=${startDate}&endDate=${endDate}&situation=${situation}&type=${tipo}` },
        { endpoint: `blingFunctions-blingGetNFC?storeName=${storeName}&agency=${agency}&beginDate=${startDate}&endDate=${endDate}&situation=${situation}&type=${tipo}` }
      )
        .toPromise()
        .then((response: any) => {
          //console.log("==-=-=-NOTAS FISCAIS=-=-=-=-", response);
          res(response);
        }).catch(error => {
          rej(error);
        });
    });
  }

  async getDoneNFCe(storeName, agency, situation, startDate, endDate) {
    //content-type': 'application/json
    return new Promise((res, rej) => {
      this.httpService.getFromBackendOrCloud(
        { endpoint: `bling/nfces?storeName=${storeName}&agency=${agency}&stringBeginDate=${startDate}&stringEndDate=${endDate}&situation=${situation}` },
        { endpoint: `blingFunctions-blingGetNFCES?storeName=${storeName}&agency=${agency}&stringBeginDate=${startDate}&stringEndDate=${endDate}&situation=${situation}` }
      )
        .toPromise()
        .then((response: any) => {
          res(response);
        }).catch(error => {
          rej(error);
        });
    });
  }

  isValidCurrentUser(currentUser) {
    return currentUser != undefined && currentUser != null && currentUser != 'undefined';
  }

  isValidToken(token) {
    return token != '' && token != undefined && token != null && token != 'undefined';
  }

  isLoggedIn(): boolean {
    const user = JSON.parse(localStorage.getItem('user'));
    return user !== null;
  }

  setAuthorization() {
    return new Promise<void>((res, rej) => {
      if (this.isLoggedIn()) {
        let loop = setInterval(() => {
          const currentUser = firebase.auth().currentUser;
          let count = 0;
          if (this.isValidCurrentUser(currentUser)) {
            clearInterval(loop);
            currentUser.getIdToken(/* forceRefresh */ true).then(idToken => {
              if (this.isValidToken(idToken)) {
                console.log('TOKEN RECUPERADO');
                if (this.httpOptions.headers.get('Authorization') != idToken) {
                  this.httpOptions = {
                    headers: new HttpHeaders({
                      'Content-Type': 'application/x-www-form-urlencoded',
                      'Authorization': idToken
                    })
                  };
                }
                res();
              }
              else {
                rej('ERROR: TOKEN IS EMPTY');
              }
            }).catch((error) => {
              clearInterval(loop);
              console.log('Erro ao recuperar token do usuario', error);
              rej(error);
            });
          }
          count++;
          // console.log('COUNT SETAUTH:', count)
          // console.log('Current user :', currentUser)
          if (!this.isLoggedIn()) clearInterval(loop);

        }, 300);
      }
    });
  }

  getAgencyStoreBling(storeName: string, agency: string) {
    return new Promise((res, rej) => {

      this.firebaseServices.getCurrentUser().then((email) => {
        const userEmail = email;

        if (!storeName || !agency || !userEmail) {
          rej("storeName,agency, frase ou  email invalidos");
        }

        return this.httpService.getFromBackendOrCloud(
          { endpoint: `bling/agency-store?userEmail=${userEmail}&storeName=${storeName}&agency=${agency}` },
          { endpoint: `getAgencyStoreBling?userEmail=${userEmail}&storeName=${storeName}&agency=${agency}` }
        )
          .subscribe((result: any) => {
            console.log("RETORNO DO BLING: " + JSON.stringify(result));
            res(result);
          });
      });


    });

  }

  blingRegister(blingApi: string, storeName: string, catergory: string) {
    return new Promise((res, rej) => {

      const httpOptions = this.httpOptions;
      const userEmail = localStorage.getItem('userEmail');
      const agency = localStorage.getItem("agency");
      const blingAPikey = blingApi;
      const blingStoreName = storeName;
      const blingCatergory = catergory;


      let requestEndpoint = endpoint + `blingFunctions-blingRegister?agency=${agency}&userEmail=${userEmail}&blingAPikey=${blingAPikey}&blingStoreName=${blingStoreName}&blingCatergory=${blingCatergory}`;


      this.httpClient.get(requestEndpoint, httpOptions).subscribe(response => {
        console.log('RESPONSE ===========', response);
        res(response);
      });

      setTimeout(() => {
        rej({ result: 'Function time out' });
      }, 40000);
    });
  };


  blingCheckKey(apiKey: string) {
    return new Promise((res, rej) => {
      let requestEndpoint = endpoint + `blingKeyTest?blingApi=${apiKey}`;
      this.httpClient.get(requestEndpoint).toPromise().then(response => {
        console.log();
        res(response.toString());
      }).catch(error => {
        rej(error.toString());
      });
    });
  }

  checkName(storeName: string) {
    return new Promise((res, rej) => {
      const agency = localStorage.getItem("agency");
      const blingStoreName = storeName;

      this.httpService.getFromBackendOrCloud(
        { endpoint: `bling/check-name?agency=${agency}&blingStoreName=${blingStoreName}` },
        { endpoint: `blingFunctions-blingCheckName?agency=${agency}&blingStoreName=${blingStoreName}` }
      )
        .toPromise()
        .then(response => {
          console.log(response.status, "olhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhHHH");
          res(response);
        }).catch(error => {
          rej(error.toString());
        });
    });
  }
}
