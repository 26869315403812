import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { Routes, RouterModule } from '@angular/router';
import { ReactiveFormsModule } from '@angular/forms';
import { IonicModule } from '@ionic/angular';
import { BrMaskerModule } from 'br-mask';
import { TranslateModule } from '@ngx-translate/core';
import {Ng2TelInputModule} from 'ng2-tel-input';  
import { InternationalPhoneNumber2Module } from 'ngx-international-phone-number2';
import { ImagePickerComponent } from '../image-picker/image-picker.component';


import { EditProfileModalPage } from './edit-profile-modal.page';

const routes: Routes = [
  {
    path: 'editProfile',
    component: EditProfileModalPage
  }
];

@NgModule({
  imports: [
    CommonModule,
    ReactiveFormsModule,
    FormsModule,
    IonicModule,
    RouterModule.forChild(routes),
    BrMaskerModule,
    TranslateModule.forChild(),
    InternationalPhoneNumber2Module,
    Ng2TelInputModule,
    
  ],
  declarations: [EditProfileModalPage, ImagePickerComponent]
})
export class EditProfileModalPageModule {}
