import { Component, OnInit } from "@angular/core";
import { IonSlides } from "@ionic/angular";
import { ViewChild } from "@angular/core";
import { FormBuilder, Validators } from "@angular/forms";
import { FirebaseService } from "../../services/firebase.service";
import {
  Router,
  ActivatedRoute,
  RouterModule,
  Routes,
  NavigationEnd,
} from "@angular/router";
import { LoadingProvider } from "../../providers/loading";
import { AlertController } from "@ionic/angular";
import { Platform, NavController } from "@ionic/angular";
import { ModalController } from "@ionic/angular";
import { WoocommerceService } from "../../services/woocommerce.service";
import { IonContent } from "@ionic/angular";
import { TranslateService } from "@ngx-translate/core";
import { FirebaseAnalyticsService } from "../../services/firebase-analytics.service";

@Component({
  selector: "app-assign-store",
  templateUrl: "./assign-store.page.html",
  styleUrls: ["./assign-store.page.scss"],
})
export class AssignStorePage implements OnInit {
  @ViewChild("mySlider") slides: IonSlides;
  @ViewChild(IonContent) content: IonContent;

  storeForm = this.formBuilder.group({
    storeName: [null, []],
    isAdmin: [null, []],
  });

  slideOpts = {
    initialSlide: 0,
    speed: 400,
  };

  public router: Router;
  public usersArray: Array<any> = [];
  public storesArray: Array<Object> = [];
  public listLoading: Boolean;
  public hideList: boolean;
  public selectedUser: any;
  public filteredUsers: Array<any> = [];
  public searchTerm: string = "";
  public loadForm: boolean;

  constructor(
    private firestoreService: FirebaseService,
    router: Router,
    public loading: LoadingProvider,
    private navController: NavController,
    private modalController: ModalController,
    private woocommerceService: WoocommerceService,
    private formBuilder: FormBuilder,
    public alertCtrl: AlertController,
    private translate: TranslateService,
    private analyticsService: FirebaseAnalyticsService
  ) {
    this.router = router;
    this.usersArray = [];
    this.hideList = false;
    this.loadForm = false;
  }

  ngOnInit() {
    this.slides.slidePrev();
    this.slides.lockSwipeToPrev(true);
    this.slides.lockSwipeToNext(true);
    this.loadUserData();
  }

  async close() {
    const onClosedData: string = "Wrapped Up!";
    await this.modalController.dismiss(onClosedData);
    this.storeForm.reset();
  }

  ionViewDidEnter() {
    this.analyticsService.trackScreen("Assign Store");
  }

  loadUserData() {
    this.listLoading = true;
    this.filteredUsers = [];
    this.searchTerm = "";
    this.usersArray = [];

    this.firestoreService.getAgencyUsers().then((result: any) => {
      this.listLoading = false;
      result.forEach((user) => {
        let userObject = {
          name: user.name,
          email: user.email,
          storeName: user.storeName,
          isAdmin: user.isAdmin,
          // 'createdAt': doc['_document'].proto.createTime
        };
        this.usersArray.push(userObject);
      });
      this.filteredUsers = this.usersArray;
    });
  }

  loadStoresData() {
    const agency = localStorage.getItem("agency");
    this.loading.present().then(() => {
      this.woocommerceService.getStores(agency).subscribe((result: any) => {
        this.storesArray = result;
        // this.loadForm = true
        this.loading.dismiss();
      });
    });
  }

  selectUser(user) {
    this.slides.lockSwipeToNext(false);
    this.slides.lockSwipeToPrev(true);
    this.slides.slideNext().then(() => {
      this.loadStoresData();
    });

    this.selectedUser = user;

    setTimeout(() => {
      this.hideList = true;
      this.content.scrollToTop(300);
    }, 400);
  }

  slideBack() {
    this.slides.lockSwipeToPrev(false);
    this.slides.slidePrev().then(() => {
      this.slides.lockSwipeToNext(true);
      this.hideList = false;
      this.loadForm = false;
    });
    this.storeForm.reset();
  }

  async submitStore() {
    const error = await this.alertCtrl.create({
      message: this.translate.instant("FAILED_UPDATE_STORE"),
      header: "Erro",
      cssClass: "secondary",
      buttons: [{ text: "Ok", handler: (data) => {} }],
    });
    const success = await this.alertCtrl.create({
      message: this.translate.instant("SUCCESS_UPDATE_STORE"),
      header: "Sucesso",
      cssClass: "secondary",
      buttons: [{ text: "Ok", handler: (data) => {} }],
    });

    this.loading.present().then(() => {
      let permission = this.storeForm.value.isAdmin;
      let storeName = this.storeForm.value.storeName;
      if (permission == null && storeName != null) {
        this.updateClientStore(this.selectedUser.email, storeName)
          .then(async (res) => {
            this.loading.dismiss();
            await success.present();
            this.loadUserData();
            this.slideBack();
          })
          .catch(async (err) => {
            this.loading.dismiss();
            await error.present();
          });
      }
      if (permission != null && storeName == null) {
        this.updateClientPermission(this.selectedUser.email, permission)
          .then(async (res) => {
            this.loading.dismiss();
            await success.present();
            this.loadUserData();
            this.slideBack();
          })
          .catch(async (err) => {
            this.loading.dismiss();
            await error.present();
          });
      }
      if (permission != null && storeName != null) {
        this.updateClientStore(this.selectedUser.email, storeName)
          .then((res) => {
            this.updateClientPermission(this.selectedUser.email, permission)
              .then(async (resp) => {
                this.loading.dismiss();
                await success.present();
                this.loadUserData();
                this.slideBack();
              })
              .catch(async (error) => {
                this.loading.dismiss();
                await error.present();
              });
          })
          .catch(async (err) => {
            this.loading.dismiss();
            await error.present();
          });
      }
    });
  }

  updateClientStore(email, storeName): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      this.firestoreService
        .updateClientStore(email, storeName, "admin", "admin")
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          console.log(err);
          reject(err);
        });
    });
  }

  updateClientPermission(email, permission): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      if (permission === "Administrador") {
        permission = "true";
      } else {
        permission = "false";
      }
      this.firestoreService
        .updateClientPermission(email, permission)
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  defaultStorePlaceholder() {
    if (this.selectedUser) {
      if (
        this.selectedUser.storeName === undefined ||
        this.selectedUser.storeName === "undefined" ||
        this.selectedUser.storeName === "" ||
        this.selectedUser.storeName === null
      ) {
        return "Sem loja";
      } else {
        return this.selectedUser.storeName;
      }
    }
  }

  defaultIsAdminPlaceholder() {
    if (this.selectedUser) {
      if (
        this.selectedUser.isAdmin == "true" ||
        this.selectedUser.isAdmin == true
      ) {
        return "Administrador";
      } else {
        return "Usuário comum";
      }
    }
  }

  disableSubmit() {
    return (
      (this.storeForm.value.storeName === "" &&
        this.storeForm.value.isAdmin === "") ||
      (this.storeForm.value.storeName === undefined &&
        this.storeForm.value.isAdmin === undefined) ||
      (this.storeForm.value.storeName === null &&
        this.storeForm.value.isAdmin === null) ||
      (this.storeForm.value.storeName === " " &&
        this.storeForm.value.isAdmin === " ")
    );
  }

  filterItems(searchTerm: string) {
    return this.usersArray.filter((user) => {
      const email: string = user.email;
      return searchTerm && email
        ? email.toLowerCase().indexOf(searchTerm.toLowerCase()) > -1
        : false;
    });
  }

  setFilteredUsers() {
    if (this.searchTerm != "") {
      this.filteredUsers = this.filterItems(this.searchTerm);
    } else {
      this.filteredUsers = this.usersArray;
    }
  }
}
