import { Component, OnInit } from '@angular/core';
import { AlertController, ModalController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { LoadingProvider } from 'src/providers/loading';
import { AnalyticsService } from 'src/services/analytics.service';
import { FirebaseService } from 'src/services/firebase.service';
import { WoocommerceService } from 'src/services/woocommerce.service';
import { Storage } from "@ionic/storage";
import * as CryptoJS from "crypto-js";
@Component({
  templateUrl: './analytics-association-modal.page.html',
  selector: 'app-analytics-association-modal',
  styleUrls: ['./analytics-association-modal.page.scss'],
})
export class AnalyticsAssociationModalPage implements OnInit {

  storeList = [];
  analyticsStoreList = [];
  dashcommerceStoreName: string;
  analyticsList = [];
  viewUrl: string;
  propertyID: string;
  propertyName: string;
  viewId: string;
  showSpinner =  false;
  
  public agency: string;
  

  constructor(
    private modalController: ModalController,
    public woocommerceService: WoocommerceService,
    private analytics: AnalyticsService,
    public alertCtrl: AlertController,
    public translate: TranslateService,
    private firestoreService: FirebaseService,
    private storage: Storage
  ) { }

  ngOnInit() {
    this.getStores()
    this.getAnalyticsProfilleModal()
  }

  private getAnalyticsProfilleModal(){
    this.analytics.getAnalyticsProfiles().then(async(result:any) => {
      this.analyticsList = JSON.parse(result)
      console.log("=======ANALYTICS LIST=======", this.analyticsList)
    })
  }

  private getStores() {
    this.storeList = JSON.parse(localStorage.getItem("storeList"));
    this.storeList.forEach(store => {
      if(!store.type){
        this.analyticsStoreList.push(store)
      }
    })   
    console.log("=======STORE LIST=======", this.analyticsStoreList) 
  }

  
  async closeModal() {
    await this.modalController.dismiss();
  }

  async presentInvalidFieldAlert(message) {
    const alert = await this.alertCtrl.create({
      header: this.translate.instant("INVALID_FIELD"),
      message: message,
      animated: true,
      mode: "ios",
      buttons: [
        {
          text: "OK",
          handler: () => {
            console.log("Confirm OK");
          },
        },
      ],
    });
    await alert.present();
  }

  async getPropertyID(){
    this.analyticsList.find((property) => {
      if(property.displayName == this.propertyName){
        console.log("======PROPERTY ID======", property.propertyID)
        this.propertyID = property.propertyID
      }
    })
    const storeName =  localStorage.getItem('storeName')

    const encryptPass = "d@shApp"
    const encryptedProperty = CryptoJS.AES.encrypt(this.propertyID, encryptPass)

    localStorage.setItem('propertyID', encryptedProperty)
    localStorage.setItem('analyticsAssociatedStore', storeName)

  }

  async getViewId(){
    const viewId =  this.analyticsList.find((view) => {
      return view.websiteUrl == this.viewUrl
    }).id
    return viewId
  }

  async sucessAlert(){
    
    const sucess = await this.alertCtrl.create({
      header: this.translate.instant("SUCCESS_UPDATE_STORE"),
      message: this.translate.instant("SUCCESS_UPDATE_ANALYTICS"),
      animated: true,
      mode: "ios",
      buttons: [
        {
          text: this.translate.instant("LOGOUT"),
          handler: async () => {
            this.closeModal()
          },
        },
      ],
    });
    await sucess.present();
  }

  async presentSuccessAlert(message) {
    const alert = await this.alertCtrl.create({
      header: this.translate.instant("SURE_QUESTION"),
      message: message,
      animated: true,
      mode: "ios",
      buttons: [
        {
          text: this.translate.instant("CANCEL"),
          handler: () => {
          },
        },
        {
          text: this.translate.instant("CONFIRM"),
          handler: async () => {
           
            const params = {
              storeName: this.dashcommerceStoreName,
              propertyName: this.propertyName,
              propertyID: this.propertyID
            }
            
            console.log(params)

            try {
              this.showSpinner = true
              await this.firestoreService.saveAnalyticsGA4Association(params)
              this.showSpinner = false
              this.sucessAlert()
            } catch (err) {
              console.log(err)
              this.showSpinner = false
            }
          },
        },
      ],
    });
    await alert.present();
  }

  async buttonSubmit(){
    await this.getPropertyID()
    console.log('======PROPERTy ID=====', this.propertyID)
    if(this.propertyID == undefined && this.dashcommerceStoreName == undefined){
      console.log("DEU RUIM os dois undefined")
      this.presentInvalidFieldAlert(
        this.translate.instant("INVALID_STORENAME")
      )
    }
    else if(this.propertyID == undefined){
      console.log("DEU RUIM somente o analytucs email undefined", this.propertyID)
      this.presentInvalidFieldAlert(
        this.translate.instant("INVALID_USEREMAIL")
      )
    }
    else if(this.dashcommerceStoreName == undefined){
      console.log("DEU RUIM somente o dashcommerceStoreName undefined")
      this.presentInvalidFieldAlert(
        this.translate.instant("INVALID_STORENAME")
      )
    }
    else if(this.propertyID.length > 0 && this.dashcommerceStoreName.length > 0){
      this.presentSuccessAlert(
        this.translate.instant("CHANGE_ANALYTICS_STORE")
      )
    }
  }

}
